<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <navbar />
    <v-main>
      <v-container>
        <v-alert dismissible border="left" type="error" v-model="isError">
          {{ errorMessage }}
        </v-alert>
        <v-alert dismissible border="left" type="success" v-model="isSuccess">
          You have successfully submitted your new user account. Please check
          your email for your temporary password and log in. Redirecting back to
          login page...
        </v-alert>
        <h1 class="page-title">Register User Account</h1>
        <p>Please enter the following information below to create your user.</p>
        <UserDetails :user="user" :loading="loading" @eventname="CreateUser" />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
const api = require("@/api/api.js");
import UserDetails from "@/components/Users/Details.vue";
import Footer from "@/components/Layout/Footer.vue";
import Navbar from "@/components/Layout/Navbar.vue";

export default {
  Name: "Register",
  components: {
    UserDetails,
    Navbar,
    Footer,
  },
  data() {
    return {
      isNew: true,
      user: {
        phone_number: "",
        first_name: "",
        last_name: "",
        temp_number: "",
        self_register: true,
      },
      loading: false,
      isError: false,
      isSuccess: false,
      successMessage: "",
      errorMessage: "",
      customErrorMessage: "",
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  async mounted() {},
  methods: {
    async CreateUser(user) {
      this.loading = true;
      this.isSuccess = false;
      this.isError = false;
      try {
        await api
          .postNoAuth("/register", user)
          .then((data) => {
            console.log("data: ", data);
          })
          .catch((err) => {
            console.log(err);
            this.isError = true;
            this.errorMessage = `Could not register. ${err.response.data}`;
          });

        this.loading = false;

        if (!this.isError) {
          this.isSuccess = true;
          setTimeout(
            () =>
              this.$router.push({
                name: "Login",
                params: {
                  registered: this.isSuccess,
                  registeredMessage:
                    "Please enter your email address and temporary password below",
                },
              }),
            5000
          );
        } else {
          this.isSuccess = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.isError = true;
        this.errorMessage = error.response.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
