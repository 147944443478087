<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <router-link to="/home">
        <v-img
          alt="UP Logo"
          class="shrink mr-3"
          contain
          src="@/assets/img_e_brand_up_color_halo_png.png"
          transition="scale-transition"
          width="35"
        />
      </router-link>
      <div color="white" class="byline">Powered By JCT</div>
      <div class="vl"></div>
    </div>

    <div fluid class="d-flex short-menu" style="width: 100%">
      <v-menu
        open-on-hover
        bottom
        offset-y
        class="mobile-nav"
        v-if="isUserAllowed(['Admin', 'Master'])"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-block yellow-text"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Users
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <router-link class="blue-text" to="/users"
              >User Mangement</router-link
            >
          </v-list-item>
          <v-list-item>
            <router-link class="blue-text" to="/users/create"
              >Add A User</router-link
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        open-on-hover
        bottom
        offset-y
        v-if="isUserAllowed(['Admin', 'Master'])"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-block yellow-text"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Containers
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <router-link class="blue-text" to="/containers/admin"
              >Containers Admin</router-link
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu open-on-hover bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-block yellow-text"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            v-if="isUserAllowed(['Master', 'Admin', 'Party'])"
          >
            Outbound
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <router-link
              v-if="isUserAllowed(['Master', 'Admin', 'Party'])"
              class="blue-text"
              to="/outbound/summary"
              >Booking Summary</router-link
            >
          </v-list-item>
          <v-list-item v-if="isUserAllowed(['Master', 'Party'])">
            <router-link to="/outbound/create" class="blue-text"
              >Create a Booking</router-link
            >
          </v-list-item>
          <v-list-item>
            <router-link
              v-if="isUserAllowed(['Master', 'Admin', 'Party'])"
              to="/outbound/comments"
              class="blue-text"
              >Booking Comments</router-link
            >
          </v-list-item>
          <v-list-item v-if="isUserAllowed(['Master', 'Admin'])">
            <router-link to="/outbound/containermove" class="blue-text"
              >Move Booking Container</router-link
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        open-on-hover
        bottom
        offset-y
        v-if="isUserAllowed(['Admin', 'Master', 'Party', 'Trucker'])"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-block yellow-text"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Inbound
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="isUserAllowed(['Admin', 'Master', 'Party'])">
            <router-link to="/forecast" class="blue-text"
              >Delivery Forecast</router-link
            >
          </v-list-item>
          <v-list-item
            v-if="
              isUserAllowed([
                'Party',
                'Admin',
                'Master',
                'Fumigator',
                'Trucker',
              ])
            "
          >
            <router-link to="/deliveries/deliveries" class="blue-text"
              >Deliveries</router-link
            >
          </v-list-item>
          <v-list-item v-if="isUserAllowed(['Trucker'])">
            <router-link to="/booking/driver" class="blue-text"
              >Schedule a Delivery</router-link
            >
          </v-list-item>
          <v-list-item v-if="isUserAllowed(['Admin', 'Master', 'Party'])">
            <router-link to="/tickets" class="blue-text">Tickets</router-link>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        open-on-hover
        bottom
        offset-y
        v-if="isUserAllowed(['Admin', 'Master', 'Fumigator'])"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="d-none d-sm-block yellow-text"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Fumigation
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="isUserAllowed(['Admin', 'Master', 'Fumigator'])">
            <router-link to="/fumigation/containers" class="blue-text"
              >Fumigation Containers</router-link
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-menu open-on-hover bottom offset-y v-if="getUserLogin()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          class="yellow-text"
        >
          Menu <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title
            ><v-btn plain @click="Logout" class="blue-text"
              >Logout</v-btn
            ></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { Auth } from "aws-amplify";
import { isUserAllowed, getUserLogin } from "@/helpers.js";
import store from "@/store/index.js";

export default {
  name: "Navbar",
  methods: {
    Logout() {
      Auth.signOut({ global: true });
      store.commit("logOut");
      this.$router.push({ name: "Login" });
    },
    isUserAllowed,
    getUserLogin,
  },
};
</script>
<style>
a {
  text-decoration: none;
}

.byline {
  position: absolute;
  top: 20px;
  left: 70px;
  font-style: italic;
}

.v-bottom-navigation {
  position: fixed;
  bottom: 20px;
}
.blue-text {
  color: #007dba !important;
}
.yellow-text {
  color: #ffcc00 !important;
}

.short-menu {
  margin-left: 140px;
  border-left: 3px solid #ffcc00;
}
</style>
