<template>
  <v-card elevation="2" outlined height="100%" class="mb-2">
    <v-card-title>{{ inboundDate }}</v-card-title>
    <v-row>
      <v-card-text>
        Orders
        <v-icon large height="100%">mdi-format-align-left</v-icon>
        <v-card elevation="2" outlined tile>
          <template v-for="order in orders">
            <v-card-text :key="order.containerNo">
              {{ order.orderNo }} - {{ order.orderTime }}
            </v-card-text>
          </template>
        </v-card>
      </v-card-text>
    </v-row>
    <v-row>
      <v-card-text>
        Containers
        <v-icon large height="100%">mdi-train</v-icon>
        <v-card elevation="2" outlined tile class="mb-2">
          <template v-for="container in containers">
            <v-card-text :key="container.containerNo">
              {{ container.containerNo }} - {{ container.containerTime }}
            </v-card-text>
          </template>
        </v-card>
      </v-card-text>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "InboundSchedule",
  props: {
    inboundDate: {
      type: String,
      required: true,
    },
    orders: {
      type: Array,
      required: false,
    },
    containers: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    data: [],
    isError: false,
    errorMessage: "",
    loading: false,
  }),
  methods: {},
};
</script>
