<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>

    <v-data-table
      v-if="isUserAllowed(['Admin', 'Master', 'Party'])"
      :headers="headers"
      :items="tickets"
      item-key="FileName"
      :loading="loading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      class="elevation-1 table mt-5"
      v-model="selected"
      show-select
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          @input="select($event)"
          :value="isSelected"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-if="isUserAllowed(['Admin'])" v-slot:header.PartyName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="partySearch"
                label="Enter a party"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="partySearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.TicketNumber="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="ticketNumberSearch"
                label="Enter a ticket #"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn
                @click="ticketNumberSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.LoadNumber="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="loadNumberSearch"
                label="Enter a load #"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="loadNumberSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.ModifiedDate="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="dateSearch"
                label="Enter a date"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="dateSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.FileName="{ item }">
        <v-btn
          :link="true"
          @click="openLink(item.PreSignedUrl)"
          target="_blank"
        >
          <v-icon class="mr-2">mdi-file</v-icon> {{ item.FileName }}
        </v-btn>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="12" md="9" lg="9" xl="9"> </v-col>
      <v-col cols="12" md="3" lg="3" xl="3" class="mt-2 mb-5">
        <v-btn
          @click="DownloadSelectedFiles()"
          :loading="loading"
          :disabled="isDownloadDisabled"
          color="secondary"
          class="mt-2 mr-2 ml-2 submit-button text--primary"
        >
          Download Selected
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
const axios = require("axios");

export default {
  name: "Tickets",
  components: {},
  data: () => ({
    isError: false,
    loading: true,
    alertMessage: "",
    valid: true,
    partySearch: "",
    loadNumberSearch: "",
    ticketNumberSearch: "",
    dateSearch: "",
    sortBy: "ModifiedDate",
    sortDesc: true,
    tickets: [],
    selected: [],
    search: "",
    partyId: 0,
    isDownloadDisabled: true,
  }),
  computed: {
    headers() {
      let headers = [];
      if (
        this.partyId <= 0 ||
        isNaN(this.partyId) ||
        isUserAllowed(["Admin"])
      ) {
        headers.push({
          text: "Party",
          value: "PartyName",
          sortable: false,
          filter: (value) => {
            if (!this.partySearch || this.partySearch === "") return true;
            return (
              value != null &&
              this.partySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.partySearch.toString().toLocaleUpperCase()) !== -1
            );
          },
        });
      }

      headers.push({
        text: "Ticket #",
        value: "TicketNumber",
        sortable: false,
        filter: (value) => {
          if (!this.ticketNumberSearch || this.ticketNumberSearch === "")
            return true;
          return (
            value != null &&
            this.ticketNumberSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(
                this.ticketNumberSearch.toString().toLocaleUpperCase()
              ) !== -1
          );
        },
      });

      headers.push({
        text: "Load #",
        value: "LoadNumber",
        sortable: false,
        filter: (value) => {
          if (!this.loadNumberSearch || this.loadNumberSearch === "")
            return true;
          return (
            value != null &&
            this.loadNumberSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.loadNumberSearch.toString().toLocaleUpperCase()) !==
              -1
          );
        },
      });
      headers.push({
        text: "Date",
        value: "ModifiedDate",
        sortable: false,
        filter: (value) => {
          if (!this.dateSearch || this.dateSearch === "") return true;
          return (
            value != null &&
            this.dateSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.dateSearch.toString().toLocaleUpperCase()) !== -1
          );
        },
      });
      headers.push({ text: "File", value: "FileName", sortable: true });
      headers.push({ text: "", value: "action", sortable: false });

      return headers;
    },
  },
  async mounted() {
    let tokenAttributes = await api.getAuthTokenAttributes();
    this.partyId = parseInt(tokenAttributes["custom:party_id"]);

    await this.GetTickets();
  },
  methods: {
    isUserAllowed,
    openLink(url) {
      window.open(url);
    },
    async GetTickets() {
      try {
        this.loading = true;
        this.tickets = await api.get(`/load/tickets?partyId=${this.partyId}`);
        console.table(this.tickets);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Failed to retrieve tickets data. Please try again.";
        console.log(err);
      }
    },
    async DownloadSelectedFiles() {
      try {
        this.loading = true;
        await Promise.all(
          this.selected.map((ticket) => {
            return axios({
              url: ticket.PreSignedUrl,
              method: "GET",
              responseType: "blob", // important
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute(
                "download",
                `${ticket.TicketNumber}_${ticket.FileName}`
              ); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
  },
  watch: {
    selected: function () {
      this.isDownloadDisabled = this.selected.length > 0 ? false : true;
    },
  },
};
</script>
