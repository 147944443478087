<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-form
      ref="form"
      v-model="valid"
      class="form mt-5"
      v-if="isUserAllowed(['Party'])"
    >
      <v-row>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            v-model="booking.LoadNumber"
            label="Load Number"
            required
            v-on:keypress="isLetterOrNumber($event)"
            :rules="[loadNumberRule, requiredRules]"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            label="Commodity"
            :items="commodity"
            item-text="text"
            item-value="value"
            :rules="[requiredRules]"
            required
            v-model="booking.CommodityId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            v-model="booking.PartyAccountId"
            :items="partyAccount"
            label="Party Account"
            required
            :rules="[requiredRules]"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="0" md="4" lg="4" xl="4"> </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-btn
            id="createBooking"
            @click="CreateBooking"
            :disabled="
              !booking.LoadNumber ||
              !booking.CommodityId ||
              !booking.PartyAccountId
            "
            :loading="loading"
            color="secondary"
            class="mt-2 mr-2 ml-2 submit-button text--primary"
            >Create Delivery
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="2" lg="2" xl="2" v-if="isUserAllowed(['Trucker'])">
        <v-btn
          id="scheduleDelivery"
          href="/booking/driver"
          :loading="loading"
          color="secondary"
          class="mt-3 mr-2 ml-2 mb-2 schedule-button text--primary"
          >Schedule a Delivery
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      id="dataTable"
      :headers="headers"
      :items="times"
      item-key="name"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      class="elevation-1 table"
      :search="search"
      :custom-filter="FilterOnlyCapsText"
    >
      <template v-slot:top>
        <v-row align="center" class="search-bar">
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field
              id="search"
              v-model="search"
              label="Filter"
              class="mx-4"
            ></v-text-field>
            <v-text-field
              id="daysPast"
              v-model="daysPast"
              label="Days back"
              class="mx-4"
              @input="fetchDeliveriesDebounced"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:header.DeliveryScheduleStatusDescription="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="statusSearch"
                label="Enter a booking status"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="statusSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.PartyName="{ item }">
        {{ item.PartyAccountName }}
      </template>
      <template v-slot:item.DriverPhone="{ item }">
        <a :href="`tel:${item.DriverPhone}`">{{ item.DriverPhone }}</a>
      </template>
      <template v-slot:item.booked="{ item }">
        <v-icon color="green" v-if="item.booked">mdi-circle</v-icon>
        <v-icon color="red" v-else>mdi-circle</v-icon>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn
          ref="select-driver-time"
          v-if="[53, 59].includes(item.StatusId)"
          size="small"
          class="mr-2"
          icon="mdi-pencil"
          @click="SelectDriverTime({ nativeEvent: $event, event: item })"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          ref="select-driver-time"
          v-if="item.DeliveryScheduleStatusDescription === 'Not Scheduled'"
          icon="mdi-plus"
          size="small"
          @click="DeleteDeliveries({ nativeEvent: $event, event: item })"
          :loading="loading"
        >
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar dark>
          <v-btn icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-toolbar-title v-html="selectedEvent.loadNumber"></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation class="form">
            <v-text-field
              v-model="selectedEvent.LoadNumber"
              label="Load Number"
              style="font-size: 12px"
              required
              outlined
              clearable
            >
            </v-text-field>
            <v-select
              v-model="selectedEvent.ScheduleDate"
              :items="GetAvailableDates()"
              label="Dates"
              style="font-size: 12px"
              required
              :rules="requiredRules"
              outlined
            ></v-select>
            <v-select
              v-model="selectedEvent.ScheduleTime"
              :items="GetAvailableTimesForDate(selectedEvent.ScheduleDate)"
              label="Times"
              style="font-size: 12px"
              required
              :rules="requiredRules"
              outlined
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="secondary"
            @click="selectedOpen = false"
            class="text--primary"
          >
            Cancel
          </v-btn>
          <v-btn
            id="deleteBooking"
            text
            color="secondary"
            @click="DeleteBooking(selectedEvent)"
            class="text--primary"
          >
            Delete
          </v-btn>
          <v-btn
            id="updateBooking"
            text
            color="secondary"
            @click="UpdateBooking(selectedEvent)"
            class="text--primary"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "Deliveries",
  components: {},
  data: () => ({
    isError: false,
    loading: true,
    alertMessage: "",
    valid: true,
    datePicker: false,
    editDatePicker: false,
    timePicker: false,
    editTimePicker: false,
    requiredRules: (v) => {
      return !!v || "Field is required";
    },
    commodity: [],
    partyAccount: [],
    statusSearch: "",
    selectedElement: null,
    selectedOpen: false,
    deletebuttonLoader: false,
    booking: {},
    daysPast: 10,
    loadNumber: "",
    selectedEvent: {},
    times: [],
    search: "",
    availableTimes: [],
    loadNumberRule: (val) => {
      if (val && val.length > 12) {
        return "Number must be 12 characters or less.";
      }
      return true;
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: "Party",
          align: "start",
          value: "PartyName",
        },
        {
          text: "Load Number",
          value: "LoadNumber",
        },
        { text: "Commodity", value: "CommodityDescription" },
        {
          text: "Status",
          value: "DeliveryScheduleStatusDescription",
          sortable: false,
          filter: (value) => {
            if (!this.statusSearch || this.statusSearch === "") return true;
            return (
              value != null &&
              this.statusSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.statusSearch.toString().toLocaleUpperCase()) !==
                -1
            );
          },
        },
        { text: "Date", value: "ScheduleDate" },
        { text: "Time", value: "ScheduleTime" },
        { text: "Email", value: "DriverEmail" },
        { text: "Name", value: "DriverName" },
        { text: "Phone", value: "DriverPhone" },
        { text: "Actions", value: "edit" },
      ];
    },
  },
  mounted() {
    this.GetLoadBookings();
    this.GetBookingFields();
    this.GetAvailableSchedules();
  },
  methods: {
    isUserAllowed,
    FilterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    fetchDeliveriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.GetLoadBookings();
      }, 500);
    },
    async SelectDriverTime({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    async DeleteDeliveries(selectedEvent) {
      try {
        console.log(selectedEvent);
        this.loading = true;
        this.selectedOpen = false;
        console.log(selectedEvent.event);
        await api.put("/inbound/delivery/update", selectedEvent.event);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertMessage = error.response.data;

        if (this.alertMessage === "") {
          this.alertMessage = "A System error has occured. Please try again.";
          this.isError = true;
        }
      }
      this.GetLoadBookings();
    },
    async DeleteBooking(selectedEvent) {
      try {
        this.loading = true;
        this.selectedOpen = false;
        await api.del(
          "/inbound/schedule/delete/" + selectedEvent.DeliveryScheduleId
        );
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertMessage = error.response.data;

        if (this.alertMessage === "") {
          this.alertMessage = "A System error has occured. Please try again.";
          this.isError = true;
        }
      }
      this.GetLoadBookings();
    },
    async UpdateBooking(selectedEvent) {
      this.loading = true;
      try {
        let currentTime = this.availableTimes.filter(
          (time) =>
            time.ScheduleDate === selectedEvent.ScheduleDate &&
            time.ScheduleTime === selectedEvent.ScheduleTime
        );
        selectedEvent.ScheduleId = currentTime[0].ScheduleId;
        this.selectedOpen = false;
        await api.put("/inbound/schedule/update", selectedEvent);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = err.response.data;

        if (this.alertMessage === "") {
          this.alertMessage = `Failed to create load bookings. Please try again.`;
        }
        console.log(err);
      }
      this.GetLoadBookings();
    },
    async CreateBooking() {
      this.loading = true;
      try {
        await api.post("/load/booking", this.booking);
        this.booking = {};
        this.$refs.form.reset();
      } catch (err) {
        this.loading = false;
        this.isError = true;
        console.log(err);
        this.alertMessage = err.response.data;

        if (this.alertMessage === "") {
          this.alertMessage = `Failed to create load bookings. Please try again.`;
        }
      }
      this.GetLoadBookings();
    },
    async GetBookingFields() {
      try {
        this.loading = true;
        let fields = await api.get("/load/bookingfields");
        this.commodity = fields.commodity
          .filter((value) => value.ScheduledDeliveryRequired)
          .map((value) => {
            return { text: value.name, value: value.commodityId };
          });
        this.partyAccount = fields.partyAccount.map((value) => {
          return { text: value.name, value: value.partyAccountId };
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async GetLoadBookings() {
      try {
        this.loading = true;
        this.times = await api.get("/load/bookings?daysPast=" + this.daysPast);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to get load deliveries. Please try again.";
        console.log(err);
      }
    },
    async GetAvailableSchedules() {
      try {
        this.availableTimes = await api.get("/load/schedules");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to get load schedule. Please try again.";
        console.log(err);
      }
    },
    GetAvailableDates() {
      let dates = this.availableTimes.map((date) => {
        return date.ScheduleDate;
      });
      return [...new Set(dates)];
    },
    GetAvailableTimesForDate(date) {
      let times = this.availableTimes
        .filter((time) => date === time.ScheduleDate)
        .map((date) => {
          return date.ScheduleTime;
        });
      return times;
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9-]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>
<style>
.schedule-button {
  width: 95%;
}
.v-label {
  font-size: 12px !important;
}
</style>
