<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>

    <v-data-table
      :headers="headers"
      :items="forecasts"
      item-key="name"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      class="elevation-1 table mt-5"
      :search="search"
      :custom-filter="FilterOnlyCapsText"
    >
      <template v-slot:header.ForecastDate="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="dateSearch"
                label="Enter a Date"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="dateSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.CommodityName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="commoditySearch"
                label="Enter a commodity"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="commoditySearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.PartyName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="partySearch"
                label="Enter a party"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="partySearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.DeliveryForecastEventStatusName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="statusSearch"
                label="Enter a status"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="statusSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template
        v-if="isUserAllowed(['Party'])"
        v-slot:item.ForecastedQuantity="{ item }"
      >
        <v-text-field
          type="number"
          min="0"
          :rules="[numberRule]"
          style="width: 100px"
          :disabled="item.ScheduledDeliveryRequired"
          v-model="item.ForecastedQuantity"
          class="mt-1 mb-1"
          rounded
          outlined
          hide-details="auto"
        ></v-text-field>
      </template>
      <template
        v-if="isUserAllowed(['Admin', 'Master'])"
        v-slot:item.ApprovedQuantity="{ item }"
      >
        <v-text-field
          type="number"
          min="0"
          :rules="[numberRule]"
          style="width: 100px"
          v-model="item.ApprovedQuantity"
          class="mt-1 mb-1"
          rounded
          outlined
          hide-details="auto"
        ></v-text-field>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon color="green">
          <v-icon
            :disabled="loading"
            color="black"
            @click="UpdateForecast(item)"
            >mdi-content-save</v-icon
          >
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "Deliveryforecast",
  components: {},
  data: () => ({
    isError: false,
    loading: true,
    alertMessage: "",
    valid: true,
    statusSearch: "",
    commoditySearch: "",
    dateSearch: "",
    partySearch: "",
    selectedEvent: {},
    forecasts: [],
    search: "",
    numberRule: (val) => {
      if (val < 0) {
        return "Number must be postive.";
      }
      return true;
    },
  }),
  computed: {
    headers() {
      return [
        {
          text: "Forecast Date",
          align: "start",
          value: "ForecastDate",
          sortable: false,
          filter: (value) => {
            if (!this.dateSearch || this.dateSearch === "") return true;
            return (
              value != null &&
              this.commoditySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.dateSearch.toString().toLocaleUpperCase()) !== -1
            );
          },
        },
        {
          text: "Commodity",
          value: "CommodityName",
          sortable: false,
          filter: (value) => {
            if (!this.commoditySearch || this.commoditySearch === "")
              return true;
            return (
              value != null &&
              this.commoditySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.commoditySearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Party",
          value: "PartyName",
          sortable: false,
          filter: (value) => {
            if (!this.partySearch || this.partySearch === "") return true;
            return (
              value != null &&
              this.partySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.partySearch.toString().toLocaleUpperCase()) !== -1
            );
          },
        },

        { text: "Requested", value: "ForecastedQuantity" },
        { text: "Approved", value: "ApprovedQuantity" },
        {
          text: "Status",
          value: "DeliveryForecastEventStatusName",
          sortable: false,
          filter: (value) => {
            if (!this.statusSearch || this.statusSearch === "") return true;
            return (
              value != null &&
              this.statusSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.statusSearch.toString().toLocaleUpperCase()) !==
                -1
            );
          },
        },

        { text: "Modified Date", value: "ModifiedDate" },
        { text: "", value: "action" },
      ];
    },
  },
  mounted() {
    this.GetDeliveryForecast();
  },
  methods: {
    isUserAllowed,
    FilterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    async UpdateForecast(forecast) {
      if (forecast.ApprovedQuantity >= 0 && forecast.ForecastedQuantity >= 0) {
        this.loading = true;
        try {
          await api.put("/load/forecast", forecast);
        } catch (err) {
          this.loading = false;
          this.isError = true;
          this.alertMessage =
            "Failed to update load forecast. Please try again.";
          console.log(err);
        }
        await this.GetDeliveryForecast();
        this.loading = false;
        this.isError = false;
      } else {
        this.isError = true;
        this.alertMessage = "Please enter positive number";
      }
    },
    async GetDeliveryForecast() {
      try {
        this.loading = true;
        this.forecasts = await api.get("/load/forecast");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Failed to retrieve forecast data. Please try again.";
        console.log(err);
      }
    },
  },
};
</script>
