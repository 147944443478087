const axios = require("axios");
import { Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";

export async function getAuthToken() {
  var user = await Auth.currentSession();
  return user.idToken.jwtToken;
}

export async function getAuthTokenAttributes() {
  const idToken = await getAuthToken();
  const payload = jwt_decode(idToken);

  return payload;
}

export async function get(page) {
  const idToken = await getAuthToken();
  let toReturn = await axios
    .get(process.env.VUE_APP_SERVICE_URL + page, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    .catch((error) => {
      throw error;
    });

  return toReturn.data;
}

export async function post(page, data) {
  const idToken = await getAuthToken();
  //console.log(process.env.VUE_APP_SERVICE_URL);
  let toReturn = await axios
    .post(process.env.VUE_APP_SERVICE_URL + page, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    .catch((error) => {
      console.log(error.response);
      throw error;
    });

  return toReturn.data;
}

export async function postNoAuth(page, data) {
  let toReturn = await axios
    .post(process.env.VUE_APP_SERVICE_URL + page, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => {
      console.log(error.response);
      throw error;
    });

  return toReturn.data;
}

export async function put(page, data) {
  const idToken = await getAuthToken();
  let toReturn = await axios
    .put(process.env.VUE_APP_SERVICE_URL + page, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    .catch((error) => {
      throw error;
    });

  return toReturn.data;
}

export async function del(page) {
  const idToken = await getAuthToken();

  let toReturn = await axios
    .delete(process.env.VUE_APP_SERVICE_URL + page, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
      },
    })
    .catch((error) => {
      throw error;
    });

  return toReturn.data;
}
