<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <navbar height="70px" />
    <v-main>
      <page-title />
      <breadcrumbs v-if="!isHome" />
      <Main />
    </v-main>
    <bottom-bar />
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "./Navbar.vue";
import BottomBar from "./BottomBar.vue";
import Main from "./Main.vue";
import PageTitle from "./PageTitle.vue";
import Breadcrumbs from "./Breadcrumbs.vue";
import Footer from "./Footer.vue";
export default {
  name: "Layout",
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isHome() {
      return this.$route.name === "Home";
    },
  },
  components: {
    Navbar,
    BottomBar,
    Main,
    PageTitle,
    Breadcrumbs,
    Footer,
  },
  watch: {
    $route() {
      switch (this.$route.path) {
        case "/fumigation":
          this.$router.push({ name: "Fumigation Containers" });
          break;
        case "/deliveries":
          this.$router.push({ name: "Deliveries" });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
