var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { md: "8", "offset-md": "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text",
                      staticStyle: { float: "right" },
                      attrs: { depressed: "", color: "secondary" },
                      on: { click: _vm.CreateUser },
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" Add A User")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          items: _vm.usersArray,
          loading: _vm.loading,
          headers: _vm.headers,
          "sort-by": _vm.sort_by,
          "sort-desc": _vm.sortDesc,
          "footer-props": {
            "items-per-page-options": [10, 20, 30, 40, 50, -1],
          },
          "items-per-page": 30,
          "loading-text": "Loading... Please wait",
        },
        on: {
          "update:sortBy": function ($event) {
            _vm.sort_by = $event
          },
          "update:sort-by": function ($event) {
            _vm.sort_by = $event
          },
          "update:sortDesc": function ($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function ($event) {
            _vm.sortDesc = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.first_name",
              fn: function ({ header }) {
                return [
                  _c(
                    "div",
                    { staticStyle: { "align-items": "center" } },
                    [
                      _vm._v(" " + _vm._s(header.text) + " "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(" mdi-filter "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "white",
                                width: "280px",
                              },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-4",
                                attrs: {
                                  label: "Enter a first name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.firstNameSearch,
                                  callback: function ($$v) {
                                    _vm.firstNameSearch = $$v
                                  },
                                  expression: "firstNameSearch",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2 mb-2",
                                  attrs: { small: "", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.firstNameSearch = ""
                                    },
                                  },
                                },
                                [_vm._v("Clear")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "header.last_name",
              fn: function ({ header }) {
                return [
                  _c(
                    "div",
                    { staticStyle: { "align-items": "center" } },
                    [
                      _vm._v(" " + _vm._s(header.text) + " "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(" mdi-filter "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "white",
                                width: "280px",
                              },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-4",
                                attrs: {
                                  label: "Enter a last name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.lastNameSearch,
                                  callback: function ($$v) {
                                    _vm.lastNameSearch = $$v
                                  },
                                  expression: "lastNameSearch",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2 mb-2",
                                  attrs: { small: "", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.lastNameSearch = ""
                                    },
                                  },
                                },
                                [_vm._v("Clear")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "header.email",
              fn: function ({ header }) {
                return [
                  _c(
                    "div",
                    { staticStyle: { "align-items": "center" } },
                    [
                      _vm._v(" " + _vm._s(header.text) + " "),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(" mdi-filter "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "white",
                                width: "280px",
                              },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-4",
                                attrs: {
                                  label: "Enter an email address",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.emailSearch,
                                  callback: function ($$v) {
                                    _vm.emailSearch = $$v
                                  },
                                  expression: "emailSearch",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2 mb-2",
                                  attrs: { small: "", text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.emailSearch = ""
                                    },
                                  },
                                },
                                [_vm._v("Clear")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.editItem(item)
                        },
                      },
                    },
                    [_vm._v(" mdi-pencil ")]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(item)
                        },
                      },
                    },
                    [_vm._v(" mdi-delete ")]
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }