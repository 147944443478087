import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
      username: "",
      loggedIn: false,
      token: "",
      isAdmin: false,
      userType: "",
    },
    containerAdmin: {
      containerPage: "", //Page of Containers Page
      containerBookingPage: "", // Last visited ContainerBookings Page
      containerBookingRow: "",
      containerBookingExpanded: [], //Array of expanded ContainerBooking rows
    },
  },
  mutations: {
    logIn(state, payload) {
      state.user.loggedIn = payload.isLoggedIn;
      state.user.username = payload.username;
      state.user.token = payload.token;
      state.user.isAdmin = payload.isAdmin;
      state.user.userType = payload.userType;
    },
    logOut(state) {
      state.user.loggedIn = false;
      (state.user.username = ""),
        (state.user.token = ""),
        (state.user.isAdmin = false);
      state.user.userType = "";
    },
    updateContainerPage(state, pagination) {
      state.containerAdmin.containerPage = pagination;
    },
    updateContainerBookingPage(state, pagination) {
      state.containerAdmin.containerBookingPage = pagination;
    },
    updateContainerBookingRow(state, pagination) {
      state.containerAdmin.containerBookingRow = pagination;
    },
    updateContainerBookingExpanded(state, pagination) {
      state.containerAdmin.containerBookingExpanded = pagination;
    },
  },
  getters: {
    getUserToken: (state) => {
      return state.user.token;
    },
    getAdminStatus: (state) => {
      return state.user.isAdmin;
    },
    getUsername: (state) => {
      return state.user.username;
    },
    getUserType: (state) => {
      return state.user.userType;
    },
    getContainerPage: (state) => {
      return state.containerAdmin.containerPage;
    },
    getContainerBookingPage: (state) => {
      return state.containerAdmin.containerBookingPage;
    },
    getContainerBookingRow: (state) => {
      return state.containerAdmin.containerBookingRow;
    },
    getContainerBookingExpanded: (state) => {
      return state.containerAdmin.containerBookingExpanded;
    },
    getUserLogin: (state) => {
      return state.user.loggedIn;
    },
  },
  actions: {},
  modules: {},
});
