<template>
  <v-container>
    <v-alert dismissible border="left" type="error" v-model="isError">
      {{ errorMessage }}
    </v-alert>
    <v-alert dismissible border="left" type="success" v-model="isSuccess">
      Saved Successfully.
    </v-alert>
    <UserDetails :user="user" :loading="loading" @eventname="CreateUser" />
  </v-container>
</template>

<script>
const api = require("@/api/api.js");
import UserDetails from "@/components/Users/Details.vue";

export default {
  Name: "UserCreate",
  components: {
    UserDetails,
  },
  data() {
    return {
      isNew: true,
      user: {
        phone_number: "",
        first_name: "",
        last_name: "",
        temp_number: "",
      },
      loading: false,
      isError: false,
      isSuccess: false,
      errorMessage: "",
    };
  },
  async mounted() {},
  methods: {
    async CreateUser(user) {
      this.loading = true;
      this.isSuccess = false;
      try {
        await api.post("/users", user);
        this.loading = false;
        this.isSuccess = true;
        this.$router.push({ name: "UserManagement" });
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.isError = true;
        this.errorMessage = error.response.data;
      }
    },
  },
};
</script>

<style></style>
