<template>
  <div>
    <v-bottom-navigation
      v-if="isUserAllowed(['Admin', 'Master'])"
      class="d-lg-none d-md-none d-sm-none"
      color="primary"
      grow
      fixed
    >
      <v-btn to="/users">
        <span>User</span>
        <v-icon medium>mdi-account-circle</v-icon>
      </v-btn>
      <v-btn to="/containers/admin">
        <span>Container</span>
        <v-icon medium>mdi-truck-cargo-container</v-icon>
      </v-btn>
      <v-btn
        to="/outbound/summary"
        v-if="isUserAllowed(['Party', 'Admin', 'Master'])"
      >
        <span>Bookings</span>
        <v-icon medium>mdi-border-color</v-icon>
      </v-btn>
      <v-btn to="/deliveries/deliveries">
        <span>Inbound</span>
        <v-icon medium>mdi-border-color</v-icon>
      </v-btn>
      <v-btn to="/outbound/comments">
        <span>Booking<br />Comments</span>
        <v-icon medium>mdi-comment-outline</v-icon>
      </v-btn>

      <v-btn to="/fumigation/containers">
        <span>Fumigation</span>
        <v-icon medium>mdi-smoke</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      v-if="isUserAllowed(['Party'])"
      class="d-lg-none d-md-none d-sm-none"
      color="primary"
      grow
      fixed
    >
      <v-btn to="/outbound/summary">
        <span>Bookings</span>
        <v-icon medium>mdi-border-color</v-icon>
      </v-btn>
      <v-btn to="/deliveries/deliveries">
        <span>Deliveries</span>
        <v-icon medium>mdi-truck-delivery</v-icon>
      </v-btn>

      <v-btn to="/forecast">
        <span>Forecast</span>
        <v-icon medium>mdi-smoke</v-icon>
      </v-btn>
      <v-btn to="/tickets">
        <span>Tickers</span>
        <v-icon medium>mdi-ticket</v-icon>
      </v-btn>
      <v-btn to="/outbound/comments">
        <span>Booking<br />Comments</span>
        <v-icon medium>mdi-comment-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      v-if="isUserAllowed(['Trucker'])"
      class="d-lg-none d-md-none d-sm-none"
      color="primary"
      grow
      fixed
    >
      <v-btn to="/booking/driver">
        <span>Schedule</span>
        <v-icon medium>mdi-border-color</v-icon>
      </v-btn>
      <v-btn to="/deliveries/deliveries">
        <span>Deliveries</span>
        <v-icon medium>mdi-truck-delivery</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-bottom-navigation
      v-if="isUserAllowed(['Fumigator'])"
      class="d-lg-none d-md-none d-sm-none"
      color="primary"
      grow
      fixed
    >
      <v-btn to="/deliveries/deliveries">
        <span>Deliveries</span>
        <v-icon medium>mdi-truck-delivery</v-icon>
      </v-btn>
      <v-btn to="/fumigation/containers">
        <span>Fumigation</span>
        <v-icon medium>mdi-smoke</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { isUserAllowed } from "@/helpers.js";

export default {
  name: "BottomBar",
  methods: {
    isUserAllowed,
  },
};
</script>

<style scoped>
span {
  font-size: 0.75em;
}

.v-item-group.v-bottom-navigation {
  margin-bottom: 40px;
  height: 70px;
  margin-top: 100px;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 60px;
  text-align: center;
}
</style>
