<template>
  <v-container>
    <v-alert dismissible border="left" type="error" v-model="isError">
      Error editing user.
    </v-alert>
    <v-alert dismissible border="left" type="success" v-model="isSuccess">
      Saved Successfully.
    </v-alert>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="green"
        :size="70"
        :width="7"
      ></v-progress-circular>
    </v-overlay>
    <UserDetails :user="user" @eventname="UpdateUser" :loading="isLoading" />
  </v-container>
</template>

<script>
const api = require("@/api/api.js");
import UserDetails from "@/components/Users/Details.vue";

export default {
  Name: "UserEdit",
  components: {
    UserDetails,
  },
  data() {
    return {
      isLoading: true,
      user: {
        temp_number: "",
      },
      isError: false,
      isSuccess: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.username = this.$route.params.username;

    console.log(this.username);

    if (this.username === "" || this.username === undefined) {
      this.$router.push({ Name: "UserManagement" }).catch(() => {});
    } else {
      await this.GetUser(this.username);
    }
  },
  methods: {
    async GetUser(username) {
      try {
        this.user = await api.get("/users/" + username);
        this.user.temp_number = ("" + this.user.phone_number).replace(
          /\D/g,
          ""
        );
        let match = this.user.temp_number.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          this.user.temp_number = [
            "(",
            match[2],
            ") ",
            match[3],
            "-",
            match[4],
          ].join("");
        }
        console.log(this.user);
        this.isLoading = false;
        console.log(this.user);
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },

    async UpdateUser(user) {
      this.isSuccess = false;
      this.isLoading = true;
      try {
        await api.put("/users", user);
        this.isLoading = false;
        this.isSuccess = true;
      } catch (err) {
        console.log(err);
        this.isError = true;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
