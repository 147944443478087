var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app-bar", { attrs: { color: "secondary" } }, [
    _c("div", { staticClass: "d-flex align-center" }, [
      _c("h3", [_vm._v(_vm._s(_vm.levelList))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }