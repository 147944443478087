<template>
  <v-container fluid>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>

    <v-container fluid>
      <v-row no-gutters>
        <v-col md="8" offset-md="4">
          <v-btn
            style="float: right"
            @click="CreateUser"
            depressed
            color="secondary"
            class="primary--text"
            ><v-icon>mdi-plus</v-icon> Add A User</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :items="usersArray"
      class="elevation-1"
      :loading="loading"
      :headers="headers"
      :sort-by.sync="sort_by"
      :sort-desc.sync="sortDesc"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      loading-text="Loading... Please wait"
    >
      <template v-slot:header.first_name="{ header }">
        <div style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="firstNameSearch"
                label="Enter a first name"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="firstNameSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.last_name="{ header }">
        <div style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="lastNameSearch"
                label="Enter a last name"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="lastNameSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.email="{ header }">
        <div style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="emailSearch"
                label="Enter an email address"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="emailSearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
const api = require("@/api/api.js");

export default {
  name: "UserManagement",

  data() {
    return {
      firstNameSearch: "",
      lastNameSearch: "",
      emailSearch: "",
      usersArray: [],
      isError: false,
      loading: true,
      sort_by: "last_name",
      sortDesc: true,
      alertMessage: "",
      headers: [
        {
          text: "First Name",
          value: "first_name",
          filter: (value) => {
            if (!this.firstNameSearch || this.firstNameSearch === "")
              return true;
            return (
              value != null &&
              this.firstNameSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.firstNameSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Last Name",
          value: "last_name",
          filter: (value) => {
            if (!this.lastNameSearch || this.lastNameSearch === "") return true;
            return (
              value != null &&
              this.lastNameSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.lastNameSearch.toString().toLocaleUpperCase()) !==
                -1
            );
          },
        },
        {
          text: "Email",
          value: "email",
          filter: (value) => {
            if (!this.emailSearch || this.emailSearch === "") return true;
            return (
              value != null &&
              this.emailSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.emailSearch.toString().toLocaleUpperCase()) !== -1
            );
          },
        },
        { text: "Active", value: "Enabled" },
        { text: "User Created On", value: "UserCreateDate" },
        {
          text: "User Last Modified On",
          value: "UserLastModifiedDate",
        },
        { text: "Role", value: "group" },
        { text: "Party", value: "Party" },
        { text: "Status", value: "UserStatus" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() {
    this.GetAllUsers();
  },
  methods: {
    async GetAllUsers() {
      try {
        const responses = await Promise.all([
          api.get("/users?request=all"),
          api.get("/users/parties"),
        ]);
        this.usersArray = responses[0];
        const parties = responses[1];
        this.usersArray = this.usersArray.map((user) => {
          let partyName = "";
          if (user.party_id !== "") {
            let party = parties.filter((thirdParty) => {
              return thirdParty.party_id.toString() === user.party_id;
            });
            if (party.length) {
              partyName = party[0].name;
            }
          }
          return { ...user, Party: partyName };
        });
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.isError = true;
        this.loading = false;
        this.alertMessage = "Failed to load users. Please try again.";
      }
    },
    CreateUser() {
      this.$router.push({ name: "UserCreate" });
    },
    editItem(item) {
      this.$router.push({
        name: "UserDetails",
        params: { username: item.Username },
      });
    },
    async deleteItem(item) {
      this.loading = true;
      try {
        await api.del("/users/" + item.Username);
        this.usersArray = await api.get("/users?request=all");
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.alertMessage = "Error deleteting user: " + item.Username;
        console.log(err);
      }
    },
  },
};
</script>
