<template>
  <div class="container-admin">
    <v-row>
      <v-alert
        dismissible
        border="left"
        type="error"
        v-model="isError"
        class="message"
      >
        {{ errorMessage }}
      </v-alert>
      <v-alert
        dismissible
        border="left"
        type="success"
        v-model="isSuccess"
        class="message"
      >
        {{ successMessage }}
      </v-alert>
    </v-row>
    <v-row class="pt-3 justify-center">
      <v-slide-group
        v-model="model"
        hide-delimiters
        center-active
        show-arrows
        class="carousel"
        style="box-shadow: 0px 0px; height: 120px; padding: 10px"
      >
        <v-slide-item
          v-for="(inv, idx) in inventory"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <v-card class="carousel-card" @click="toggle">
            <div class="mt-2 ml-3 pl-3 pt-5">
              <v-row> <b>Item Number:&nbsp;</b>{{ inv.ItemNumber }} </v-row>
              <v-row>
                <b>Account Name:&nbsp; </b> {{ inv.AccountName | lowercase }}
              </v-row>
              <v-row
                ><b>Quantity on Hand:&nbsp; </b> {{ inv.QuantityOnHand }}</v-row
              >
              <v-row>
                <b>Last Updated Date:&nbsp; </b>
                {{
                  inv.LastUpdatedDate.split("T")[0] +
                  " " +
                  inv.LastUpdatedDate.split("T")[1].split(".")[0]
                }}
              </v-row>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="8" lg="9">
        <v-card>
          <h3 class="pl-2 pt-2">Bookings</h3>
          <v-data-table
            :headers="bookingsHeader"
            :items="IncompleteBookings()"
            item-key="BookingId"
            :loading="bookingLoading"
            :expanded.sync="expanded"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50, -1],
            }"
            :items-per-page="30"
            class="elevation-1 table"
            show-expand
            @item-expanded="ExpandedEvent"
            @pagination="PaginationUpdate"
          >
            <template v-slot:header.ShipperName="{ header }">
              <div style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="bookingShipperSearch"
                      label="Enter a shipper"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="bookingShipperSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:header.BookingNumber="{ header }">
              <div style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="bookingNumberSearch"
                      label="Enter a booking number"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="bookingNumberSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:header.CommodityName="{ header }">
              <div style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="commoditySearch"
                      label="Enter a commodity"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="commoditySearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:item.CommodityName="{ item }">
              <v-chip dark label :color="getCommodityColor(item.CommodityName)">
                {{ item.CommodityName }}
              </v-chip>
            </template>
            <template v-slot:header.ContainerTypeName="{ header }">
              <div style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="bookingContainerTypeSearch"
                      label="Enter a container type"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="bookingContainerTypeSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.booking`]="{ item }">
              <router-link
                :to="'/outbound/edit/' + item.booking"
                class="text-decoration-underline"
                >{{ item.booking }}</router-link
              >
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-text-field
                    v-model="bookingSearch"
                    label="Enter a booking number"
                    class="pa-4"
                    type="text"
                  ></v-text-field>
                  <v-btn
                    @click="bookingSearch = ''"
                    small
                    text
                    class="ml-2 mb-2"
                    >Clean</v-btn
                  >
                </div>
              </v-menu>
            </template>
            <template v-slot:[`item.BookingQuantity`]="{ item }">
              <span>
                {{ item.BookingQuantity || 0 }} /
                {{ item.ContainersLoaded || 0 }} /
                {{ item.ContainersAssigned || 0 }}
              </span>
            </template>
            <template v-slot:[`item.PrioritizeFlag`]="{ item }">
              <v-icon small>
                {{ item.PrioritizeFlag ? "mdi-flag" : "" }}
              </v-icon>
            </template>
            <template v-slot:[`item.EarliestReleaseDate`]="{ item }">
              <span>{{ item.EarliestReleaseDate.split("T")[0] }}</span>
            </template>
            <template v-slot:[`item.CutDate`]="{ item }">
              <span>{{ item.CutDate.split("T")[0] }}</span>
            </template>
            <template v-slot:[`item.upRelease`]="{ item }">
              <div class="d-inline-flex justify-content: space-between">
                <v-btn
                  icon="mdi-plus"
                  size="x-small"
                  @click="requestAdditonalContainers(item)"
                  :loading="adhocRequestLoading"
                  style="margin-right: 10px"
                >
                  <v-icon> mdi-plus-circle </v-icon>
                </v-btn>

                <v-btn
                  color="secondary"
                  class="text--primary"
                  @click="ReleaseToG4(item)"
                  :loading="g4Loading"
                  >G4</v-btn
                >
              </div>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length / 2">
                <v-data-table
                  :headers="bookingContainerHeader"
                  :items="BookingContainers(item)"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50, -1],
                  }"
                  :items-per-page="20"
                  :loading="containerBookingsLoading"
                  class="elevation-1 bookingContainerTable"
                >
                  <template v-slot:header.ContainerNumber="{ header }">
                    <div class="d-flex" style="align-items: center">
                      {{ header.text }}
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon small> mdi-filter </v-icon>
                          </v-btn>
                        </template>
                        <div style="background-color: white; width: 280px">
                          <v-text-field
                            v-model="bookingContainerNumberSearch"
                            label="Enter a container number"
                            class="pa-4"
                            type="text"
                          ></v-text-field>
                          <v-btn
                            @click="bookingContainerNumberSearch = ''"
                            small
                            text
                            class="ml-2 mb-2"
                            >Clear</v-btn
                          >
                        </div>
                      </v-menu>
                    </div>
                  </template>
                  <template v-slot:[`item.LoadDate`]="{ item }">
                    {{
                      item.LoadDate
                        ? item.LoadDate.split("T")[0]
                        : "No Date Available"
                    }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      icon="mdi-delete"
                      size="small"
                      @click="RemoveContainer(item)"
                      :loading="workorderLoading"
                      style="margin-right: 10px"
                    >
                      <v-icon
                        v-show="
                          [
                            'Assigned',
                            'PendingTransLoad',
                            'PendingSale',
                            'PendingLoad',
                            'Modified',
                            'PendingScale',
                          ].includes(item.BookingContainerEventStatusName)
                        "
                        small
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <h4 v-show="DroppedBookingContainers(item).length > 0">
                  Containers To Be Added
                </h4>
                <v-data-table
                  v-if="DroppedBookingContainers(item).length > 0"
                  :headers="droppedContainerHeader"
                  :items="DroppedBookingContainers(item)"
                  :items-per-page="5"
                  class="elevation-1 bookingContainerTable"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="RemoveDroppedContainer(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </td>
              <td :colspan="headers.length / 2">
                <div class="drop-container">
                  <h4>Drag Containers Here to Assign</h4>
                  <div
                    class="drop-zone"
                    @drop="OnDrop($event, item)"
                    @dragover.prevent
                    @dragenter.prevent
                    :style="{ backgroundColor: dragColor }"
                  ></div>
                </div>
              </td>
            </template>
          </v-data-table>
          <v-row>
            <v-btn
              v-if="droppedContainers.length > 0"
              color="secondary"
              class="button text--primary mb-5"
              id="submit-containers"
              @click="SubmitContainers()"
              :loading="submitContainers"
            >
              Submit Containers
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="4" lg="3">
        <v-card class="containers">
          <!--
          <div class="container-request pt-2 pl-2">
            <h3>Request New Container</h3>
            <v-row>
              <v-col cols="12" :xl="4" :lg="4">
                <v-select
                  v-model="containerShipper"
                  :items="containerShippers"
                  item-text="Name"
                  item-value="Name"
                  label="Select Container Shipper"
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" :xl="4" :lg="4">
                <v-select
                  v-model="containerType"
                  :items="containerTypes"
                  item-text="Name"
                  item-value="Description"
                  label="Select Container Type"
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" :xl="4" :lg="4">
                <v-btn
                  color="secondary"
                  class="button text--primary"
                  id="requestContainers"
                  @click="RequestContainers"
                  :loading="requestLoading"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
            <v-row> </v-row>
          </div>
        -->
          <h3 class="pl-2 pt-2">Containers</h3>
          <v-data-table
            :headers="containerHeaders"
            :items="AvailableContainers()"
            item-key="name"
            :loading="loadingContainers"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50, -1],
            }"
            :items-per-page="20"
            class="elevation-1 table"
            :search="search"
          >
            <template v-slot:header.ContainerNumber="{ header }">
              <div class="d-flex" style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="containerNumberSearch"
                      label="Enter a container number"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="containerNumberSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:header.ShipperName="{ header }">
              <div class="d-flex" style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="shipperSearch"
                      label="Enter a shipper"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="shipperSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:header.ContainerTypeName="{ header }">
              <div class="d-flex" style="align-items: center">
                {{ header.text }}
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon small> mdi-filter </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <v-text-field
                      v-model="containerTypeSearch"
                      label="Enter a container type"
                      class="pa-4"
                      type="text"
                    ></v-text-field>
                    <v-btn
                      @click="containerTypeSearch = ''"
                      small
                      text
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </div>
            </template>
            <template v-slot:[`item.ContainerNumber`]="{ item }">
              <div
                class="drag-el"
                :key="item.container"
                draggable
                @dragstart="StartDrag($event, item)"
                style="width: 130px"
              >
                <v-icon small> mdi-drag </v-icon>
                {{ item.ContainerNumber }}
              </div>
            </template>
            <template v-slot:[`item.containerType`]="{ item }">
              {{ item.ContainerTypeName }}
            </template>
            <template v-slot:[`item.steamShipLine`]="{ item }">
              {{ item.ShipperDescription }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon="mdi-delete"
                size="small"
                @click="DeleteContainer(item)"
                :loading="reservationLoading"
                style="margin-right: 10px"
              >
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const api = require("@/api/api.js");
const { DateTime, Settings } = require("luxon");
Settings.defaultZoneName = "America/Chicago";

export default {
  name: "ContainerAdministration",

  components: {},
  data() {
    return {
      model: 0,
      alertMessage: "",
      bookingLoading: true,
      bookings: [],
      bookingContainerNumberSearch: "",
      bookingContainerTypeSearch: "",
      bookingNumberSearch: "",
      bookingSearch: "",
      bookingShipperSearch: "",
      commoditySearch: "",
      containerBookings: [],
      containerBookingsLoading: true,
      containerNumberSearch: "",
      containers: [],
      containerShipper: {},
      containerShippers: [],
      containerType: {},
      containerTypes: [],
      containerTypeSearch: "",
      dragColor: "#FFCC00",
      droppedContainers: [],
      errorMessage: "",
      expanded: [],
      g4Loading: false,
      adhocRequestLoading: false,
      inventory: [],
      isError: false,
      isSuccess: false,
      loading: true,
      workorderLoading: false,
      reservationLoading: false,
      removedContainers: [],
      requestLoading: true,
      loadingContainers: true,
      search: "",
      shipperSearch: "",
      singleExpand: false,
      submitContainers: false,
      successMessage: "",
      unsavedChanges: false,
    };
  },
  computed: {
    bookingContainerHeader() {
      return [
        {
          text: "Container Number",
          value: "ContainerNumber",
          filter: (value) => {
            if (
              !this.bookingContainerNumberSearch ||
              this.bookingContainerNumberSearch === ""
            )
              return true;
            return (
              value != null &&
              this.bookingContainerNumberSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.bookingContainerNumberSearch
                    .toString()
                    .toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Load Date",
          value: "LoadDate",
        },
        {
          text: "Status",
          value: "BookingContainerEventStatusName",
        },
        { text: "Cancel", value: "actions", sortable: false },
      ];
    },
    bookingsHeader() {
      return [
        {
          text: "",
          value: "data-table-expand",
          width: "2%",
        },
        {
          text: "Status",
          value: "BookingStatusName",
          width: "5%",
        },
        {
          text: "Booking Number",
          value: "BookingNumber",
          width: "10%",
          filter: (value) => {
            if (!this.bookingNumberSearch || this.bookingNumberSearch === "")
              return true;
            return (
              value != null &&
              this.bookingNumberSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.bookingNumberSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Total / Loaded / Assigned",
          value: "BookingQuantity",
          width: "15%",
        },
        { text: "Inspection", value: "InspectionRequired", width: "5%" },
        {
          text: "Commodity",
          value: "CommodityName",
          width: "5%",
          filter: (value) => {
            if (!this.commoditySearch || this.commoditySearch === "")
              return true;
            return (
              value != null &&
              this.commoditySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.commoditySearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Container Type",
          value: "ContainerTypeName",
          width: "5%",
          filter: (value) => {
            if (
              !this.bookingContainerTypeSearch ||
              this.bookingContainerTypeSearch === ""
            )
              return true;
            return (
              value != null &&
              this.bookingContainerTypeSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.bookingContainerTypeSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Steam Ship Line",
          value: "ShipperName",
          width: "9%",
          sortable: false,
          filter: (value) => {
            if (!this.bookingShipperSearch || this.bookingShipperSearch === "")
              return true;
            return (
              value != null &&
              this.bookingShipperSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.bookingShipperSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        { text: "Prioritized", value: "PrioritizeFlag", width: "1%" },
        { text: "ERD", value: "EarliestReleaseDate", width: "12%" },
        { text: "Cut", value: "CutDate", width: "12%" },
        { text: "Destination", value: "DestinationName", width: "10%" },
        { text: "Party", value: "PartyName", width: "10%" },
        { text: "Request/Release", value: "upRelease", width: "10%" },
      ];
    },
    containerHeaders() {
      return [
        {
          text: "Container #",
          value: "ContainerNumber",
          filter: (value) => {
            if (
              !this.containerNumberSearch ||
              this.containerNumberSearch === ""
            )
              return true;
            return (
              value != null &&
              this.containerNumberSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.containerNumberSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Container Type",
          value: "ContainerTypeName",
          sortable: false,
          filter: (value) => {
            if (!this.containerTypeSearch || this.containerTypeSearch === "")
              return true;
            return (
              value != null &&
              this.containerTypeSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.containerTypeSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        {
          text: "Steam Ship Line",
          value: "ShipperName",
          sortable: false,
          filter: (value) => {
            if (!this.shipperSearch || this.shipperSearch === "") return true;
            return (
              value != null &&
              this.shipperSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.shipperSearch.toString().toLocaleUpperCase()) !==
                -1
            );
          },
        },
        { text: "Cancel", value: "actions", sortable: false },
      ];
    },
    containerInBookingList() {
      return this.containers.filter((container) => container.position === 1);
    },
    containerList() {
      return this.containers.filter((container) => container.position === 0);
    },
    containerPage() {
      return this.$store.getters.getContainerPage;
    },
    droppedContainerHeader() {
      return [
        { text: "Container Number", value: "ContainerNumber" },
        { text: "Cancel", value: "actions", sortable: false },
      ];
    },
  },
  filters: {
    lowercase: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.toLowerCase();
    },
  },
  async mounted() {
    await Promise.all([
      this.GetAgrisInventory(),
      this.GetContainerShipper(),
      this.GetContainerSummary(),
      this.GetContainerTypes(),
      this.GetLoadBooking(),
      this.GetLoadSummary(),
    ]);
  },
  methods: {
    AvailableContainers() {
      return this.containers.filter(
        (container) => !["63", "66"].includes(container.StatusId)
      );
    },
    BookingContainers(item) {
      return this.containerBookings.filter(
        (booking) => booking.BookingId == item.BookingId
      );
    },
    async DeleteContainer(container) {
      this.reservationLoading = true;
      await api
        .del(`/containers/${container.ContainerId}`)
        .then(() => {
          let index = this.containers.findIndex(
            (c) => c.ContainerId == container.ContainerId
          );
          this.$delete(this.containers, index);
          this.isSuccess = true;
          this.successMessage = "Container Successfully Rejected!";
          this.reservationLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.reservationLoading = false;
          this.isError = true;
          this.errorMessage =
            "Could not reject container. Please confirm connection with Union Pacific API.";
        });
    },
    DroppedBookingContainers(item) {
      return this.droppedContainers.filter((booking) => {
        return booking.BookingId === item.BookingId;
      });
    },
    ExpandedEvent(expand) {
      this.$store.commit("updateContainerBookingExpanded", expand);
    },
    async GetAgrisInventory() {
      try {
        this.inventory = await api.get("/inventory");
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Failed to get Agris Inventory. Please try again later.";
      }
    },
    async GetContainerShipper() {
      try {
        this.containerShippers = await api.get("/containers/ssl");
        this.requestLoading = false;
      } catch (err) {
        this.requestLoading = true;
        this.isError = true;
        this.alertMessage = "Failed to get container shippers";
        console.log(err);
      }
    },
    async GetContainerSummary() {
      try {
        this.loadingContainers = true;
        this.containers = await api.get("/containers");
        this.loadingContainers = false;
      } catch (err) {
        this.loadingContainers = false;
        this.isError = true;
        this.alertMessage = "Failed to get containers";
      }
    },
    async GetContainerTypes() {
      try {
        this.containerTypes = await api.get("/containers/types");
        this.requestLoading = false;
      } catch (err) {
        this.requestLoading = true;
        this.isError = true;
        this.alertMessage = "Failed to get container types";
      }
    },
    async GetLoadBooking() {
      try {
        this.containerBookingsLoading = true;
        this.containerBookings = await api.get("/load/bookings/containers"); //vwBookingContainer
        this.containerBookingsLoading = false;
      } catch (err) {
        this.containerBookingsLoading = false;
        this.isError = true;
        this.alertMessage = "Failed to get booking. Please try again.";
      }
    },
    async GetLoadSummary() {
      try {
        this.bookings = await api.get("/load/bookingsummary"); //vwBookingContainer_Count
        console.log(this.bookings);
        this.bookingLoading = false;
      } catch (err) {
        this.bookingLoading = false;
        this.isError = true;
        this.alertMessage = "Failed to get load summary. Please try again.";
      }
    },
    IncompleteBookings() {
      return this.bookings.filter(
        (booking) => !booking.BookingStatusName.includes("Completed")
      );
    },
    OnDrop(evt, list) {
      const containerShipper = evt.dataTransfer.getData("shipper");
      const containerTypeId = evt.dataTransfer.getData("containerTypeId");
      const ContainerTypeDescription = evt.dataTransfer.getData(
        "containerTypeDescription"
      );
      const containerId = evt.dataTransfer.getData("containerId");
      const containerNumber = evt.dataTransfer.getData("containerNumber");
      const ReservationId = evt.dataTransfer.getData("reservation");
      //Check if today is withing CUT date and ERD date
      let today = DateTime.now().setZone("America/Chicago").toISODate();
      let cut = DateTime.fromISO(list.CutDate).plus({ days: 1 }).toString();
      let erd = DateTime.fromISO(list.EarliestReleaseDate).toString();
      //Check if fumigation flag is active. If so containers can be added three days before ERD
      if (
        list.InspectionRequired === "F" ||
        list.InspectionRequired === "F/M"
      ) {
        erd = DateTime.fromISO(list.EarliestReleaseDate)
          .minus({ days: 3 })
          .toString();
      }
      if (
        today.split("T")[0] < erd.split("T")[0] ||
        today.split("T")[0] > cut.split("T")[0]
      ) {
        this.isError = true;
        this.errorMessage = `Current date needs to be between ERD and Cut date.`;
        return;
      }
      //Check if the booking can take more containers
      if (
        list.ContainersLoaded +
          list.ContainersAssigned +
          (this.droppedContainers.length + 1) >
        list.BookingQuantity
      ) {
        this.isError = true;
        this.errorMessage = `Cannot add any more containers. The maximum is ${list.BookingQuantity}.`;
        return;
      }
      //Check if the container type is valid
      //Check if the booking is in pending update status, don't let containers be added.
      if (list.StatusId === 26) {
        this.isError = true;
        this.errorMessage = `Booking ${list.BookingNumber} can't have containers added while it's in Pending Update Review status`;
        return;
      }
      if (containerShipper !== list.ShipperName) {
        this.isError = true;
        this.errorMessage = `Cannot add Container ${containerNumber} to Booking ${list.BookingNumber}. SSL and container type must match and number of containers cannot exceed the container quantity on the booking`;
        return;
      } else {
        /*
                  Container Types: ContainerTypeId
                  1 - 20' General Purpose
                  2 - 40' General Purpose
                  3 - 40' High Cube
                  4 - 40' Standard
                  If booking container type is General Purpose it can take High Cube or Standard. Else High Cubes can only take High Cubes, Standard can only take Standard.
                */
        if (list.ContainerTypeId != 2) {
          if (containerTypeId != list.ContainerTypeId) {
            this.isError = true;
            this.errorMessage = `Cannot add Container Type of ${ContainerTypeDescription}. This Booking only accepts container types of ${list.ContainerTypeDesciption}`;
            return;
          }
        } else {
          if (containerTypeId == 1) {
            this.isError = true;
            this.errorMessage = `Cannot add Container Type of ${ContainerTypeDescription}. This Booking only accepts container types of ${list.ContainerTypeDesciption}`;
            return;
          }
        }
      }
      let tareWeight = evt.dataTransfer.getData("tareWeight");
      if (tareWeight === "null") {
        tareWeight = 0;
      }
      let newBookingContainer = {
        BookingId: list.BookingId,
        ReservationId: ReservationId,
        ContainerNumber: containerNumber,
        TareWeight: tareWeight,
        ModifiedUserId: "",
        BookingContainerEventStatusId: 30,
        BookingContainerId: 0,
        RequestBody: "",
        ResponseBody: "",
        Comments: "",
      };
      this.droppedContainers.push(newBookingContainer);
      let index = this.containers.findIndex(
        (container) => container.ContainerId == containerId
      );
      this.removedContainers.push(this.containers[index]);
      this.$delete(this.containers, index);
    },
    PaginationUpdate(page) {
      this.$store.commit("updateContainerPage", page);
    },
    async ReleaseToG4(item) {
      try {
        this.g4Loading = true;
        let bookingContainers = [];
        let containerNumberAndReservation = [];

        //Clear Previous messages
        this.isSuccess = false;
        this.isError = false;

        //Populate all booking containers into bookingContainers array
        bookingContainers.push(this.BookingContainers(item));
        bookingContainers.forEach((item) => {
          item.forEach((i) => {
            if (i.BookingContainerEventStatusId === 30) {
              containerNumberAndReservation.push({
                BookingContainerId: i.BookingContainerId,
                BookingId: i.BookingId,
                BookingNumber: i.BookingNumber,
                ContainerNumber: i.ContainerNumber,
                ReservationId: i.ReservationId,
                Fumigation: i.FumigateFlag,
                TareWeight: i.TareWeight,
                StccCode: i.StccCode,
              });
            }
          });
        });

        //We can only send to UP if it has booking containers
        if (containerNumberAndReservation.length > 0) {
          //Send to to be sent to UP. Await and send the message based on the result
          await api
            .put("/load/bookings/containers", containerNumberAndReservation)
            .then(() => {
              this.g4Loading = false;
              this.isSuccess = true;
              this.GetLoadBooking();
              this.successMessage = `Released Booking Number ${item.BookingNumber} to UP!`;
            })
            .catch((err) => {
              console.error(err);
              this.g4Loading = false;
              this.isError = true;
              this.errorMessage = "Unable to Release to UP";
            });
        } else {
          this.g4Loading = false;
          this.isError = true;
          this.errorMessage = `Booking ${item.BookingNumber} must have containers assigned before you can release to Union Pacific`;
        }
      } catch (err) {
        console.error(err);
        this.g4Loading = false;
        this.isError = true;
        this.errorMessage = "Unable to Release to UP";
      }
    },
    async requestAdditonalContainers(item) {
      console.log(item);
      this.adhocRequestLoading = true;
      try {
        if (item.ContainersRemaining === 0) {
          this.adhocRequestLoading = false;
          this.isError = true;
          this.errorMessage = `Cannot add any more containers. The maximum is ${item.BookingQuantity}.`;
        } else {
          this.isSuccess = false;
          this.isError = false;
          let requestedContainers = {
            owner_scac: item.ScacCode,
            length: item.Length,
            cube_type: item.CubeType,
            shipperId: item.ShipperId,
            containerTypeId: item.ContainerTypeId,
            stcc: item.StccCode,
            bookingNumber: item.BookingNumber,
            bookingId: item.BookingId,
            cutoff_datetime: item.CutDate,
          };
          await api
            .put("/containers", requestedContainers)
            .then(() => {
              this.adhocRequestLoading = false;
              this.isSuccess = true;
              this.successMessage = `Requested additional ${item.ContainerTypeDesciption} from ${item.ShipperName}`;
              this.GetLoadBooking();
              this.GetLoadSummary();
            })
            .catch((err) => {
              this.adhocRequestLoading = false;
              console.error(err);
              this.isError = true;
              this.errorMessage = "Could not request additional containers.";
            });
        }
      } catch (err) {
        this.adhocRequestLoading = false;
        console.error(err);
        this.isError = true;
        this.errorMessage = "Could not request additional containers.";
      }
    },
    RemoveBookingContainer(bookingContainers) {
      let index = this.containerBookings.findIndex(
        (container) => container.ContainerId == bookingContainers.ContainerId
      );
      this.$delete(this.containerBookings, index);
    },
    async RemoveContainer(bookingContainer) {
      try {
        this.workorderLoading = true;
        let removeBookingContainer = {
          BookingContainerId: bookingContainer.BookingContainerId,
          BookingId: bookingContainer.BookingId,
          ReservationId: bookingContainer.ReservationId,
          ContainerNumber: bookingContainer.ContainerNumber,
          TareWeight: bookingContainer.TareWeight,
          ModifiedUserId: "",
          BookingContainerEventStatusId:
            bookingContainer.BookingContainerEventStatusId,
          RequestBody: "",
          ResponseBody: "",
          Comments: "",
        };
        await api
          .put("/load/bookings/containers/delete", removeBookingContainer)
          .then(() => {
            this.workorderLoading = false;
            this.containerBookingsLoading = true;
            this.GetLoadBooking();
            this.GetLoadSummary();
            this.IncompleteBookings();
            this.GetContainerSummary();
          })
          .catch((err) => {
            console.error(err);
            this.workorderLoading = false;
            this.isError = true;
            this.errorMessage = "Error! Could Not Submit Booking Containers.";
            this.submitContainers = false;
          });
      } catch (err) {
        console.error(err);
        this.workorderLoading = false;
        this.submitContainers = false;
        this.isError = true;
        this.errorMessage = `Error! ${err}`;
      }
    },
    RemoveDroppedContainer(droppedContainer) {
      //We need to find the removed container from the containers list and add it back.
      let index = this.removedContainers.findIndex(
        (container) =>
          container.ContainerNumber.toString() ===
          droppedContainer.ContainerNumber.toString()
      );
      this.containers.push(this.removedContainers[index]);
      this.containers.sort((a, b) => a.ContainerId - b.ContainerId);
      this.$delete(this.removedContainers, index);

      //Then we need to remove the containers from the containers to be added list
      index = this.droppedContainers.findIndex(
        (container) =>
          container.ContainerNumber.toString() ===
          droppedContainer.ContainerNumber.toString()
      );
      this.$delete(this.droppedContainers, index);
    },
    async RequestContainers() {
      try {
        let requestedContainers = {
          owner_scac: this.containerShipper.ScacCode,
          length: this.containerType.Length,
          cube_type: this.containerType.CubeType,
          shipperId: this.containerShipper.ShipperID,
          containerTypeId: this.containerType.ContainerTypeId,
        };
        this.requestLoading = true;
        await api
          .put("/containers", requestedContainers)
          .then(() => {
            this.requestLoading = false;
            this.isSuccess = true;
            this.successMessage = `Requested additional ${this.containerType.Description} from ${this.containerShipper.Name}`;
          })
          .catch((err) => {
            this.requestLoading = false;
            console.error(err);
            this.isError = true;
            this.errorMessage = "Could not request additional containers.";
          });
        await this.GetContainerSummary();
      } catch (err) {
        this.requestLoading = false;
        console.error(err);
        this.isError = true;
        this.errorMessage = "Could not request additional containers.";
      }
    },
    StartDrag: (evt, item) => {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("containerId", item.ContainerId);
      evt.dataTransfer.setData("containerNumber", item.ContainerNumber);
      evt.dataTransfer.setData("tareWeight", item.TareWeight);
      evt.dataTransfer.setData("reservation", item.ReservationId);
      evt.dataTransfer.setData("shipper", item.ShipperName);
      evt.dataTransfer.setData("containerTypeId", item.ContainerTypeId);
      evt.dataTransfer.setData(
        "containerTypeDescription",
        item.ContainerTypeDescription
      );
    },
    async SubmitContainers() {
      try {
        this.submitContainers = true;
        console.log("submit containers");
        await api
          .post("/load/bookings/containers", this.droppedContainers)
          .then(() => {
            this.containerBookingsLoading = true;
            this.GetLoadBooking();
            this.GetLoadSummary();
            this.IncompleteBookings();
          })
          .catch((err) => {
            console.error(err);
            this.isError = true;
            this.errorMessage = "Error! Could Not Submit Booking Containers.";
            this.submitContainers = false;
          })
          .then(() => {
            this.droppedContainers = [];
            this.isSuccess = true;
            this.successMessage = "Updated Booking Containers!";
            this.submitContainers = false;
          })
          .catch((err) => {
            console.error(err);
            this.isError = true;
            this.errorMessage = "Error! Could Not Submit Booking Containers.";
            this.submitContainers = false;
          });
      } catch (err) {
        console.error(err);
        this.submitContainers = false;
        this.isError = true;
        this.errorMessage = `Error! ${err}`;
      }
    },
    getCommodityColor(Commodity) {
      if (Commodity.toLowerCase() === "soybeans1") return "red";
      else if (Commodity.toLowerCase() === "soybeans2") return "orange";
      else if (Commodity.toLowerCase() === "corn") return "yellow";
      else return "green";
    },
  },
};
</script>

<style scoped>
.v-application,
h1,
h2,
h3,
h4,
h5,
.v-list-item__title {
  font-size: 12px !important;
}

::v-deep .v-application {
  font-size: 12px !important;
}

::v-deep .v-list-item__title {
  font-size: 12px !important;
}

::v-deep .v-data-table {
  font-size: 12px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 12px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 12px !important;
}

.container-admin {
  padding: 10px;
  font-size: 12px;
}

.bookingContainerTable {
  width: 90%;
  margin-left: 50px;
}

.button {
  margin-top: 12px;
  width: 90%;
  height: 40px !important;
}

.carousel-card {
  width: 275px;
  margin-right: 7px;
  margin-left: 7px;
}

.colpad {
  padding: 10px;
}

.containers {
  position: sticky;
  right: 10px;
  top: 60px;
}

.container-request {
  margin-bottom: 25px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

.drop-container {
  min-height: 250px;
}

.drop-zone {
  margin-bottom: 10px;
  min-height: 200px;
}

.message {
  width: 100%;
  position: fixed;
  z-index: 2;
  top: 65px;
}

#request-containers {
  left: 0;
  width: 95%;
  padding: 0;
}

#submit-containers {
  margin-top: 20px;
  height: 40px !important;
  width: 98%;
  left: 15px;
}

tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.UPdown {
  max-width: 25px;
  max-height: 25px;
  margin-left: 25px;
}

.carousel {
  width: 1565px;
}

@media only screen and (max-width: 1565px) {
  .carousel {
    width: 1280px;
  }
}

@media only screen and (max-width: 1280px) {
  .carousel {
    width: 990px;
  }
}

@media only screen and (max-width: 990px) {
  .carousel {
    width: 700px;
  }
}

@media only screen and (max-width: 700px) {
  .carousel {
    width: 100%;
  }
}

@media only screen and (max-width: 959px) {
  .containers {
    position: relative;
    right: 0px;
  }
}
</style>
