var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "form mt-3",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Booking #",
                      required: "",
                      rules: _vm.requiredRules,
                      clearable: "",
                    },
                    model: {
                      value: _vm.load.bookingNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "bookingNumber", $$v)
                      },
                      expression: "load.bookingNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      items: _vm.partyAccount,
                      label: "Party Account",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    model: {
                      value: _vm.load.partyAccountId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "partyAccountId", $$v)
                      },
                      expression: "load.partyAccountId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledShipperField,
                      label: "SSL",
                      items: _vm.steamShipLine.filter(
                        (shipper) =>
                          shipper.active || shipper.value === _vm.load.shipperId
                      ),
                      "item-text": "text",
                      "item-value": "value",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    model: {
                      value: _vm.load.shipperId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "shipperId", $$v)
                      },
                      expression: "load.shipperId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Commodity",
                      items: _vm.commodity,
                      "item-text": "text",
                      "item-value": "value",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    model: {
                      value: _vm.load.commodityId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "commodityId", $$v)
                      },
                      expression: "load.commodityId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Container Type",
                      items: _vm.containerType.filter(
                        (containerType) =>
                          !_vm.hasContainersAssignedOrLoaded ||
                          (_vm.load.containerTypeId === 2 &&
                            ["40HQ", "40STD"].includes(containerType.text)) ||
                          _vm.load.containerTypeId === containerType.value
                      ),
                      "item-text": "text",
                      "item-value": "value",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    on: { change: _vm.setMaxWeight },
                    model: {
                      value: _vm.load.containerTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "containerTypeId", $$v)
                      },
                      expression: "load.containerTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Max Container Weight",
                      required: "",
                      rules: [_vm.numberRule, _vm.requiredRules],
                      outlined: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.load.MaxWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "MaxWeight", $$v)
                      },
                      expression: "load.MaxWeight",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.load.InvoicingStatusId === 96,
                      label: "Container quantity",
                      required: "",
                      rules: [_vm.numberRule, _vm.requiredRules, _vm.quantity],
                      outlined: "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.load.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "quantity", $$v)
                      },
                      expression: "load.quantity",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        disabled: _vm.disabledInputField,
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled: _vm.disabledInputField,
                                        label: "ERD Date",
                                        "prepend-icon": "mdi-calendar",
                                        required: "",
                                        rules: _vm.requiredRules,
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.load.earliestReleaseDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.load,
                                            "earliestReleaseDate",
                                            $$v
                                          )
                                        },
                                        expression: "load.earliestReleaseDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.datePickerTwo,
                        callback: function ($$v) {
                          _vm.datePickerTwo = $$v
                        },
                        expression: "datePickerTwo",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        on: {
                          input: function ($event) {
                            _vm.datePickerTwo = false
                          },
                        },
                        model: {
                          value: _vm.load.earliestReleaseDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.load, "earliestReleaseDate", $$v)
                          },
                          expression: "load.earliestReleaseDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        disabled: _vm.disabledInputField,
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled: _vm.disabledInputField,
                                        label: "Cut Date",
                                        "prepend-icon": "mdi-calendar",
                                        required: "",
                                        rules: _vm.requiredRules,
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.load.cutDate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.load, "cutDate", $$v)
                                        },
                                        expression: "load.cutDate",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.datePickerOne,
                        callback: function ($$v) {
                          _vm.datePickerOne = $$v
                        },
                        expression: "datePickerOne",
                      },
                    },
                    [
                      _c("v-date-picker", {
                        on: {
                          input: function ($event) {
                            _vm.datePickerOne = false
                          },
                        },
                        model: {
                          value: _vm.load.cutDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.load, "cutDate", $$v)
                          },
                          expression: "load.cutDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Destination",
                      items: _vm.destination,
                      loading: _vm.isLoading,
                      "item-text": "text",
                      "item-value": "value",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    model: {
                      value: _vm.load.destinationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "destinationId", $$v)
                      },
                      expression: "load.destinationId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Sample Destination Comment",
                      items: _vm.destinationComments,
                      loading: _vm.isLoading,
                      required: "",
                      rules: _vm.requiredRules,
                      "item-text": "text",
                      "item-value": "value",
                    },
                    model: {
                      value: _vm.load.SampleDestinationCommentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "SampleDestinationCommentId", $$v)
                      },
                      expression: "load.SampleDestinationCommentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Sample Requirements Comment",
                      items: _vm.sampleComments,
                      required: "",
                      rules: _vm.requiredRules,
                      loading: _vm.isLoading,
                      "item-text": "text",
                      "item-value": "value",
                    },
                    model: {
                      value: _vm.load.SampleRequirementCommentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "SampleRequirementCommentId", $$v)
                      },
                      expression: "load.SampleRequirementCommentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6", md: "2", lg: "2", xl: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      items: _vm.fumigation,
                      label: "Fumigation",
                      required: "",
                      rules: _vm.requiredRules,
                    },
                    model: {
                      value: _vm.load.fumigateFlag,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "fumigateFlag", $$v)
                      },
                      expression: "load.fumigateFlag",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.disabledInputField,
                      label: "Comments",
                      clearable: "",
                    },
                    model: {
                      value: _vm.load.comments,
                      callback: function ($$v) {
                        _vm.$set(_vm.load, "comments", $$v)
                      },
                      expression: "load.comments",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center",
                  attrs: { cols: "12", md: "6", lg: "4", xl: "4" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", md: "3", lg: "3", xl: "3" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          disabled: _vm.disabledInputField,
                          label: "Moldex",
                        },
                        model: {
                          value: _vm.load.linersFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.load, "linersFlag", $$v)
                          },
                          expression: "load.linersFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "5", lg: "5", xl: "5" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          disabled: _vm.disabledInputField,
                          label: "Additional Sample",
                        },
                        model: {
                          value: _vm.load.sampleFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.load, "sampleFlag", $$v)
                          },
                          expression: "load.sampleFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", md: "3", lg: "3", xl: "3" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          disabled: _vm.disabledInputField,
                          label: "Prioritize",
                        },
                        model: {
                          value: _vm.load.prioritizeFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.load, "prioritizeFlag", $$v)
                          },
                          expression: "load.prioritizeFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "d-flex justify-end" },
        [
          !_vm.isNew
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center mt-4",
                  attrs: { cols: "12", md: "3", lg: "1", xl: "1" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 ml-2 mt-3 text--primary",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.disabledInputField,
                        color: "secondary",
                      },
                      on: { click: _vm.CancelLoad },
                    },
                    [_vm._v(" Cancel Booking ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center mt-4",
              attrs: { cols: "12", md: "3", lg: "2", xl: "2" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2 ml-2 mt-3 text--primary",
                  attrs: {
                    disabled: _vm.disableUpdateButton,
                    loading: _vm.isLoading,
                    color: "secondary",
                  },
                  on: { click: _vm.SaveLoad },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        this.$route.params && this.$route.params.loadId
                          ? "Update Booking"
                          : "Create Booking"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }