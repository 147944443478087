var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-admin" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-alert",
            {
              staticClass: "message",
              attrs: { dismissible: "", border: "left", type: "error" },
              model: {
                value: _vm.isError,
                callback: function ($$v) {
                  _vm.isError = $$v
                },
                expression: "isError",
              },
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          ),
          _c(
            "v-alert",
            {
              staticClass: "message",
              attrs: { dismissible: "", border: "left", type: "success" },
              model: {
                value: _vm.isSuccess,
                callback: function ($$v) {
                  _vm.isSuccess = $$v
                },
                expression: "isSuccess",
              },
            },
            [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "pt-3 justify-center" },
        [
          _c(
            "v-slide-group",
            {
              staticClass: "carousel",
              staticStyle: {
                "box-shadow": "0px 0px",
                height: "120px",
                padding: "10px",
              },
              attrs: {
                "hide-delimiters": "",
                "center-active": "",
                "show-arrows": "",
              },
              model: {
                value: _vm.model,
                callback: function ($$v) {
                  _vm.model = $$v
                },
                expression: "model",
              },
            },
            _vm._l(_vm.inventory, function (inv, idx) {
              return _c("v-slide-item", {
                key: idx,
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ active, toggle }) {
                        return [
                          _c(
                            "v-card",
                            {
                              staticClass: "carousel-card",
                              on: { click: toggle },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mt-2 ml-3 pl-3 pt-5" },
                                [
                                  _c("v-row", [
                                    _c("b", [_vm._v("Item Number: ")]),
                                    _vm._v(_vm._s(inv.ItemNumber) + " "),
                                  ]),
                                  _c("v-row", [
                                    _c("b", [_vm._v("Account Name:  ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("lowercase")(inv.AccountName)
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("v-row", [
                                    _c("b", [_vm._v("Quantity on Hand:  ")]),
                                    _vm._v(" " + _vm._s(inv.QuantityOnHand)),
                                  ]),
                                  _c("v-row", [
                                    _c("b", [_vm._v("Last Updated Date:  ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          inv.LastUpdatedDate.split("T")[0] +
                                            " " +
                                            inv.LastUpdatedDate.split(
                                              "T"
                                            )[1].split(".")[0]
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", xs: "12", sm: "12", md: "8", lg: "9" } },
            [
              _c(
                "v-card",
                [
                  _c("h3", { staticClass: "pl-2 pt-2" }, [_vm._v("Bookings")]),
                  _c("v-data-table", {
                    staticClass: "elevation-1 table",
                    attrs: {
                      headers: _vm.bookingsHeader,
                      items: _vm.IncompleteBookings(),
                      "item-key": "BookingId",
                      loading: _vm.bookingLoading,
                      expanded: _vm.expanded,
                      "footer-props": {
                        "items-per-page-options": [10, 20, 30, 40, 50, -1],
                      },
                      "items-per-page": 30,
                      "show-expand": "",
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                      "item-expanded": _vm.ExpandedEvent,
                      pagination: _vm.PaginationUpdate,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.ShipperName",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { "align-items": "center" } },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a shipper",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.bookingShipperSearch,
                                              callback: function ($$v) {
                                                _vm.bookingShipperSearch = $$v
                                              },
                                              expression:
                                                "bookingShipperSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.bookingShipperSearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "header.BookingNumber",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { "align-items": "center" } },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a booking number",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.bookingNumberSearch,
                                              callback: function ($$v) {
                                                _vm.bookingNumberSearch = $$v
                                              },
                                              expression: "bookingNumberSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.bookingNumberSearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "header.CommodityName",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { "align-items": "center" } },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a commodity",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.commoditySearch,
                                              callback: function ($$v) {
                                                _vm.commoditySearch = $$v
                                              },
                                              expression: "commoditySearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.commoditySearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.CommodityName",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    dark: "",
                                    label: "",
                                    color: _vm.getCommodityColor(
                                      item.CommodityName
                                    ),
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.CommodityName) + " ")]
                              ),
                            ]
                          },
                        },
                        {
                          key: "header.ContainerTypeName",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { "align-items": "center" } },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a container type",
                                              type: "text",
                                            },
                                            model: {
                                              value:
                                                _vm.bookingContainerTypeSearch,
                                              callback: function ($$v) {
                                                _vm.bookingContainerTypeSearch =
                                                  $$v
                                              },
                                              expression:
                                                "bookingContainerTypeSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.bookingContainerTypeSearch =
                                                    ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.booking`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-decoration-underline",
                                  attrs: {
                                    to: "/outbound/edit/" + item.booking,
                                  },
                                },
                                [_vm._v(_vm._s(item.booking))]
                              ),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "close-on-content-click": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-filter ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "background-color": "white",
                                        width: "280px",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "pa-4",
                                        attrs: {
                                          label: "Enter a booking number",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.bookingSearch,
                                          callback: function ($$v) {
                                            _vm.bookingSearch = $$v
                                          },
                                          expression: "bookingSearch",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2 mb-2",
                                          attrs: { small: "", text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.bookingSearch = ""
                                            },
                                          },
                                        },
                                        [_vm._v("Clean")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.BookingQuantity`,
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.BookingQuantity || 0) +
                                    " / " +
                                    _vm._s(item.ContainersLoaded || 0) +
                                    " / " +
                                    _vm._s(item.ContainersAssigned || 0) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.PrioritizeFlag`,
                          fn: function ({ item }) {
                            return [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.PrioritizeFlag ? "mdi-flag" : ""
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.EarliestReleaseDate`,
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.EarliestReleaseDate.split("T")[0])
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.CutDate`,
                          fn: function ({ item }) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(item.CutDate.split("T")[0])),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.upRelease`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-inline-flex justify-content: space-between",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        icon: "mdi-plus",
                                        size: "x-small",
                                        loading: _vm.adhocRequestLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.requestAdditonalContainers(
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-plus-circle "),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text--primary",
                                      attrs: {
                                        color: "secondary",
                                        loading: _vm.g4Loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.ReleaseToG4(item)
                                        },
                                      },
                                    },
                                    [_vm._v("G4")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function ({ headers, item }) {
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length / 2 } },
                                [
                                  _c("v-data-table", {
                                    staticClass:
                                      "elevation-1 bookingContainerTable",
                                    attrs: {
                                      headers: _vm.bookingContainerHeader,
                                      items: _vm.BookingContainers(item),
                                      "footer-props": {
                                        "items-per-page-options": [
                                          10, 20, 30, 40, 50, -1,
                                        ],
                                      },
                                      "items-per-page": 20,
                                      loading: _vm.containerBookingsLoading,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "header.ContainerNumber",
                                          fn: function ({ header }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex",
                                                  staticStyle: {
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(header.text) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "offset-y": "",
                                                        "close-on-content-click": false,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                              attrs,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-filter "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "background-color":
                                                              "white",
                                                            width: "280px",
                                                          },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass: "pa-4",
                                                            attrs: {
                                                              label:
                                                                "Enter a container number",
                                                              type: "text",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.bookingContainerNumberSearch,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.bookingContainerNumberSearch =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "bookingContainerNumberSearch",
                                                            },
                                                          }),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "ml-2 mb-2",
                                                              attrs: {
                                                                small: "",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.bookingContainerNumberSearch =
                                                                      ""
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Clear")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.LoadDate`,
                                          fn: function ({ item }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.LoadDate
                                                      ? item.LoadDate.split(
                                                          "T"
                                                        )[0]
                                                      : "No Date Available"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: `item.actions`,
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                  },
                                                  attrs: {
                                                    icon: "mdi-delete",
                                                    size: "small",
                                                    loading:
                                                      _vm.workorderLoading,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.RemoveContainer(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: [
                                                            "Assigned",
                                                            "PendingTransLoad",
                                                            "PendingSale",
                                                            "PendingLoad",
                                                            "Modified",
                                                            "PendingScale",
                                                          ].includes(
                                                            item.BookingContainerEventStatusName
                                                          ),
                                                          expression:
                                                            "\n                        [\n                          'Assigned',\n                          'PendingTransLoad',\n                          'PendingSale',\n                          'PendingLoad',\n                          'Modified',\n                          'PendingScale',\n                        ].includes(item.BookingContainerEventStatusName)\n                      ",
                                                        },
                                                      ],
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v(" mdi-delete ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _c(
                                    "h4",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.DroppedBookingContainers(item)
                                              .length > 0,
                                          expression:
                                            "DroppedBookingContainers(item).length > 0",
                                        },
                                      ],
                                    },
                                    [_vm._v(" Containers To Be Added ")]
                                  ),
                                  _vm.DroppedBookingContainers(item).length > 0
                                    ? _c("v-data-table", {
                                        staticClass:
                                          "elevation-1 bookingContainerTable",
                                        attrs: {
                                          headers: _vm.droppedContainerHeader,
                                          items:
                                            _vm.DroppedBookingContainers(item),
                                          "items-per-page": 5,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: `item.actions`,
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.RemoveDroppedContainer(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" mdi-delete ")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { attrs: { colspan: headers.length / 2 } },
                                [
                                  _c("div", { staticClass: "drop-container" }, [
                                    _c("h4", [
                                      _vm._v("Drag Containers Here to Assign"),
                                    ]),
                                    _c("div", {
                                      staticClass: "drop-zone",
                                      style: { backgroundColor: _vm.dragColor },
                                      on: {
                                        drop: function ($event) {
                                          return _vm.OnDrop($event, item)
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                        },
                                        dragenter: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c(
                    "v-row",
                    [
                      _vm.droppedContainers.length > 0
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "button text--primary mb-5",
                              attrs: {
                                color: "secondary",
                                id: "submit-containers",
                                loading: _vm.submitContainers,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.SubmitContainers()
                                },
                              },
                            },
                            [_vm._v(" Submit Containers ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", xs: "12", sm: "12", md: "4", lg: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "containers" },
                [
                  _c("h3", { staticClass: "pl-2 pt-2" }, [
                    _vm._v("Containers"),
                  ]),
                  _c("v-data-table", {
                    staticClass: "elevation-1 table",
                    attrs: {
                      headers: _vm.containerHeaders,
                      items: _vm.AvailableContainers(),
                      "item-key": "name",
                      loading: _vm.loadingContainers,
                      "footer-props": {
                        "items-per-page-options": [10, 20, 30, 40, 50, -1],
                      },
                      "items-per-page": 20,
                      search: _vm.search,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header.ContainerNumber",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a container number",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.containerNumberSearch,
                                              callback: function ($$v) {
                                                _vm.containerNumberSearch = $$v
                                              },
                                              expression:
                                                "containerNumberSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.containerNumberSearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "header.ShipperName",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a shipper",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.shipperSearch,
                                              callback: function ($$v) {
                                                _vm.shipperSearch = $$v
                                              },
                                              expression: "shipperSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.shipperSearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "header.ContainerTypeName",
                          fn: function ({ header }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _vm._v(" " + _vm._s(header.text) + " "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v(" mdi-filter ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "white",
                                            width: "280px",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pa-4",
                                            attrs: {
                                              label: "Enter a container type",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.containerTypeSearch,
                                              callback: function ($$v) {
                                                _vm.containerTypeSearch = $$v
                                              },
                                              expression: "containerTypeSearch",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-2 mb-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.containerTypeSearch = ""
                                                },
                                              },
                                            },
                                            [_vm._v("Clear")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.ContainerNumber`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  key: item.container,
                                  staticClass: "drag-el",
                                  staticStyle: { width: "130px" },
                                  attrs: { draggable: "" },
                                  on: {
                                    dragstart: function ($event) {
                                      return _vm.StartDrag($event, item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(" mdi-drag "),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(item.ContainerNumber) + " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.containerType`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.ContainerTypeName) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.steamShipLine`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(item.ShipperDescription) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: {
                                    icon: "mdi-delete",
                                    size: "small",
                                    loading: _vm.reservationLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DeleteContainer(item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(" mdi-delete "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }