var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", border: "left", type: "error" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" Error editing user. ")]
      ),
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", border: "left", type: "success" },
          model: {
            value: _vm.isSuccess,
            callback: function ($$v) {
              _vm.isSuccess = $$v
            },
            expression: "isSuccess",
          },
        },
        [_vm._v(" Saved Successfully. ")]
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.isLoading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "green", size: 70, width: 7 },
          }),
        ],
        1
      ),
      _c("UserDetails", {
        attrs: { user: _vm.user, loading: _vm.isLoading },
        on: { eventname: _vm.UpdateUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }