var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "text-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12 mt-4 pb-2" } },
            [
              _c("v-img", {
                staticClass: "my-3 pb-2",
                attrs: {
                  src: require("../assets/img_e_brand_up_color_png.png"),
                  contain: "",
                  height: "150",
                },
              }),
            ],
            1
          ),
          _c("v-col", { staticClass: "mb-4" }, [
            _c("div", { staticClass: "text-h6 font-weight-bold mb-0" }, [
              _vm._v(" Welcome to Global 4 Transload "),
            ]),
            _c("div", { staticClass: "text-h6 font-weight-bold mb-3" }, [
              _vm._v("Powered By JCT"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }