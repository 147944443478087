<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-tabs v-model="tab" align-with-title class="mt-5">
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab
        v-for="item in tabs"
        :key="item"
        class="tabs"
        active-class="active-tab"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="mt-2">
      <v-tab-item key="Details">
        <v-row class="ml-3">
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-autocomplete
              :disabled="!this.isUserAllowed(['Admin', 'Master'])"
              label="Invoicing Status"
              :items="InvoicingStatuses"
              item-text="text"
              item-value="value"
              v-model="InvoicingStatusId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-autocomplete
              :disabled="!this.isUserAllowed(['Admin', 'Master'])"
              label="Documentation Status"
              :items="DocumentationStatuses"
              item-text="text"
              item-value="value"
              v-model="DocumentationStatusId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-autocomplete
              :disabled="!this.isUserAllowed(['Admin', 'Master'])"
              label="Payment Status"
              :items="PaymentStatuses"
              item-text="text"
              item-value="value"
              v-model="PaymentStatusId"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4" lg="4" xl="4"> </v-col>
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-btn
              v-if="isUserAllowed(['Admin', 'Master'])"
              @click="UpdateBookingStatuses()"
              :loading="loading"
              color="secondary"
              class="mt-2 mr-2 ml-2 submit-button text--primary"
            >
              Update statuses
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ml-3">
          <v-col
            v-if="
              isUserAllowed(['Admin', 'Master']) && this.showGrades === false
            "
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-data-table
              :headers="averageGradeHeaders"
              :items="averageGrades"
              :loading="loading"
              hide-default-footer
              dense
            >
              <template v-slot:item.Avg_Factor_TW="{ item }">
                <v-chip
                  :color="getGradeColorTW(item.Avg_Factor_TW)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_TW }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_M="{ item }">
                <v-chip
                  :color="getGradeColorM(item.Avg_Factor_M)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_M }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_HT="{ item }">
                <v-chip
                  :color="getGradeColorHT(item.Avg_Factor_HT)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_HT }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_DKT="{ item }">
                <v-chip
                  :color="getGradeColorDKT(item.Avg_Factor_DKT)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_DKT }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_FM="{ item }">
                <v-chip
                  :color="getGradeColorFM(item.Avg_Factor_FM)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_FM }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_SBOC="{ item }">
                <v-chip
                  :color="getGradeColorSBOC(item.Avg_Factor_SBOC)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_SBOC }}
                </v-chip>
              </template>
              <template v-slot:item.Avg_Factor_SPLITS="{ item }">
                <v-chip
                  :color="getGradeColorSPLITS(item.Avg_Factor_SPLITS)"
                  dark
                  label
                  style="margin: 4px"
                >
                  {{ item.Avg_Factor_SPLITS }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-else cols="12" md="6" lg="6" xl="6"> </v-col>
          <v-col
            v-if="
              isUserAllowed(['Admin', 'Master']) && this.showGrades === false
            "
            cols="12"
            md="2"
            lg="2"
            xl="2"
          >
            <v-btn
              @click="CalculateAverageGrades()"
              :loading="loading"
              color="secondary"
              class="mt-2 mr-2 ml-2 submit-button text--primary"
            >
              Calc Avg Grades
            </v-btn>
          </v-col>
          <v-col v-else cols="12" md="2" lg="2" xl="2"></v-col>
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-btn
              v-show="isUserAllowed(['Admin', 'Master'])"
              @click="UpdatePackingListStatus()"
              :loading="loading"
              color="secondary"
              class="mt-2 mr-2 ml-2 submit-button text--primary"
            >
              Create Packing List
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" lg="2" xl="2">
            <v-btn
              v-if="isUserAllowed(['Admin', 'Master'])"
              @click="UpdateRailBillStatus()"
              :loading="loading"
              color="secondary"
              class="mt-2 mr-2 ml-2 submit-button text--primary"
            >
              Mark as Rail Billed
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="bookingNumber"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="30"
          class="elevation-1 table mb-5"
          show-expand
          :expanded.sync="expanded"
          hide-default-footer
        >
          <template
            v-slot:expanded-item="{ headers, item }"
            style="shadow-box: none"
          >
            <td :colspan="headers.length">
              <v-data-table
                v-if="isUserAllowed(['Admin', 'Master'])"
                :headers="containerHeaders"
                :items="item.containers"
                item-key="BookingContainerId"
                :loading="loading"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="30"
                class="elevation-1 table"
                show-select
                v-model="selectedContainers"
              >
                <template v-slot:item.ContainerNumber="{ item }">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ item.ContainerNumber }}
                      </div>
                    </template>
                    <span>
                      Booking Container Id: {{ item.BookingContainerId }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-slot:item.SealNumber="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.SealNumber"
                    v-if="item.SealNumber !== null"
                    large
                    persistent
                    @save="UpdateContainer(item)"
                  >
                    <div>{{ item.SealNumber }}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Seal Number</div>
                      <v-text-field
                        v-model="item.SealNumber"
                        label="Edit"
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.NetWeight="{ item }">
                  <span>{{ item.Weight - item.TareWeight }}</span>
                </template>
                <template v-slot:item.review="{ item }">
                  <v-btn icon v-if="isUserAllowed(['Admin', 'Master'])">
                    <v-icon
                      color="black"
                      v-if="
                        item.BookingContainerEventStatusName ===
                        'PendingRailBilling'
                      "
                      @click="UpdateContainerStatus(item)"
                      >mdi-alert-circle</v-icon
                    >
                  </v-btn>
                </template>
              </v-data-table>
              <v-data-table
                v-else
                :headers="containerHeaders"
                :items="item.containers"
                item-key="ContainerNumber"
                :loading="loading"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="30"
                class="elevation-1 table"
                v-model="selectedContainers"
              >
                <template v-slot:item.PackingListStatusId="{ item }">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.PackingListStatusId === 152"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-clipboard-text-clock-outline</v-icon
                      >
                    </template>
                    <span>Pending Packing List</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.PackingListStatusId === 154"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-clipboard-text-clock-outline</v-icon
                      >
                    </template>
                    <span>Processing Packing List</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.PackingListStatusId === 156"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-clipboard-check-outline</v-icon
                      >
                    </template>
                    <span>Packing List Created</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.RailBillingStatusId="{ item }">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="item.RailBillingStatusId === 164"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-currency-usd</v-icon
                      >
                    </template>
                    <span>Rail Billed</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.SealNumber="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.SealNumber"
                    large
                    persistent
                  >
                    <div>{{ item.SealNumber }}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">Update Seal Number</div>
                      <v-text-field
                        v-model="item.SealNumber"
                        label="Edit"
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.NetWeight="{ item }">
                  <span>{{ item.Weight - item.TareWeight }}</span>
                </template>
                <template v-slot:item.review="{ item }">
                  <v-btn icon v-if="isUserAllowed(['Admin', 'Master'])">
                    <v-icon
                      color="black"
                      v-if="
                        item.BookingContainerEventStatusName ===
                        'PendingRailBilling'
                      "
                      @click="UpdateContainerStatus(item)"
                      >mdi-alert-circle</v-icon
                    >
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item key="Document">
        <v-data-table
          :headers="containerFileHeaders"
          :items="documents"
          item-key="FileName"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
          :items-per-page="30"
          class="elevation-1 table"
          v-model="selected"
          show-select
        >
          <template v-slot:header.FileType="{ header }">
            <div style="align-items: center">
              {{ header.text }}
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon small> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <div style="background-color: white; width: 280px">
                  <v-text-field
                    v-model="bookingFileTypeSearch"
                    label="Enter a file type"
                    class="pa-4"
                    type="text"
                  ></v-text-field>
                  <v-btn
                    @click="bookingFileTypeSearch = ''"
                    small
                    text
                    class="ml-2 mb-2"
                    >Clear</v-btn
                  >
                </div>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.FileName="{ item }">
            <v-btn
              :link="true"
              @click="openLink(item.PreSignedUrl)"
              target="_blank"
            >
              <v-icon class="mr-2">mdi-file</v-icon> {{ item.FileName }}
            </v-btn>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              @input="select($event)"
              :value="isSelected"
              :ripple="false"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" md="10" lg="10" xl="10"> </v-col>
          <v-col cols="12" md="2" lg="2" xl="2" class="mt-2 mb-5">
            <v-btn
              @click="DownloadSelectedFiles()"
              :loading="loading"
              :disabled="!selected.length"
              color="secondary"
              class="mt-2 mr-2 ml-2 submit-button text--primary"
            >
              Download Selected
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
const axios = require("axios");
export default {
  name: "Billing",

  components: {},
  data() {
    return {
      expanded: [],
      RailBillStatuses: [],
      InvoicingStatuses: [],
      DocumentationStatuses: [],
      PaymentStatuses: [],
      PackingListStatuses: [],
      data: [],
      bookingFileTypeSearch: "",
      InvoicingStatusId: 0,
      DocumentationStatusId: 0,
      PartyId: 0,
      PaymentStatusId: 0,
      CommodityName: "None",
      isError: false,
      statusError: false,
      loading: true,
      emailAddress: "",
      alertMessage: "",
      documents: [],
      selected: [],
      averageGrades: [
        {
          Avg_Factor_TW: 0,
          Avg_Factor_M: 0,
          Avg_Factor_HT: 0,
          Avg_Factor_DKT: 0,
          Avg_Factor_FM: 0,
          Avg_Factor_SBOC: 0,
          Avg_Factor_SPLITS: 0,
          Avg_Factor_GRADE: 0,
        },
      ],
      selectedContainers: [],
      selectedBookingContainerIds: [],
      showGrades: false,
      tab: "Details",
      tabs: ["Details", "Documents"],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Customer", value: "PartyAccountName" },
        { text: "Booking", align: "start", value: "bookingNumber" },
        { text: "Commodity", value: "CommodityName" },
        { text: "Container Type", value: "ContainerTypeName" },
        { text: "SSL", value: "ShipperName" },
        { text: "ERD", value: "earliestReleaseDate" },
        { text: "Cut", value: "cutDate" },
        { text: "Destination", value: "DestinationName" },
        { text: "Quantity", value: "Quantity" },
        { text: "Contact", value: "user" },
        { text: "Comments", value: "comments" },
        { text: "", value: "data-table-expand" },
      ];
    },
    containerHeaders() {
      return [
        { text: "Container #", value: "ContainerNumber" },
        { text: "Status", value: "BookingContainerEventStatusName" },
        { text: "Packing List", value: "PackingListDate" },
        { text: "Rail Billing", value: "RailBillingDate" },
        { text: "Seal #", value: "SealNumber" },
        { text: "Ticket #", value: "TicketNumber" },
        { text: "Weight", value: "Weight" },
        { text: "Tare Weight", value: "TareWeight" },
        { text: "Net Weight", value: "NetWeight" },
        { text: "Load Date", value: "LoadDate" },
        { text: "TW", value: "Factor_TW" },
        { text: "M", value: "Factor_M" },
        { text: "HT", value: "Factor_HT" },
        { text: "DKT", value: "Factor_DKT" },
        { text: "FM", value: "Factor_FM" },
        { text: "SBOC", value: "Factor_SBOC" },
        { text: "Splits", value: "Factor_SPLITS" },
        { text: "Grade", value: "Factor_GRADE" },
        { text: "", value: "review" },
      ];
    },
    containerFileHeaders() {
      return [
        {
          text: "File Type",
          value: "FileType",
          filter: (value) => {
            if (
              !this.bookingFileTypeSearch ||
              this.bookingFileTypeSearch === ""
            )
              return true;
            return (
              value != null &&
              this.bookingFileTypeSearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(
                  this.bookingFileTypeSearch.toString().toLocaleUpperCase()
                ) !== -1
            );
          },
        },
        { text: "Container #", align: "start", value: "ContainerNumber" },
        { text: "File", value: "FileName" },
        { text: "", value: "checkbox" },
      ];
    },
    averageGradeHeaders() {
      return [
        { text: "Avg TW", value: "Avg_Factor_TW", sortable: false },
        { text: "Avg M", value: "Avg_Factor_M", sortable: false },
        { text: "Avg HT", value: "Avg_Factor_HT", sortable: false },
        { text: "Avg DKT", value: "Avg_Factor_DKT", sortable: false },
        { text: "Avg FM", value: "Avg_Factor_FM", sortable: false },
        { text: "Avg SBOC", value: "Avg_Factor_SBOC", sortable: false },
        { text: "Avg Splits", value: "Avg_Factor_SPLITS", sortable: false },
      ];
    },
  },
  mounted() {
    if (this.$route.params && this.$route.params.loadId) {
      this.loading = true;
      this.GetBookingDetails(this.$route.params.loadId);
      this.GetBillingStatus();
    }
  },
  methods: {
    isUserAllowed,
    openLink(url) {
      window.open(url);
    },
    async DownloadSelectedFiles() {
      try {
        this.loading = true;
        await Promise.all(
          this.selected.map((containerDocument) => {
            return axios({
              url: containerDocument.PreSignedUrl,
              method: "GET",
              responseType: "blob", // important!
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              let fileName = "";
              if (
                containerDocument.ContainerNumber &&
                containerDocument.ContainerNumber !== ""
              ) {
                fileName = `${containerDocument.ContainerNumber}_${containerDocument.FileName}`;
              } else {
                fileName = containerDocument.FileName;
              }
              link.setAttribute("download", fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
            });
          })
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async GetBillingStatus() {
      try {
        //console.log(this.data);
        let statuses = await api.get("/load/billingstatus");
        this.RailBillStatuses = statuses.BookingRailBill.map((status) => {
          return { text: status.Name, value: status.StatusId };
        });
        this.InvoicingStatuses = statuses.BookingInvoicing.map((status) => {
          return { text: status.Name, value: status.StatusId };
        });
        this.DocumentationStatuses = statuses.BookingDocumentation.map(
          (status) => {
            return { text: status.Name, value: status.StatusId };
          }
        );
        this.PaymentStatuses = statuses.BookingPayment.map((status) => {
          return { text: status.Name, value: status.StatusId };
        });
        this.PackingListStatuses = statuses.BookingPackingList.map((status) => {
          return { text: status.Name, value: status.StatusId };
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async CompleteBilling() {
      try {
        await api.post("/load/completebilling", {
          loadId: this.$route.params.loadId,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to complete billing. Please try again.";
        console.log(err);
      }
    },
    async GetBookingDetails(loadId) {
      this.showGrades = false;
      try {
        this.data = [];
        this.data.push(await api.get("/load/bookingdetails/" + loadId));
        if (this.data.length) {
          this.CommodityName = this.data[0].CommodityName;
          if (this.CommodityName.toLowerCase() === "ddgs") {
            this.showGrades = true;
          }
          this.InvoicingStatusId = this.data[0].InvoicingStatusId;
          this.DocumentationStatusId = this.data[0].DocumentationStatusId;
          this.PaymentStatusId = this.data[0].PaymentStatusId;
          this.PartyId = this.data[0].partyAccountId;
          this.documents = this.data[0].AllBookingDocuments.filter(
            (document) => {
              return (
                document.FileType !== "Inspection921" ||
                this.PaymentStatusId === 116 ||
                this.PartyId < 4 ||
                this.isUserAllowed(["Admin", "Master"])
              );
            }
          );
        }
        this.expanded.push(this.data[0]);
        console.table(this.data);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async UpdateBookingStatuses() {
      this.loading = true;
      try {
        this.data[0].InvoicingStatusId = this.InvoicingStatusId;
        this.data[0].DocumentationStatusId = this.DocumentationStatusId;
        this.data[0].PaymentStatusId = this.PaymentStatusId;
        await api.put("/load/updatebookingstatuses", this.data[0]);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to create load. Please try again.";
        console.log(err);
      }
    },
    async UpdatePackingListStatus() {
      this.loading = true;
      if (this.selectedContainers.length < 1) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Select one or more containers to generate a packing list.";
      } else {
        await this.GetSelectedContainerIds();
        if (this.statusError === true) {
          this.loading = false;
          this.isError = true;
          this.alertMessage =
            "One or more selected containers is not in the correct status to generate a packing list.";
          this.statusError = false;
        } else {
          try {
            await api.put(
              "/load/updatepackingliststatus",
              this.selectedBookingContainerIds
            );
            await this.GetBookingDetails(this.$route.params.loadId);
            this.loading = false;
          } catch (err) {
            this.loading = false;
            this.isError = true;
            this.alertMessage =
              "Failed to update packing list status. Please try again.";
            console.log(err);
          }
        }
      }
    },
    async UpdateRailBillStatus() {
      this.loading = true;
      if (this.selectedContainers.length < 1) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Select one or more containers to mark rail billed.";
      } else {
        await this.GetSelectedContainerIds();
        if (this.statusError === true) {
          this.loading = false;
          this.isError = true;
          this.alertMessage =
            "One or more selected containers is not in the correct status to mark rail billed.";
          this.statusError = false;
        } else {
          try {
            await api.put(
              "/load/updaterailbillstatus",
              this.selectedBookingContainerIds
            );
            await this.GetBookingDetails(this.$route.params.loadId);
            this.loading = false;
          } catch (err) {
            this.loading = false;
            this.isError = true;
            this.alertMessage =
              "Failed to update rail bill status. Please try again.";
            console.log(err);
          }
        }
      }
    },
    async GetSelectedContainerIds() {
      this.selectedBookingContainerIds = [];
      this.selectedContainers.forEach((o) => {
        if (
          o.BookingContainerEventStatusId !== 34 &&
          o.BookingContainerEventStatusId !== 35 &&
          o.BookingContainerEventStatusId !== 36 &&
          o.BookingContainerEventStatusId !== 37 &&
          o.BookingContainerEventStatusId !== 39 &&
          o.BookingContainerEventStatusId !== 43 &&
          o.BookingContainerEventStatusId !== 44
        ) {
          this.statusError = true;
        }
        this.selectedBookingContainerIds.push(o.BookingContainerId);
      });
    },
    async GenerateBillingEmail() {
      try {
        await api.post("/load/email", {
          email: this.emailAddress,
          loadId: this.$route.params.loadId,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to generate email. Please try again.";
        console.log(err);
      }
      this.emailAddress = "";
    },
    async UpdateContainer(container) {
      console.log(container);
      try {
        this.loading = true;
        await api.put("/load/updatecontainer", container);
        this.isSuccess = true;
        this.successMessage = "Updated Container!";
        await this.GetBookingDetails(this.$route.params.loadId);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to update container. Please try again.";
        console.log(err);
      }
    },
    async UpdateContainerStatus(container) {
      try {
        this.loading = true;
        await api.put(
          "/load/completecontainer/" + container.BookingContainerId,
          container
        );
        await this.GetBookingDetails(this.$route.params.loadId);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to generate email. Please try again.";
        console.log(err);
      }
    },
    async CalculateAverageGrades() {
      this.averageGrades[0].Avg_Factor_TW = 0;
      this.averageGrades[0].Avg_Factor_M = 0;
      this.averageGrades[0].Avg_Factor_HT = 0;
      this.averageGrades[0].Avg_Factor_DKT = 0;
      this.averageGrades[0].Avg_Factor_FM = 0;
      this.averageGrades[0].Avg_Factor_SBOC = 0;
      this.averageGrades[0].Avg_Factor_SPLITS = 0;

      if (this.selectedContainers.length > 0) {
        this.selectedContainers.forEach((o) => {
          console.log(o);
          if (o.Factor_TW !== null) {
            this.averageGrades[0].Avg_Factor_TW += parseFloat(o.Factor_TW);
          }
          if (o.Factor_M !== null) {
            this.averageGrades[0].Avg_Factor_M += parseFloat(o.Factor_M);
          }
          if (o.Factor_HT !== null) {
            this.averageGrades[0].Avg_Factor_HT += parseFloat(o.Factor_HT);
          }
          if (o.Factor_DKT !== null) {
            this.averageGrades[0].Avg_Factor_DKT += parseFloat(o.Factor_DKT);
          }
          if (o.Factor_FM !== null) {
            this.averageGrades[0].Avg_Factor_FM += parseFloat(o.Factor_FM);
          }
          if (o.Factor_SBOC !== null) {
            this.averageGrades[0].Avg_Factor_SBOC += parseFloat(o.Factor_SBOC);
          }
          if (o.Factor_SPLITS !== null) {
            this.averageGrades[0].Avg_Factor_SPLITS += parseFloat(
              o.Factor_SPLITS
            );
          }
        });
        this.averageGrades[0].Avg_Factor_TW = parseFloat(
          (this.averageGrades[0].Avg_Factor_TW /=
            this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_M = parseFloat(
          (this.averageGrades[0].Avg_Factor_M /= this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_HT = parseFloat(
          (this.averageGrades[0].Avg_Factor_HT /=
            this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_DKT = parseFloat(
          (this.averageGrades[0].Avg_Factor_DKT /=
            this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_FM = parseFloat(
          (this.averageGrades[0].Avg_Factor_FM /=
            this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_SBOC = parseFloat(
          (this.averageGrades[0].Avg_Factor_SBOC /=
            this.selectedContainers.length)
        ).toFixed(1);
        this.averageGrades[0].Avg_Factor_SPLITS = parseFloat(
          (this.averageGrades[0].Avg_Factor_SPLITS /=
            this.selectedContainers.length)
        ).toFixed(1);
      }
    },
    getGradeColorTW(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade < 56) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade < 54) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorM(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 13.5) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 13.5) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorHT(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 0.2) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 0.5) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorDKT(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 2) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 3) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorFM(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 1) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 2) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorSBOC(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 1) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 2) {
          return "red";
        } else {
          return "black";
        }
      }
    },
    getGradeColorSPLITS(grade) {
      if (this.CommodityName.toLowerCase() === "soybeans1") {
        if (grade > 10) {
          return "red";
        } else {
          return "black";
        }
      } else if (this.CommodityName.toLowerCase() === "soybeans2") {
        if (grade > 20) {
          return "red";
        } else {
          return "black";
        }
      }
    },
  },
};
</script>

<style>
.search-bar {
  padding-top: 15px;
}

.table {
  padding-left: 15px;
  padding-right: 15px;
}

.v-data-table__expanded__content {
  box-shadow: none !important;
}

.active-tab {
  background-color: #ffcc00 !important;
  border-radius: 15px 15px 0px 0px;
}

.tabs {
  background-color: #e2e5de;
  border-radius: 15px 15px 0px 0px;
}
</style>
