<template>
  <v-card
    class="d-flex justify-center mb-12 login-card"
    flat
    tile
    height="100%"
  >
    <v-card tile :class="`align-self-center elevation-0`">
      <v-form
        ref="form"
        @submit.prevent="validate"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <img
                src="../../assets/img_e_brand_up_color_png.png"
                class="text-center mt-4"
                contain
                height="100"
              />
              <div>Powered By JCT</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" v-if="!emailSent">
              <h2>Forgot Password?</h2>
              <p>Enter the email associated with your G4 Transload account.</p>
            </v-col>
            <v-col cols="12" md="12" v-if="emailSent">
              <h2>Reset Password</h2>
              <p>
                Enter a new password below to use in G4 Transload. Please use
                the verification code sent to your E-mail.
              </p>
            </v-col>
            <v-alert
              dismissible
              border="left"
              type="error"
              v-model="isError"
              class="message"
            >
              {{ errorMessage }}
            </v-alert>
            <v-alert
              dismissible
              border="left"
              type="success"
              v-model="isSuccess"
              class="message"
            >
              {{ successMessage }}
            </v-alert>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="emailSent">
              <v-text-field
                v-model="userPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  passwordRules.required,
                  passwordRules.min,
                  passwordRules.digit,
                  passwordRules.capital,
                  passwordRules.special,
                ]"
                validate-on-blur
                :type="show ? 'text' : 'password'"
                label="New Password"
                required
                outlined
                class="mb-6"
                @click:append="show = !show"
              ></v-text-field>
              <div style="margin-top: -25px; margin-bottom: 25px">
                <p style="font-style: italic; font-size: 13px">
                  Password should contain at least one uppercase letter, one
                  lower case letter, a digit, and a symbol.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="12" v-if="emailSent">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm New Password"
                :rules="[passwordRules.required]"
                required
                outlined
                clearable
                validate-on-blur
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :error-messages="passwordMatch()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="emailSent">
              <v-text-field
                v-model="code"
                :rules="[codeRules.required, codeRules.min, codeRules.digit]"
                label="Validation Code Found in E-mail"
                required
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!emailSent">
              <v-btn
                class="submit white--text"
                color="info"
                @click="haveVerificationCode"
              >
                I already have the verification code
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="!emailSent">
              <v-btn class="submit white--text" color="primary" @click="cancel">
                Back to Login
              </v-btn>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                v-if="!emailSent"
                color="secondary"
                class="submit text--primary"
                type="submit"
                :disabled="!valid"
              >
                Send Validation Email
              </v-btn>
              <v-btn
                v-if="emailSent"
                color="secondary"
                class="submit text--primary"
                type="submit"
                :disabled="!valid"
              >
                Reset Password
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data: () => ({
    valid: true,
    email: "",
    emailSent: false,
    code: "",
    isError: false,
    errorMessage: "",
    isSuccess: false,
    successMessage: "",
    userPassword: "",
    confirmPassword: "",
    show: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(v) ||
        "E-mail must be valid",
    ],
    passwordRules: {
      required: (v) => !!v || "Enter a password",
      min: (v) => v.length >= 8 || "Use 8 characters or more for your password",
      digit: (v) => (v && /\d/.test(v)) || "Please at least one digit",
      capital: (v) =>
        (v && /[A-Z]{1}/.test(v)) || "Please use at least one capital letter",
      special: (v) =>
        (v && /[^A-Za-z0-9]/.test(v)) ||
        "Please use at least one special character",
    },
    codeRules: {
      required: (value) =>
        !!value ||
        "Enter the validation code. It is found in the forgot password e-mail.",
      min: (v) => v.length > 5 || "Verification code must be 6 numbers long.",
      max: (v) => v.length < 6 || "Verification code must be 6 numbers long.",
      digit: (v) =>
        (v && /\d{6}/.test(v)) || "Verification code must be numeric",
    },
  }),
  mounted() {
    if (this.$route.query && this.$route.query.emailSent === "true") {
      this.emailSent = true;
    }
  },
  methods: {
    async validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.emailSent) {
          console.log("Submitting Password");
          try {
            await this.passwordSubmit();
          } catch (err) {
            console.log(err);
            this.isError = true;
            this.errorMessage = "";
          }
        } else {
          try {
            console.log("Forgot Password");
            await this.forgotPassword();
          } catch (err) {
            this.isError = true;
            this.errorMessage = "Please enter valid email";
          }
        }
      }
    },
    async forgotPassword() {
      await Auth.forgotPassword(this.email)
        .then((data) => {
          console.log(data);
          this.emailSent = true;
          this.isSuccess = true;
          this.successMessage =
            "Email sent. Please select a new password and input the validation code sent to your inbox";
        })
        .catch((err) => {
          console.log(err);
          this.isError = true;
          this.errorMessage = `Could not send email. ${err.message}`;
        });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async passwordSubmit() {
      await Auth.forgotPasswordSubmit(
        this.email,
        this.code,
        this.confirmPassword
      )
        .then((data) => {
          console.log(data);
          this.$emit(
            "forgotreset",
            "Password reset. Please log in with new password."
          );
        })
        .catch((err) => {
          console.log(err);
          this.isError = true;
          this.errorMessage = `Could not send email. ${err.message}`;
        });
    },
    cancel() {
      this.$emit("back");
    },
    passwordMatch() {
      return this.userPassword === this.confirmPassword
        ? ""
        : "Password must match";
    },
    haveVerificationCode() {
      this.emailSent = true;
    },
  },
};
</script>
<style scoped>
.submit {
  width: 100%;
  height: 40px !important;
  color: #fff;
}
.message {
  width: 100%;
}
.login-body,
.login-card,
.v-application--wrap {
  border-radius: 50px;
}
.v-application .mb-6 {
  margin-bottom: 0px !important;
}
</style>
