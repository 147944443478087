var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "First Name",
                  required: "",
                  rules: _vm.firstNameRules,
                  outlined: "",
                  clearable: "",
                },
                model: {
                  value: _vm.user.first_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "first_name", $$v)
                  },
                  expression: "user.first_name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Last Name",
                  required: "",
                  rules: _vm.lastNameRules,
                  outlined: "",
                  clearable: "",
                },
                model: {
                  value: _vm.user.last_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "last_name", $$v)
                  },
                  expression: "user.last_name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Email",
                  required: "",
                  rules: _vm.emailRules,
                  outlined: "",
                  clearable: "",
                },
                model: {
                  value: _vm.user.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("v-text-field-simplemask", {
                attrs: {
                  label: _vm.phoneNumberLabel,
                  properties: {
                    outlined: true,
                    clearable: true,
                    rules: [
                      (v) =>
                        _vm.user.temp_number &&
                        (!!v || "Phone number is required"),
                      (v) =>
                        _vm.user.temp_number &&
                        (v.length === 14 || "Invalid Phone Number"),
                    ],
                  },
                  options: {
                    inputMask: "(###) ###-####",
                    outputMask: "##########",
                  },
                  required: "",
                },
                model: {
                  value: _vm.user.temp_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "temp_number", $$v)
                  },
                  expression: "user.temp_number",
                },
              }),
            ],
            1
          ),
          !_vm.user.self_register
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.roles,
                      rules: _vm.groupRules,
                      label: "User Type",
                      outlined: "",
                    },
                    model: {
                      value: _vm.user.role_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "role_id", $$v)
                      },
                      expression: "user.role_id",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.group === "Party"
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "12" } },
                [
                  _c("v-select", {
                    attrs: { items: _vm.parties, label: "Party", outlined: "" },
                    model: {
                      value: _vm.user.party_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "party_id", $$v)
                      },
                      expression: "user.party_id",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "text--primary ml-2 mr-2 mb-2",
              attrs: {
                disabled: !_vm.valid,
                loading: _vm.loading || _vm.loadingParties || _vm.loadingRoles,
                color: "secondary",
              },
              on: { click: _vm.Save },
            },
            [_vm._v(_vm._s(this.user.self_register ? "Register" : "Save User"))]
          ),
          _c(
            "v-btn",
            { staticClass: "text--primary mb-2", on: { click: _vm.Cancel } },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }