var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _vm.isUserAllowed(["Admin", "Master", "Party"])
        ? _c("v-data-table", {
            staticClass: "elevation-1 table mt-5",
            attrs: {
              headers: _vm.headers,
              items: _vm.tickets,
              "item-key": "FileName",
              loading: _vm.loading,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "footer-props": {
                "items-per-page-options": [10, 20, 30, 40, 50, -1],
              },
              "items-per-page": 30,
              "show-select": "",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.data-table-select",
                  fn: function ({ item, isSelected, select }) {
                    return [
                      _c("v-simple-checkbox", {
                        attrs: { value: isSelected, ripple: false },
                        on: {
                          input: function ($event) {
                            return select($event)
                          },
                        },
                      }),
                    ]
                  },
                },
                _vm.isUserAllowed(["Admin"])
                  ? {
                      key: "header.PartyName",
                      fn: function ({ header }) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              _vm._v(" " + _vm._s(header.text) + " "),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "close-on-content-click": false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-filter ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "background-color": "white",
                                        width: "280px",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "pa-4",
                                        attrs: {
                                          label: "Enter a party",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.partySearch,
                                          callback: function ($$v) {
                                            _vm.partySearch = $$v
                                          },
                                          expression: "partySearch",
                                        },
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2 mb-2",
                                          attrs: { small: "", text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.partySearch = ""
                                            },
                                          },
                                        },
                                        [_vm._v("Clear")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "header.TicketNumber",
                  fn: function ({ header }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _vm._v(" " + _vm._s(header.text) + " "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-filter ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "white",
                                    width: "280px",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pa-4",
                                    attrs: {
                                      label: "Enter a ticket #",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.ticketNumberSearch,
                                      callback: function ($$v) {
                                        _vm.ticketNumberSearch = $$v
                                      },
                                      expression: "ticketNumberSearch",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2 mb-2",
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.ticketNumberSearch = ""
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "header.LoadNumber",
                  fn: function ({ header }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _vm._v(" " + _vm._s(header.text) + " "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-filter ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "white",
                                    width: "280px",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pa-4",
                                    attrs: {
                                      label: "Enter a load #",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.loadNumberSearch,
                                      callback: function ($$v) {
                                        _vm.loadNumberSearch = $$v
                                      },
                                      expression: "loadNumberSearch",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2 mb-2",
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.loadNumberSearch = ""
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "header.ModifiedDate",
                  fn: function ({ header }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _vm._v(" " + _vm._s(header.text) + " "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-filter ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "white",
                                    width: "280px",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pa-4",
                                    attrs: {
                                      label: "Enter a date",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.dateSearch,
                                      callback: function ($$v) {
                                        _vm.dateSearch = $$v
                                      },
                                      expression: "dateSearch",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2 mb-2",
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dateSearch = ""
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.FileName",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { link: true, target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.openLink(item.PreSignedUrl)
                            },
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-file"),
                          ]),
                          _vm._v(" " + _vm._s(item.FileName) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          })
        : _vm._e(),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "9", lg: "9", xl: "9" } }),
          _c(
            "v-col",
            {
              staticClass: "mt-2 mb-5",
              attrs: { cols: "12", md: "3", lg: "3", xl: "3" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-2 mr-2 ml-2 submit-button text--primary",
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.isDownloadDisabled,
                    color: "secondary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.DownloadSelectedFiles()
                    },
                  },
                },
                [_vm._v(" Download Selected ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }