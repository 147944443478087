var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", color: "primary", dark: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c(
            "router-link",
            { attrs: { to: "/home" } },
            [
              _c("v-img", {
                staticClass: "shrink mr-3",
                attrs: {
                  alt: "UP Logo",
                  contain: "",
                  src: require("@/assets/img_e_brand_up_color_halo_png.png"),
                  transition: "scale-transition",
                  width: "35",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "byline", attrs: { color: "white" } }, [
            _vm._v("Powered By JCT"),
          ]),
          _c("div", { staticClass: "vl" }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex short-menu",
          staticStyle: { width: "100%" },
          attrs: { fluid: "" },
        },
        [
          _vm.isUserAllowed(["Admin", "Master"])
            ? _c(
                "v-menu",
                {
                  staticClass: "mobile-nav",
                  attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "d-none d-sm-block yellow-text",
                                    attrs: { color: "primary", dark: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Users ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3871240676
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/users" },
                            },
                            [_vm._v("User Mangement")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/users/create" },
                            },
                            [_vm._v("Add A User")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isUserAllowed(["Admin", "Master"])
            ? _c(
                "v-menu",
                {
                  attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "d-none d-sm-block yellow-text",
                                    attrs: { color: "primary", dark: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Containers ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4081918290
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/containers/admin" },
                            },
                            [_vm._v("Containers Admin")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _vm.isUserAllowed(["Master", "Admin", "Party"])
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "d-none d-sm-block yellow-text",
                                  attrs: { color: "primary", dark: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" Outbound ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _vm.isUserAllowed(["Master", "Admin", "Party"])
                        ? _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/outbound/summary" },
                            },
                            [_vm._v("Booking Summary")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isUserAllowed(["Master", "Party"])
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/outbound/create" },
                            },
                            [_vm._v("Create a Booking")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    [
                      _vm.isUserAllowed(["Master", "Admin", "Party"])
                        ? _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/outbound/comments" },
                            },
                            [_vm._v("Booking Comments")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isUserAllowed(["Master", "Admin"])
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue-text",
                              attrs: { to: "/outbound/containermove" },
                            },
                            [_vm._v("Move Booking Container")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isUserAllowed(["Admin", "Master", "Party", "Trucker"])
            ? _c(
                "v-menu",
                {
                  attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "d-none d-sm-block yellow-text",
                                    attrs: { color: "primary", dark: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Inbound ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2740257779
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm.isUserAllowed(["Admin", "Master", "Party"])
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue-text",
                                  attrs: { to: "/forecast" },
                                },
                                [_vm._v("Delivery Forecast")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isUserAllowed([
                        "Party",
                        "Admin",
                        "Master",
                        "Fumigator",
                        "Trucker",
                      ])
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue-text",
                                  attrs: { to: "/deliveries/deliveries" },
                                },
                                [_vm._v("Deliveries")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isUserAllowed(["Trucker"])
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue-text",
                                  attrs: { to: "/booking/driver" },
                                },
                                [_vm._v("Schedule a Delivery")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isUserAllowed(["Admin", "Master", "Party"])
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue-text",
                                  attrs: { to: "/tickets" },
                                },
                                [_vm._v("Tickets")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isUserAllowed(["Admin", "Master", "Fumigator"])
            ? _c(
                "v-menu",
                {
                  attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "d-none d-sm-block yellow-text",
                                    attrs: { color: "primary", dark: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" Fumigation ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    372742411
                  ),
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm.isUserAllowed(["Admin", "Master", "Fumigator"])
                        ? _c(
                            "v-list-item",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "blue-text",
                                  attrs: { to: "/fumigation/containers" },
                                },
                                [_vm._v("Fumigation Containers")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.getUserLogin()
        ? _c(
            "v-menu",
            {
              attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "yellow-text",
                                attrs: { color: "primary", dark: "" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(" Menu "),
                            _c("v-icon", [_vm._v("mdi-chevron-down")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3648827138
              ),
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "blue-text",
                              attrs: { plain: "" },
                              on: { click: _vm.Logout },
                            },
                            [_vm._v("Logout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }