<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-alert dismissible border="left" type="error" v-model="isError">
          {{ errorMessage }}
        </v-alert>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            label="Enter A Load Number"
            v-model="loadNumber"
            required
            outlined
            clearable
            class="pt-5"
          >
          </v-text-field>
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="focus"
                label="Schedule Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="focus"
              :allowed-dates="allowedDates"
            ></v-date-picker>
          </v-menu>
          <v-btn
            @click="LoadSchedule"
            :disabled="!valid"
            :loading="loading"
            color="secondary"
            class="text--primary"
            >Search for Times</v-btn
          >
          <v-alert
            text
            dense
            color="teal"
            style="margin-top: 20px"
            icon="mdi-clock-fast"
            border="left"
          >
            Enter a load number here. If you recieve an error, please contact
            your dispatch to confirm you have the right number. If a slot is
            already booked, it will appear as "BOOKED".
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-show="schedulingLoaded">
      <v-col>
        <v-sheet height="800">
          <v-calendar
            type="day"
            ref="calendar"
            :first-interval="12"
            :interval-count="35"
            :interval-minutes="30"
            :interval-height="80"
            :interval-width="40"
            :event-overlap-mode="'column'"
            :events="schedule"
            @click:event="SlotDialog"
            v-model="focus"
          >
            <template v-slot:event="{ event }">
              <div v-if="!event.is_booked" class="open-slot slot">
                {{ event.name }}<br />
                {{ event.display_start }}<br />
              </div>
              <div v-else-if="event.is_booked" class="booked slot">
                {{ event.name }}<br />
                {{ event.display_start }}<br />
              </div>
            </template>
            <template v-slot:day-body="{ date, week }">
              <div
                v-if="timeline"
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              ></div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Confirm {{ startTime }} <br />
          to <br />
          {{ endTime }} Delivery Slot?
        </v-card-title>
        <v-card-text
          >This will schedule your time slot for your delivery.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="text--primary"
            text
            @click="dialog = false"
            :loading="loading"
          >
            Close
          </v-btn>
          <v-btn
            color="secondary"
            class="text--primary"
            text
            @click="ConfirmDelivery()"
            :loading="loading"
          >
            Confirm Delivery
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancel" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Cancel scheduled {{ startTime }} <br />
          to <br />
          {{ endTime }} Delivery Slot?
        </v-card-title>
        <v-card-text
          >This will cancel your scheduled your time slot for your delivery.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="cancel = false"
            :loading="loading"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="CancelDelivery()"
            :loading="loading"
          >
            Cancel Schedule
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const api = require("@/api/api.js");
export default {
  Name: "Schedule",
  computed: {
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    cal() {
      return this.schedulingLoaded ? this.$refs.calendar : null;
    },
  },
  data() {
    return {
      valid: true,
      loading: false,
      schedulingLoaded: false,
      dialog: false,
      cancel: false,
      deliveryInfo: {},
      isError: false,
      errorMessage: "",
      loadNumber: null,
      startTime: null,
      endTime: null,
      timeline: true,
      requiredRules: [(v) => !!v || "Field is required"],
      focus: new Date().toISOString().slice(0, 10),
      date: new Date().toISOString().slice(0, 10),
      schedule: [],
    };
  },
  watch: {
    events: {
      handler: function (after, before) {
        console.log(before);
        console.log(after);
      },
      deep: true,
    },
  },
  async mounted() {},
  methods: {
    async Cancel() {},
    async Save() {},
    SlotDialog(event) {
      this.deliveryInfo = event.event;

      if (Date.parse(event.event.end) < Date.parse(new Date())) return true;
      if (!event.event.is_booked) {
        this.dialog = true;
        this.startTime = event.event.display_start;
        this.endTime = event.event.display_end;
      } else {
        this.cancel = true;
        this.startTime = event.event.display_start;
        this.endTime = event.event.display_end;
      }
    },
    async LoadSchedule() {
      this.schedulingLoaded = false;
      this.isError = false;
      this.loading = true;
      let numberOfDays = 4;
      let currentDayNumber = new Date().getDay();
      if (currentDayNumber === 3) {
        numberOfDays = 5;
      } else if ([4, 5].includes(currentDayNumber)) {
        numberOfDays = 6;
      }
      const searchObject = {
        loadNumber: this.loadNumber,
        searchDate: this.focus,
        numberOfDays: numberOfDays,
      };
      try {
        this.schedule = await api.post(
          "/inbound/schedule/search",
          searchObject
        );
        this.loading = false;
        this.schedulingLoaded = true;
        console.log(this.schedule);
        await this.scrollToTime();
        await this.updateTime();

        if (
          new Date()
            .toLocaleDateString()
            .replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") !== this.focus
        ) {
          this.timeline = false;
        }
      } catch (error) {
        this.loading = false;
        this.errorMessage = error.response.data;
        this.schedule = [];

        if (this.errorMessage === "") {
          this.errorMessage = "A System error has occured. Please try again.";
        }

        this.isError = true;
      }
    },
    async ConfirmDelivery() {
      const scheduleBooking = {
        delivery_id: this.deliveryInfo.delivery_id,
        schedule_id: this.deliveryInfo.schedule_id,
        start_time: this.deliveryInfo.start,
        end_time: this.deliveryInfo.end,
        schedule_date: this.deliveryInfo.schedule_date,
        schedule_time: this.deliveryInfo.schedule_time,
        load_number: this.loadNumber,
      };
      this.loading = true;
      try {
        await api.post("/inbound/schedule/create", scheduleBooking);
        this.dialog = false;
        await this.LoadSchedule();
      } catch (error) {
        this.dialog = false;
        this.errorMessage = error.response.data;
        this.isError = true;
        if (this.errorMessage === "") {
          this.errorMessage = "A System error has occured. Please try again.";
        }
      }
      this.loading = false;
    },
    async CancelDelivery() {
      this.loading = true;
      try {
        await api.del(
          "/inbound/schedule/delete/" + this.deliveryInfo.delivery_schedule_id
        );
        console.log(this.deliveryInfo.delivery_schedule_id);
        this.cancel = false;
        await this.LoadSchedule();
      } catch (error) {
        this.cancel = false;
        this.errorMessage = error.response.data;

        if (this.errorMessage === "") {
          this.errorMessage = "A System error has occured. Please try again.";
          this.isError = true;
        }
      }
      this.loading = false;
    },
    async getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    async scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    async updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    allowedDates(val) {
      const allowedDates = [];
      allowedDates.push(new Date().toISOString().slice(0, 10));
      let x = 1;
      let totalDays = 5;
      let currentDayNumber = new Date().getDay();
      if (currentDayNumber === 3) {
        totalDays = 6;
      } else if ([4, 5].includes(currentDayNumber)) {
        totalDays = 7;
      }
      while (x < totalDays) {
        let allowedDate = new Date();
        allowedDate.setDate(allowedDate.getDate() + x);
        allowedDates.push(allowedDate.toISOString().slice(0, 10));
        x++;
      }

      if (allowedDates.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    ConvertTime(time) {
      return ((time + 11) % 12) + 1;
    },
  },
};
</script>

<style scoped>
.v-form {
  margin-top: 60px;
  border-radius: 0px;
  background: #feffff;
  box-shadow: 5px 5px 7px #f1f2f2, -5px -5px 7px #ffffff;
  padding: 20px;
}
.consent {
  color: #e53935;
}

::v-deep .v-current-time.first::before {
  content: "";
  position: absolute;
  background-color: #ea4335;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -5px;
  margin-left: -6.5px;
}

::v-deep .v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}

::v-deep .v-application .primary {
  background-color: white !important;
}

::v-deep .theme--light.v-calendar-events .v-event-timed {
  background-color: white;
}
::v-deep div .v-calendar .v-event-timed {
  background: none !important;
}

.v-calendar .v-event-timed {
  background-color: white !important;
}

div .v-event-timed .primary .white--text {
  background-color: white !important;
}

/*es-lint ignore*/
::v-deep .theme--light.v-calendar-daily .v-calendar-daily__day-interval {
  /*border-top: none !important;*/
}

::v-deep .v-calendar .v-event-timed {
  border: none;
}

::v-deep .v-event-timed {
}

::v-deep .theme--light.v-calendar-events .v-event-timed {
  border: none !important;
}

.slot {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  height: 60px;
  padding: 5px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  margin: 5px;
}

.booked {
  background-color: #c3332b;
}
.open-slot {
  background-color: white;
  color: grey;
}

.closed-slot {
  background-color: grey;
}

@media only screen and (max-width: 600px) {
  .slot {
    font-size: 10px;
    margin: 2px;
  }

  .v-calendar-daily {
    margin-left: -15px;
    margin-right: -15px;
  }

  ::v-deep .v-calendar-daily__intervals-body,
  ::v-deep .v-calendar-daily__intervals-head {
    display: none;
  }
}
</style>
