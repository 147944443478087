<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <ImportSchedule
      v-for="schedule in data"
      v-bind:key="schedule.index"
      v-bind:inboundDate="schedule.inboundDate"
      v-bind:orders="schedule.orders"
      v-bind:containers="schedule.containers"
    >
    </ImportSchedule>
  </div>
</template>
<script>
const api = require("@/api/api.js");

import ImportSchedule from "../../components/Inbound/InboundSchedule.vue";

export default {
  components: {
    ImportSchedule,
  },
  data() {
    return {
      data: [],
      isError: false,
      loading: true,
      alertMessage: "",
    };
  },
  mounted() {
    this.GetInboundSummary();
  },
  methods: {
    async GetInboundSummary() {
      try {
        this.data = await api.get("/inbound");
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
<style></style>
