<template>
  <v-container fluid>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-row class="pt-3 mb-4 justify-center">
      <v-slide-group
        v-model="model"
        hide-delimiters
        center-active
        show-arrows
        class="carousel"
        style="box-shadow: 0px 0px; height: 120px; padding: 10px"
      >
        <v-slide-item
          v-for="(inv, idx) in inventory"
          :key="idx"
          v-slot="{ active, toggle }"
        >
          <v-card class="carousel-card">
            <div class="mt-2 ml-3 pl-3 pt-5" @click="toggle">
              <v-row> <b>Item Number:&nbsp;</b>{{ inv.ItemNumber }} </v-row>
              <v-row>
                <b>Account Name:&nbsp;</b>{{ inv.AccountName | lowercase }}
              </v-row>
              <v-row
                ><b>Quantity on Hand:&nbsp;</b> {{ inv.QuantityOnHand }}</v-row
              >
              <v-row>
                <b>Last Updated Date:&nbsp;</b>
                {{
                  inv.LastUpdatedDate.split("T")[0] +
                  " " +
                  inv.LastUpdatedDate.split("T")[1].split(".")[0]
                }}
              </v-row>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data"
      item-key="booking"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      class="elevation-1 table"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :expanded.sync="expanded"
      :options.sync="pagination"
      :single-expand="singleExpand"
      @update:options="paginate"
    >
      <template v-slot:top>
        <v-row align="center" class="search-bar pt-1">
          <v-col class="d-flex" cols="12" sm="3">
            <v-text-field
              v-model="daysPast"
              label="Days back"
              class="mx-4"
              @input="fetchDestinationDebounced"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:header.BookingNumber="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="bookingSearch"
                label="Enter a booking number"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="bookingSearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.BookingStatusName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="statusSearch"
                label="Enter a booking status"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="statusSearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.ShipperName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="shipperSearch"
                label="Enter a shipper"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="shipperSearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.CommodityName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="commoditySearch"
                label="Enter a commodity"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="commoditySearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.ContainerTypeName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="containerSearch"
                label="Enter a container type"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="containerSearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.DestinationName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="destinationSearch"
                label="Enter a destination"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn
                @click="destinationSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.PartyAccountName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="partySearch"
                label="Enter a party account"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="partySearch = ''" small text class="ml-2 mb-2"
                >Clean</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.BookingNumber="{ item }">
        <router-link
          :to="'/outbound/billing/' + item.BookingId"
          class="text-decoration-underline"
          >{{ item.BookingNumber }}</router-link
        >
      </template>
      <template v-slot:item.BookingQuantity="{ item }">
        <span>
          {{ item.BookingQuantity || 0 }} / {{ item.ContainersLoaded || 0 }} /
          {{ item.ContainersAssigned || 0 }}
        </span>
      </template>
      <template v-slot:header.dateOne="{}">
        <span>
          {{ dateOne.getDate() }} {{ monthNames[dateOne.getMonth()] }} :
          {{ dateOneTotal }}
        </span>
      </template>
      <template v-slot:item.dateOne="{ item }">
        <v-text-field
          type="number"
          min="0"
          v-model="item[dateOneColumn]"
          @input="item.Modified = true"
          :disabled="dateOneDisabled || bookingNotPlanable(item, dateOneColumn)"
          class="mt-4"
          rounded
          outlined
          :rules="[numberRule]"
          v-on:change="updateContainerTotals()"
          :error="IsContainerSumError(item)"
        ></v-text-field>
      </template>
      <template v-slot:header.dateTwo="{}">
        <span>
          {{ dateTwo.getDate() }} {{ monthNames[dateTwo.getMonth()] }} :
          {{ dateTwoTotal }}
        </span>
      </template>
      <template v-slot:item.dateTwo="{ item }">
        <v-text-field
          v-model="item[dateTwoColumn]"
          @input="item.Modified = true"
          :disabled="dateTwoDisabled || bookingNotPlanable(item, dateTwoColumn)"
          class="mt-4"
          rounded
          outlined
          :rules="[numberRule]"
          v-on:change="updateContainerTotals()"
          :error="IsContainerSumError(item)"
        ></v-text-field>
      </template>
      <template v-slot:header.dateThree="{}">
        <span>
          {{ dateThree.getDate() }} {{ monthNames[dateThree.getMonth()] }} :
          {{ dateThreeTotal }}
        </span>
      </template>
      <template v-slot:item.dateThree="{ item }">
        <v-text-field
          v-model="item[dateThreeColumn]"
          @input="item.Modified = true"
          :disabled="
            dateThreeDisabled || bookingNotPlanable(item, dateThreeColumn)
          "
          class="mt-4"
          rounded
          outlined
          :rules="[numberRule]"
          v-on:change="updateContainerTotals()"
          :error="IsContainerSumError(item)"
        ></v-text-field>
      </template>
      <template v-slot:header.dateFour="{}">
        <span>
          {{ dateFour.getDate() }} {{ monthNames[dateFour.getMonth()] }} :
          {{ dateFourTotal }}
        </span>
      </template>
      <template v-slot:item.dateFour="{ item }">
        <v-text-field
          v-model="item[dateFourColumn]"
          @input="item.Modified = true"
          :disabled="
            dateFourDisabled || bookingNotPlanable(item, dateFourColumn)
          "
          class="mt-4"
          rounded
          outlined
          :rules="[numberRule]"
          v-on:change="updateContainerTotals()"
          :error="IsContainerSumError(item)"
        ></v-text-field>
      </template>
      <template v-slot:header.FumigateFlag="{ header }">
        <div
          v-if="header.text === '2' || header.text === 2"
          class="d-flex"
          style="align-items: center"
        >
          <p>Conditional Name Test</p>
        </div>
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:item.review="{ item }">
        <v-btn icon color="green">
          <v-icon
            color="black"
            v-if="
              item.BookingStatusName === 'Booking pending review' ||
              item.BookingStatusName === 'Booking pending update review'
            "
            @click="ReviewBooking(item)"
            >mdi-alert-circle</v-icon
          >
        </v-btn>
      </template>
      <template v-slot:item.edit="{ item }">
        <router-link :to="'/outbound/edit/' + item.BookingId">
          <v-icon color="grey">mdi-pencil</v-icon>
        </router-link>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="12" md="6" lg="10" xl="10"> </v-col>
      <v-col
        v-if="isUserAllowed(['Admin', 'Master'])"
        cols="12"
        md="3"
        lg="2"
        xl="2"
        class="d-flex justify-center"
      >
        <v-btn
          @click="UpdatePlans"
          :loading="loading"
          color="secondary"
          class="mr-2 ml-2 mt-3 text--primary"
          width="100%"
        >
          Update Plan
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const api = require("@/api/api.js");
const { DateTime } = require("luxon");
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "LoadSummary",

  components: {},
  data() {
    return {
      model: 0,
      search: "",
      calories: "",
      pagination: {
        rowsPerPage: +this.$route.query.limit || 30,
        itemsPerPage: +this.$route.query.limit || 30,
      },
      dateOneDisabled: false,
      dateTwoDisabled: false,
      dateThreeDisabled: false,
      dateFourDisabled: false,
      dateOne: new Date(),
      dateTwo: new Date(),
      dateThree: new Date(),
      dateFour: new Date(),
      dateFive: new Date(),
      daysPast: 30,
      dateOneColumn: new Date(),
      dateTwoColumn: new Date(),
      dateThreeColumn: new Date(),
      dateFourColumn: new Date(),
      dateFiveColumn: new Date(),
      dateOneTotal: 0,
      dateTwoTotal: 0,
      dateThreeTotal: 0,
      dateFourTotal: 0,
      dateFiveTotal: 0,
      expanded: [],
      inventory: [],
      singleExpand: false,
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      data: [],
      orderSection: [
        { text: "Past 30 days", value: 30 },
        { text: "Past 60 days", value: 60 },
        { text: "Past 90 days", value: 90 },
      ],
      sortBy: "CutDate",
      sortDesc: true,
      isError: false,
      loading: true,
      alertMessage: "",
      bookingSearch: "",
      statusSearch: "",
      commoditySearch: "",
      shipperSearch: "",
      containerSearch: "",
      destinationSearch: "",
      partySearch: "",
      numberRule: (val) => {
        if (val < 0) {
          return "Number must be postive.";
        }
        return true;
      },
    };
  },
  computed: {
    headers() {
      if (isUserAllowed(["Admin", "Master"])) {
        return [
          {
            text: "Booking",
            value: "BookingNumber",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.bookingSearch !== this.bookingSearch) {
                  obj.bookingSearch = this.bookingSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.bookingSearch || this.bookingSearch === "") return true;
              return (
                value != null &&
                this.bookingSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.bookingSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Status",
            value: "BookingStatusName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.statusSearch !== this.statusSearch) {
                  obj.statusSearch = this.statusSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.statusSearch || this.statusSearch === "") return true;
              return (
                value != null &&
                this.statusSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.statusSearch.toString().toLocaleUpperCase()) !==
                  -1
              );
            },
          },
          {
            text: "SSL",
            value: "ShipperName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.shipperSearch !== this.shipperSearch) {
                  obj.shipperSearch = this.shipperSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.shipperSearch || this.shipperSearch === "") return true;
              return (
                value != null &&
                this.shipperSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.shipperSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Commodity",
            value: "CommodityName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.commoditySearch !== this.commoditySearch) {
                  obj.commoditySearch = this.commoditySearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.commoditySearch || this.commoditySearch === "")
                return true;
              return (
                value != null &&
                this.commoditySearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.commoditySearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Container Type",
            value: "ContainerTypeName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.containerSearch !== this.containerSearch) {
                  obj.containerSearch = this.containerSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.containerSearch || this.containerSearch === "")
                return true;
              return (
                value != null &&
                this.containerSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.containerSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          { text: "ERD", value: "EarliestReleaseDate" },
          { text: "CUT", value: "CutDate" },
          {
            text: "Destination",
            value: "DestinationName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.destinationSearch !== this.destinationSearch) {
                  obj.destinationSearch = this.destinationSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.destinationSearch || this.destinationSearch === "")
                return true;
              return (
                value != null &&
                this.destinationSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.destinationSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Party",
            value: "PartyAccountName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.partySearch !== this.partySearch) {
                  obj.partySearch = this.partySearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.partySearch || this.partySearch === "") return true;
              return (
                value != null &&
                this.partySearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.partySearch.toString().toLocaleUpperCase()) !==
                  -1
              );
            },
          },
          { text: "Total / Loaded / Assigned", value: "BookingQuantity" },
          { text: "", value: "dateOne" },
          { text: "", value: "dateTwo" },
          { text: "", value: "dateThree" },
          { text: "", value: "dateFour" },
          { text: "", value: "review" },
          {
            text: "Fumigation Status",
            value: "FumigateFlag",
            sortable: false,
          },
          { text: "", value: "edit" },
        ];
      } else {
        return [
          {
            text: "Booking",
            value: "BookingNumber",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.bookingSearch !== this.bookingSearch) {
                  obj.bookingSearch = this.bookingSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.bookingSearch || this.bookingSearch === "") return true;
              return (
                value != null &&
                this.bookingSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.bookingSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Status",
            value: "BookingStatusName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.statusSearch !== this.statusSearch) {
                  obj.statusSearch = this.statusSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.statusSearch || this.statusSearch === "") return true;
              return (
                value != null &&
                this.statusSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.statusSearch.toString().toLocaleUpperCase()) !==
                  -1
              );
            },
          },
          {
            text: "SSL",
            value: "ShipperName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.shipperSearch !== this.shipperSearch) {
                  obj.shipperSearch = this.shipperSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.shipperSearch || this.shipperSearch === "") return true;
              return (
                value != null &&
                this.shipperSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.shipperSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Commodity",
            value: "CommodityName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.commoditySearch !== this.commoditySearch) {
                  obj.commoditySearch = this.commoditySearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.commoditySearch || this.commoditySearch === "")
                return true;
              return (
                value != null &&
                this.commoditySearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.commoditySearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Container Type",
            value: "ContainerTypeName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.containerSearch !== this.containerSearch) {
                  obj.containerSearch = this.containerSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.containerSearch || this.containerSearch === "")
                return true;
              return (
                value != null &&
                this.containerSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.containerSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          { text: "ERD", value: "EarliestReleaseDate" },
          { text: "CUT", value: "CutDate" },
          {
            text: "Destination",
            value: "DestinationName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.destinationSearch !== this.destinationSearch) {
                  obj.destinationSearch = this.destinationSearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.destinationSearch || this.destinationSearch === "")
                return true;
              return (
                value != null &&
                this.destinationSearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(
                    this.destinationSearch.toString().toLocaleUpperCase()
                  ) !== -1
              );
            },
          },
          {
            text: "Party",
            value: "PartyAccountName",
            sortable: false,
            filter: (value) => {
              if (!this.loading) {
                const query = this.$route.query;
                const obj = Object.assign({}, query);
                if (obj.partySearch !== this.partySearch) {
                  obj.partySearch = this.partySearch;
                  this.$router
                    .replace({
                      path: this.$route.path,
                      query: obj,
                    })
                    .catch(() => {});
                }
              }
              if (!this.partySearch || this.partySearch === "") return true;
              return (
                value != null &&
                this.partySearch != null &&
                typeof value === "string" &&
                value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.partySearch.toString().toLocaleUpperCase()) !==
                  -1
              );
            },
          },
          { text: "Total / Load / Assigned", value: "BookingQuantity" },
          {
            text: "Fumigation Status",
            value: "FumigateFlag",
            sortable: false,
          },
          { text: "", value: "edit" },
        ];
      }
    },
  },
  mounted() {
    this.daysPast = this.$route.query.daysPast || 30;
    this.GetPlanDates();
    this.GetLoadSummary();
    this.GetAgrisInventory();
  },
  methods: {
    isUserAllowed,
    filterOnlyCapsText(value, search) {
      // item might also need to be passed
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    fetchDestinationDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        const query = this.$route.query;
        const obj = Object.assign({}, query);
        if (obj.daysPast !== this.daysPast && !this.loading) {
          obj.daysPast = this.daysPast;
          this.$router
            .replace({
              path: this.$route.path,
              query: obj,
            })
            .catch(() => {});
        }
        this.GetLoadSummary();
      }, 500);
    },
    async GetLoadSummary() {
      this.loading = true;
      try {
        this.data = await api.get("/loadsummary?daysPast=" + this.daysPast);
        this.data.forEach((booking) => {
          if (booking.FumigateFlag) {
            if (booking.FumigateFlag === 1) {
              booking.FumigateFlag = "Fumigation not required";
            } else if (booking.FumigateFlag === 2) {
              booking.FumigateFlag = "Phosphine";
            } else if (booking.FumigateFlag === 3) {
              booking.FumigateFlag = "Methyl Bromide";
            }
          }
        });
        this.updateContainerTotals();
        this.setFilterVariables();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to get load summary. Please try again.";
        console.log(err);
      }
    },
    IsContainerSumError(item) {
      let dateOne = 0;
      let dateTwo = 0;
      let dateThree = 0;
      let dateFour = 0;
      if (this.dateOneDisabled === false && item[this.dateOneColumn]) {
        dateOne = parseInt(item[this.dateOneColumn]);
      }
      if (this.dateTwoDisabled === false && item[this.dateTwoColumn]) {
        dateTwo = parseInt(item[this.dateTwoColumn]);
      }
      if (this.dateThreeDisabled === false && item[this.dateThreeColumn]) {
        dateThree = parseInt(item[this.dateThreeColumn]);
      }
      if (this.dateFourDisabled === false && item[this.dateFourColumn]) {
        dateFour = parseInt(item[this.dateFourColumn]);
      }
      return (
        item.BookingQuantity <
        item.ContainersLoaded +
          item.ContainersAssigned +
          dateOne +
          dateTwo +
          dateThree +
          dateFour
      );
    },
    /*
    IsContainerSumError(item) {
      return (
        item.BookingQuantity <
        item.ContainersLoaded +
          item.ContainersAssigned +
          parseInt(item[this.dateOneColumn]) +
          parseInt(item[this.dateTwoColumn]) +
          parseInt(item[this.dateThreeColumn]) +
          parseInt(item[this.dateFourColumn])
      );
    },
    */
    async ReviewBooking(item) {
      this.loading = true;
      try {
        await api.put("/load/review", item);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to Update Booking. Please try again.";
        console.log(err);
      }
      this.GetLoadSummary();
    },
    async UpdatePlans() {
      let badBookingContainerSums = await this.ValidateContainerSums();
      if (badBookingContainerSums.length > 0) {
        this.isError = true;
        this.alertMessage = `Failed to update plans. To many containers for plan(s): (${badBookingContainerSums.join(
          ", "
        )}) . Please try again.`;
      } else {
        this.loading = true;
        try {
          await api.post(
            "/load/bookingplans",
            this.data.filter((booking) => booking.Modified)
          );
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.isError = true;
          this.alertMessage = "Failed to Update Plan. Please try again.";
          console.log(err);
        }
        this.GetLoadSummary();
      }
    },
    ValidateContainerSums() {
      let badBookingContainerSums = [];
      for (const booking of this.data) {
        if (this.IsContainerSumError(booking)) {
          badBookingContainerSums.push(booking.BookingNumber);
        }
      }
      return badBookingContainerSums;
    },
    async GetAgrisInventory() {
      try {
        this.inventory = await api.get("/inventory");
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Failed to get Agris Inventory. Please try again later.";
      }
    },
    async GetPlanDates() {
      try {
        const dates = await api.get("/load/plandates");
        this.dateOne = new Date(dates[0].PlanDate + "T00:00:01");
        this.dateOneColumn = dates[0].PlanDate;
        this.dateOneDisabled = dates[0].LockDateTime !== null ? true : false;
        this.dateTwo = new Date(dates[1].PlanDate + "T00:00:01");
        this.dateTwoColumn = dates[1].PlanDate;
        this.dateTwoDisabled = dates[1].LockDateTime !== null ? true : false;
        this.dateThree = new Date(dates[2].PlanDate + "T00:00:01");
        this.dateThreeColumn = dates[2].PlanDate;
        this.dateThreeDisabled = dates[2].LockDateTime !== null ? true : false;
        this.dateFour = new Date(dates[3].PlanDate + "T00:00:01");
        this.dateFourColumn = dates[3].PlanDate;
        this.dateFourDisabled = dates[3].LockDateTime !== null ? true : false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to get Plan Dates. Please try again.";
      }
    },
    bookingNotPlanable(item, plandate) {
      let erd = DateTime.fromISO(item.EarliestReleaseDate)
        .plus({ days: item.FumigateFlag > 1 ? -3 : 0 })
        .toString();
      return (
        item.StatusId === 27 ||
        item.StatusId === 28 ||
        plandate < erd.split("T")[0] ||
        plandate > item.CutDate
      );
    },
    async updateContainerTotals() {
      this.dateOneTotal = 0;
      this.dateTwoTotal = 0;
      this.dateThreeTotal = 0;
      this.dateFourTotal = 0;
      this.dateFiveTotal = 0;
      if (this.data.length > 0) {
        this.data.forEach((order) => {
          this.dateOneTotal += order[this.dateOneColumn]
            ? parseInt(order[this.dateOneColumn])
            : 0;
          this.dateTwoTotal += order[this.dateTwoColumn]
            ? parseInt(order[this.dateTwoColumn])
            : 0;
          this.dateThreeTotal += order[this.dateThreeColumn]
            ? parseInt(order[this.dateThreeColumn])
            : 0;
          this.dateFourTotal += order[this.dateFourColumn]
            ? parseInt(order[this.dateFourColumn])
            : 0;
          this.dateFiveTotal += order[this.dateFiveColumn]
            ? parseInt(order[this.dateFiveColumn])
            : 0;
        });
      }
    },
    paginate(val) {
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      obj.limit = val.itemsPerPage;
      obj.page = val.page;

      if (!this.loading) {
        this.$router
          .replace({
            path: this.$route.path,
            query: obj,
          })
          .catch(() => {});
      }
    },
    setFilterVariables() {
      this.bookingSearch = this.$route.query.bookingSearch || "";
      this.statusSearch = this.$route.query.statusSearch || "";
      this.commoditySearch = this.$route.query.commoditySearch || "";
      this.shipperSearch = this.$route.query.shipperSearch || "";
      this.containerSearch = this.$route.query.containerSearch || "";
      this.destinationSearch = this.$route.query.destinationSearch || "";
      this.partySearch = this.$route.query.partySearch || "";
      this.pagination = {
        rowsPerPage: +this.$route.query.limit || 30,
        itemsPerPage: +this.$route.query.limit || 30,
        page: +this.$route.query.page || 1,
      };
    },
  },
};
</script>

<style>
.search-bar {
  padding-top: 15px;
}

.table {
  padding-left: 15px;
  padding-right: 15px;
}

.text-start {
  min-width: 90px;
}

.carousel-card {
  width: 275px;
  margin-right: 7px;
  margin-left: 7px;
  font-size: 12px;
}

.v-window__container {
  padding-left: 70px;
  padding-right: 70px;
}

.carousel {
  width: 1565px;
}

@media only screen and (max-width: 1565px) {
  .carousel {
    width: 1280px;
  }
}

@media only screen and (max-width: 1280px) {
  .carousel {
    width: 990px;
  }
}

@media only screen and (max-width: 990px) {
  .carousel {
    width: 700px;
  }
}

@media only screen and (max-width: 700px) {
  .carousel {
    width: 100%;
  }
}
</style>
