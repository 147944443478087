<template>
  <v-card class="d-flex justify-center mb-12" flat tile height="100%">
    <v-card class="pa-2" tile :class="`align-self-center elevation-0`">
      <v-form
        @submit.prevent="validate"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <h1>Multi-Factor Authentication</h1>
              <p>Enter Your Multi-Factor Authentication Code now.</p>
              <v-alert dismissible border="left" type="error" v-model="isError">
                {{ errorMessage }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="mfacode"
                label="MFA Code"
                outlined
                required
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                :disabled="!valid"
                color="secondary"
                class="mr-6 text--primary"
                type="submit"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "MFAValidation",
  props: {
    sessionUser: {
      type: Object,
      required: true,
    },
    mfaType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    show: false,
    mfacode: "",
    isError: false,
    errorMessage: "",
    loading: false,
  }),
  mounted() {},
  methods: {
    async validate() {
      const isValid = this.$refs.form.validate();
      const admins = ["Admin", "Master"];
      if (isValid) {
        this.loading = true;
        try {
          await Auth.confirmSignIn(
            this.sessionUser,
            this.mfacode,
            this.mfaType
          ).then((user) => {
            this.$store.commit("logIn", {
              isLoggedIn: true,
              username: user.Username,
              token: user.signInUserSession.accessToken.jwtToken,
              isAdmin: admins.some((i) =>
                user.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(i)
              ),
              userType:
                user.signInUserSession.accessToken.payload["cognito:groups"][0],
            });
          });

          await Auth.rememberDevice();

          this.$router.push({ name: "Home" });
        } catch (err) {
          this.errorMessage = err.message;
          this.isError = true;
          this.loading = false;
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
