<template>
  <ul class="breadcrumbs">
    <li v-for="(item, index) in levelList" :key="item.path">
      <span
        v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
        class="no-redirect"
        >{{ item.meta.title }}</span
      >
      <a v-else @click.prevent="handleLink(item)"
        >{{ item.meta.title }}<span class="breadcrumb-route">/</span></a
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: "Breadcrumbs",
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{ path: "/", meta: { title: "Home" } }].concat(matched);
      }

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === "Home".toLocaleLowerCase();
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
ul.breadcrumbs {
  outline-style: none;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 15px;
}
ul.breadcrumbs li {
  display: inline-block;
}

.breadcrumb-route {
  padding: 0 8px;
}

.no-redirect {
  font-weight: bold;
}
</style>
