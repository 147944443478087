var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _c(
        "v-tabs",
        {
          staticClass: "mt-5",
          attrs: { "align-with-title": "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tabs-slider", { attrs: { color: "secondary" } }),
          _vm._l(_vm.tabs, function (item) {
            return _c(
              "v-tab",
              {
                key: item,
                staticClass: "tabs",
                attrs: { "active-class": "active-tab" },
              },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "mt-2",
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { key: "Details" },
            [
              _c(
                "v-row",
                { staticClass: "ml-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: !this.isUserAllowed(["Admin", "Master"]),
                          label: "Invoicing Status",
                          items: _vm.InvoicingStatuses,
                          "item-text": "text",
                          "item-value": "value",
                        },
                        model: {
                          value: _vm.InvoicingStatusId,
                          callback: function ($$v) {
                            _vm.InvoicingStatusId = $$v
                          },
                          expression: "InvoicingStatusId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: !this.isUserAllowed(["Admin", "Master"]),
                          label: "Documentation Status",
                          items: _vm.DocumentationStatuses,
                          "item-text": "text",
                          "item-value": "value",
                        },
                        model: {
                          value: _vm.DocumentationStatusId,
                          callback: function ($$v) {
                            _vm.DocumentationStatusId = $$v
                          },
                          expression: "DocumentationStatusId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          disabled: !this.isUserAllowed(["Admin", "Master"]),
                          label: "Payment Status",
                          items: _vm.PaymentStatuses,
                          "item-text": "text",
                          "item-value": "value",
                        },
                        model: {
                          value: _vm.PaymentStatusId,
                          callback: function ($$v) {
                            _vm.PaymentStatusId = $$v
                          },
                          expression: "PaymentStatusId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", {
                    attrs: { cols: "12", md: "4", lg: "4", xl: "4" },
                  }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _vm.isUserAllowed(["Admin", "Master"])
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "mt-2 mr-2 ml-2 submit-button text--primary",
                              attrs: {
                                loading: _vm.loading,
                                color: "secondary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.UpdateBookingStatuses()
                                },
                              },
                            },
                            [_vm._v(" Update statuses ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-3" },
                [
                  _vm.isUserAllowed(["Admin", "Master"]) &&
                  this.showGrades === false
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "6", xl: "6" } },
                        [
                          _c("v-data-table", {
                            attrs: {
                              headers: _vm.averageGradeHeaders,
                              items: _vm.averageGrades,
                              loading: _vm.loading,
                              "hide-default-footer": "",
                              dense: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.Avg_Factor_TW",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorTW(
                                              item.Avg_Factor_TW
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_TW) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_M",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorM(
                                              item.Avg_Factor_M
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_M) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_HT",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorHT(
                                              item.Avg_Factor_HT
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_HT) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_DKT",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorDKT(
                                              item.Avg_Factor_DKT
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_DKT) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_FM",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorFM(
                                              item.Avg_Factor_FM
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_FM) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_SBOC",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorSBOC(
                                              item.Avg_Factor_SBOC
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_SBOC) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.Avg_Factor_SPLITS",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          staticStyle: { margin: "4px" },
                                          attrs: {
                                            color: _vm.getGradeColorSPLITS(
                                              item.Avg_Factor_SPLITS
                                            ),
                                            dark: "",
                                            label: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.Avg_Factor_SPLITS) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              258791076
                            ),
                          }),
                        ],
                        1
                      )
                    : _c("v-col", {
                        attrs: { cols: "12", md: "6", lg: "6", xl: "6" },
                      }),
                  _vm.isUserAllowed(["Admin", "Master"]) &&
                  this.showGrades === false
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mt-2 mr-2 ml-2 submit-button text--primary",
                              attrs: {
                                loading: _vm.loading,
                                color: "secondary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.CalculateAverageGrades()
                                },
                              },
                            },
                            [_vm._v(" Calc Avg Grades ")]
                          ),
                        ],
                        1
                      )
                    : _c("v-col", {
                        attrs: { cols: "12", md: "2", lg: "2", xl: "2" },
                      }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isUserAllowed(["Admin", "Master"]),
                              expression: "isUserAllowed(['Admin', 'Master'])",
                            },
                          ],
                          staticClass:
                            "mt-2 mr-2 ml-2 submit-button text--primary",
                          attrs: { loading: _vm.loading, color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.UpdatePackingListStatus()
                            },
                          },
                        },
                        [_vm._v(" Create Packing List ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _vm.isUserAllowed(["Admin", "Master"])
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "mt-2 mr-2 ml-2 submit-button text--primary",
                              attrs: {
                                loading: _vm.loading,
                                color: "secondary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.UpdateRailBillStatus()
                                },
                              },
                            },
                            [_vm._v(" Mark as Rail Billed ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1 table mb-5",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.data,
                  "item-key": "bookingNumber",
                  loading: _vm.loading,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 30, 40, 50, -1],
                  },
                  "items-per-page": 30,
                  "show-expand": "",
                  expanded: _vm.expanded,
                  "hide-default-footer": "",
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "expanded-item",
                    fn: function ({ headers, item }) {
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: headers.length } },
                          [
                            _vm.isUserAllowed(["Admin", "Master"])
                              ? _c("v-data-table", {
                                  staticClass: "elevation-1 table",
                                  attrs: {
                                    headers: _vm.containerHeaders,
                                    items: item.containers,
                                    "item-key": "BookingContainerId",
                                    loading: _vm.loading,
                                    "footer-props": {
                                      "items-per-page-options": [
                                        10, 20, 30, 40, 50, -1,
                                      ],
                                    },
                                    "items-per-page": 30,
                                    "show-select": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.ContainerNumber",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "div",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.ContainerNumber
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " Booking Container Id: " +
                                                      _vm._s(
                                                        item.BookingContainerId
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.SealNumber",
                                        fn: function ({ item }) {
                                          return [
                                            item.SealNumber !== null
                                              ? _c(
                                                  "v-edit-dialog",
                                                  {
                                                    attrs: {
                                                      "return-value":
                                                        item.SealNumber,
                                                      large: "",
                                                      persistent: "",
                                                    },
                                                    on: {
                                                      "update:returnValue":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "SealNumber",
                                                            $event
                                                          )
                                                        },
                                                      "update:return-value":
                                                        function ($event) {
                                                          return _vm.$set(
                                                            item,
                                                            "SealNumber",
                                                            $event
                                                          )
                                                        },
                                                      save: function ($event) {
                                                        return _vm.UpdateContainer(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "input",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mt-4 text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Update Seal Number"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Edit",
                                                                    autofocus:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.SealNumber,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "SealNumber",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.SealNumber",
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(item.SealNumber)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.NetWeight",
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.Weight - item.TareWeight
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.review",
                                        fn: function ({ item }) {
                                          return [
                                            _vm.isUserAllowed([
                                              "Admin",
                                              "Master",
                                            ])
                                              ? _c(
                                                  "v-btn",
                                                  { attrs: { icon: "" } },
                                                  [
                                                    item.BookingContainerEventStatusName ===
                                                    "PendingRailBilling"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "black",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.UpdateContainerStatus(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-alert-circle"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedContainers,
                                    callback: function ($$v) {
                                      _vm.selectedContainers = $$v
                                    },
                                    expression: "selectedContainers",
                                  },
                                })
                              : _c("v-data-table", {
                                  staticClass: "elevation-1 table",
                                  attrs: {
                                    headers: _vm.containerHeaders,
                                    items: item.containers,
                                    "item-key": "ContainerNumber",
                                    loading: _vm.loading,
                                    "footer-props": {
                                      "items-per-page-options": [
                                        10, 20, 30, 40, 50, -1,
                                      ],
                                    },
                                    "items-per-page": 30,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item.PackingListStatusId",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          item.PackingListStatusId ===
                                                          152
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-clipboard-text-clock-outline"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Pending Packing List"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          item.PackingListStatusId ===
                                                          154
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-clipboard-text-clock-outline"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Processing Packing List"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          item.PackingListStatusId ===
                                                          156
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-clipboard-check-outline"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Packing List Created"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.RailBillingStatusId",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "primary",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          item.RailBillingStatusId ===
                                                          164
                                                            ? _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {},
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-currency-usd"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Rail Billed"),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.SealNumber",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-edit-dialog",
                                              {
                                                attrs: {
                                                  "return-value":
                                                    item.SealNumber,
                                                  large: "",
                                                  persistent: "",
                                                },
                                                on: {
                                                  "update:returnValue":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        item,
                                                        "SealNumber",
                                                        $event
                                                      )
                                                    },
                                                  "update:return-value":
                                                    function ($event) {
                                                      return _vm.$set(
                                                        item,
                                                        "SealNumber",
                                                        $event
                                                      )
                                                    },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "input",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mt-4 text-h6",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Update Seal Number"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label: "Edit",
                                                              autofocus: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item.SealNumber,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "SealNumber",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.SealNumber",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(item.SealNumber)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.NetWeight",
                                        fn: function ({ item }) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.Weight - item.TareWeight
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item.review",
                                        fn: function ({ item }) {
                                          return [
                                            _vm.isUserAllowed([
                                              "Admin",
                                              "Master",
                                            ])
                                              ? _c(
                                                  "v-btn",
                                                  { attrs: { icon: "" } },
                                                  [
                                                    item.BookingContainerEventStatusName ===
                                                    "PendingRailBilling"
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "black",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.UpdateContainerStatus(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-alert-circle"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.selectedContainers,
                                    callback: function ($$v) {
                                      _vm.selectedContainers = $$v
                                    },
                                    expression: "selectedContainers",
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: "Document" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1 table",
                attrs: {
                  headers: _vm.containerFileHeaders,
                  items: _vm.documents,
                  "item-key": "FileName",
                  loading: _vm.loading,
                  "footer-props": {
                    "items-per-page-options": [10, 20, 30, 40, 50, -1],
                  },
                  "items-per-page": 30,
                  "show-select": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header.FileType",
                    fn: function ({ header }) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "align-items": "center" } },
                          [
                            _vm._v(" " + _vm._s(header.text) + " "),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "offset-y": "",
                                  "close-on-content-click": false,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { icon: "" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v(" mdi-filter ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "background-color": "white",
                                      width: "280px",
                                    },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "pa-4",
                                      attrs: {
                                        label: "Enter a file type",
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.bookingFileTypeSearch,
                                        callback: function ($$v) {
                                          _vm.bookingFileTypeSearch = $$v
                                        },
                                        expression: "bookingFileTypeSearch",
                                      },
                                    }),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ml-2 mb-2",
                                        attrs: { small: "", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.bookingFileTypeSearch = ""
                                          },
                                        },
                                      },
                                      [_vm._v("Clear")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.FileName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { link: true, target: "_blank" },
                            on: {
                              click: function ($event) {
                                return _vm.openLink(item.PreSignedUrl)
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v("mdi-file"),
                            ]),
                            _vm._v(" " + _vm._s(item.FileName) + " "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.data-table-select",
                    fn: function ({ item, isSelected, select }) {
                      return [
                        _c("v-simple-checkbox", {
                          attrs: { value: isSelected, ripple: false },
                          on: {
                            input: function ($event) {
                              return select($event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _c(
                "v-row",
                [
                  _c("v-col", {
                    attrs: { cols: "12", md: "10", lg: "10", xl: "10" },
                  }),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2 mb-5",
                      attrs: { cols: "12", md: "2", lg: "2", xl: "2" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "mt-2 mr-2 ml-2 submit-button text--primary",
                          attrs: {
                            loading: _vm.loading,
                            disabled: !_vm.selected.length,
                            color: "secondary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.DownloadSelectedFiles()
                            },
                          },
                        },
                        [_vm._v(" Download Selected ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }