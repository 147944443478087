<template>
  <v-app-bar color="secondary">
    <div class="d-flex align-center">
      <h3>{{ levelList }}</h3>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "PageTitle",
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route() {
      this.GetPageTitle();
    },
  },
  created() {
    this.GetPageTitle();
  },
  methods: {
    GetPageTitle() {
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );

      if (matched[1] === undefined) {
        this.levelList = "Home";
      } else {
        this.levelList = matched[1].meta.title;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul.breadcrumbs {
  outline-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 52px;
}
ul.breadcrumbs li {
  display: inline-block;
}

a {
  color: #ffffff !important;
}

.breadcrumb-route {
  padding: 0 8px;
}

.no-redirect {
  font-weight: bold;
}
</style>
