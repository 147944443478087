<template>
  <v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-col cols="12" md="12">
        <v-text-field
          v-model="user.first_name"
          label="First Name"
          required
          :rules="firstNameRules"
          outlined
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          v-model="user.last_name"
          label="Last Name"
          required
          :rules="lastNameRules"
          outlined
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          v-model="user.email"
          label="Email"
          required
          :rules="emailRules"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12">
        <v-text-field-simplemask
          v-model="user.temp_number"
          :label="phoneNumberLabel"
          :properties="{
            outlined: true,
            clearable: true,
            rules: [
              (v) => user.temp_number && (!!v || 'Phone number is required'),
              (v) =>
                user.temp_number && (v.length === 14 || 'Invalid Phone Number'),
            ],
          }"
          :options="{
            inputMask: '(###) ###-####',
            outputMask: '##########',
          }"
          required
        />
      </v-col>
      <v-col cols="12" md="12" v-if="!user.self_register">
        <v-select
          v-model="user.role_id"
          :items="roles"
          :rules="groupRules"
          label="User Type"
          outlined
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="12" v-if="user.group === 'Party'">
        <v-select
          v-model="user.party_id"
          :items="parties"
          label="Party"
          outlined
        >
        </v-select>
      </v-col>
      <v-btn
        @click="Save"
        :disabled="!valid"
        :loading="loading || loadingParties || loadingRoles"
        color="secondary"
        class="text--primary ml-2 mr-2 mb-2"
        >{{ this.user.self_register ? "Register" : "Save User" }}</v-btn
      >
      <v-btn @click="Cancel" class="text--primary mb-2">Cancel</v-btn>
    </v-form>
  </v-row>
</template>

<script>
const api = require("@/api/api.js");
export default {
  Name: "UserDetails",
  props: {
    user: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      tempPhoneNumber: null,
      phoneNumberLabel: "Mobile Phone Number",
      focus: false,
      parties: [],
      roles: [],
      valid: true,
      loadingParties: true,
      loadingRoles: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(v) ||
          "E-mail must be valid",
      ],
      requiredRules: [(v) => !!v || "Field is required"],
      firstNameRules: [
        (v) => !!v || "First Name is required",
        (v) => /^[a-z ,.'-]+$/i.test(v) || "Please enter first name",
      ],
      lastNameRules: [
        (v) => !!v || "Last Name is required",
        (v) => /^[a-z ,.'-]+$/i.test(v) || "Please enter last name",
      ],
      groupRules: [(v) => !!v || "User Type is required"],
    };
  },
  async mounted() {
    if (!this.user.self_register) {
      this.getParties();
      this.getRoles();
    } else {
      this.loadingParties = false;
      this.loadingRoles = false;
    }
  },
  methods: {
    Save() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.user.phone_number =
          "+1" + this.user.temp_number.replace(/[- )(]/g, "");
        if (this.user.group !== "Party") {
          this.user.party_id = "";
        }
        this.$emit("eventname", this.user);
        this.valid = true;
      }
    },
    Cancel() {
      this.$router.push(
        this.self_register ? { name: "Login" } : { name: "UserManagement" }
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async getParties() {
      try {
        let parties = await api.get("/users/parties");
        this.parties = parties.map((party) => {
          return { text: party.name, value: party.party_id.toString() };
        });
      } catch (error) {
        console.log(error);
      }
      this.loadingParties = false;
    },
    async getRoles() {
      try {
        let roles = await api.get("/users/roles");
        this.roles = roles.map((role) => {
          return { text: role.name, value: role.role_id.toString() };
        });
      } catch (error) {
        console.log(error);
      }
      this.loadingRoles = false;
    },
    setGroupName(newUserValue) {
      if (this.roles.length > 0) {
        const roleName = this.roles.filter((r) => {
          return r.value === newUserValue.role_id;
        })[0].text;
        this.user.group = roleName;
      }
    },
  },
  watch: {
    user: {
      handler(val) {
        this.setGroupName(val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-form {
  margin-top: 60px;
  border-radius: 0px;
  background: #feffff;
  box-shadow: 5px 5px 7px #f1f2f2, -5px -5px 7px #ffffff;
  width: 80%;
}
.consent {
  color: #e53935;
}
@media only screen and (max-width: 959px) {
  .v-form {
    width: 100%;
  }
}
</style>
