<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-form ref="form" v-model="valid" class="form mt-3">
      <v-row>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            :disabled="disabledInputField"
            v-model="load.bookingNumber"
            label="Booking #"
            required
            :rules="requiredRules"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            v-model="load.partyAccountId"
            :items="partyAccount"
            label="Party Account"
            required
            :rules="requiredRules"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledShipperField"
            label="SSL"
            :items="
              steamShipLine.filter(
                (shipper) => shipper.active || shipper.value === load.shipperId
              )
            "
            item-text="text"
            item-value="value"
            required
            :rules="requiredRules"
            v-model="load.shipperId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            label="Commodity"
            :items="commodity"
            item-text="text"
            item-value="value"
            required
            :rules="requiredRules"
            v-model="load.commodityId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            label="Container Type"
            :items="
              containerType.filter(
                (containerType) =>
                  !hasContainersAssignedOrLoaded ||
                  (load.containerTypeId === 2 &&
                    ['40HQ', '40STD'].includes(containerType.text)) ||
                  load.containerTypeId === containerType.value
              )
            "
            item-text="text"
            item-value="value"
            v-model="load.containerTypeId"
            required
            :rules="requiredRules"
            v-on:change="setMaxWeight"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            :disabled="disabledInputField"
            v-model="load.MaxWeight"
            label="Max Container Weight"
            required
            :rules="[numberRule, requiredRules]"
            outlined
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            v-model="load.quantity"
            :disabled="load.InvoicingStatusId === 96"
            label="Container quantity"
            required
            :rules="[numberRule, requiredRules, quantity]"
            outlined
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-menu
            :disabled="disabledInputField"
            v-model="datePickerTwo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabledInputField"
                v-model="load.earliestReleaseDate"
                label="ERD Date"
                prepend-icon="mdi-calendar"
                required
                :rules="requiredRules"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="load.earliestReleaseDate"
              @input="datePickerTwo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-menu
            :disabled="disabledInputField"
            v-model="datePickerOne"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="disabledInputField"
                v-model="load.cutDate"
                label="Cut Date"
                prepend-icon="mdi-calendar"
                required
                :rules="requiredRules"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="load.cutDate"
              @input="datePickerOne = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            label="Destination"
            :items="destination"
            :loading="isLoading"
            item-text="text"
            item-value="value"
            v-model="load.destinationId"
            required
            :rules="requiredRules"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            label="Sample Destination Comment"
            :items="destinationComments"
            :loading="isLoading"
            required
            :rules="requiredRules"
            item-text="text"
            item-value="value"
            v-model="load.SampleDestinationCommentId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            label="Sample Requirements Comment"
            :items="sampleComments"
            required
            :rules="requiredRules"
            :loading="isLoading"
            item-text="text"
            item-value="value"
            v-model="load.SampleRequirementCommentId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" md="2" lg="2" xl="2">
          <v-autocomplete
            :disabled="disabledInputField"
            v-model="load.fumigateFlag"
            :items="fumigation"
            label="Fumigation"
            required
            :rules="requiredRules"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            :disabled="disabledInputField"
            v-model="load.comments"
            label="Comments"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="4" xl="4" class="d-flex justify-center">
          <v-col cols="6" md="3" lg="3" xl="3">
            <v-switch
              :disabled="disabledInputField"
              v-model="load.linersFlag"
              label="Moldex"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="5" lg="5" xl="5">
            <v-switch
              :disabled="disabledInputField"
              v-model="load.sampleFlag"
              label="Additional Sample"
            ></v-switch>
          </v-col>
          <v-col cols="6" md="3" lg="3" xl="3">
            <v-switch
              :disabled="disabledInputField"
              v-model="load.prioritizeFlag"
              label="Prioritize"
            ></v-switch>
          </v-col>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="3"
        lg="1"
        xl="1"
        class="d-flex justify-center mt-4"
        v-if="!isNew"
      >
        <v-btn
          @click="CancelLoad"
          :loading="isLoading"
          :disabled="disabledInputField"
          color="secondary"
          class="mr-2 ml-2 mt-3 text--primary"
        >
          Cancel Booking
        </v-btn>
      </v-col>
      <v-col cols="12" md="3" lg="2" xl="2" class="d-flex justify-center mt-4">
        <v-btn
          @click="SaveLoad"
          :disabled="disableUpdateButton"
          :loading="isLoading"
          color="secondary"
          class="mr-2 ml-2 mt-3 text--primary"
        >
          {{
            this.$route.params && this.$route.params.loadId
              ? "Update Booking"
              : "Create Booking"
          }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const api = require("@/api/api.js");
import store from "@/store/index.js";
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "Load",

  components: {},
  data() {
    return {
      search: "",
      calories: "",
      data: [],
      load: { liners: false, sample: false, prioritize: false },
      loading: false,
      datePickerOne: false,
      datePickerTwo: false,
      requiredRules: [(v) => !!v || "Field is required"],
      valid: false,
      isError: false,
      alertMessage: "",
      isNew: true,
      steamShipLine: [],
      containerType: [],
      commodity: [],
      destination: [],
      destinationComments: [],
      sampleComments: [],
      fumigation: [],
      partyAccount: [],
      fieldsLoading: false,
      isAdmin: false,
      numberRule: (val) => {
        if (val < 0) {
          return "Number must be postive.";
        }
        return true;
      },
      quantity: (val) => {
        if (
          val <
          (this.load.ContainersAssigned || 0) +
            (this.load.ContainersLoaded || 0)
        ) {
          return "Number can't be less then assigned containers.";
        }
        return true;
      },
    };
  },
  computed: {
    disabledInputField() {
      return (
        isUserAllowed(["Admin", "Master"]) || this.load.InvoicingStatusId === 96
      );
    },
    disabledShipperField() {
      return this.disabledInputField || this.hasContainersAssignedOrLoaded;
    },
    disableUpdateButton() {
      return !this.valid || this.load.InvoicingStatusId === 96;
    },
    hasContainersAssignedOrLoaded() {
      return (
        (this.load.ContainersAssigned || 0) +
          (this.load.ContainersLoaded || 0) >
        0
      );
    },
    isLoading() {
      return this.loading || this.fieldsLoading;
    },
  },
  mounted() {
    this.GetBookingFields();
    this.isAdmin = store.getters.getAdminStatus;
    if (this.$route.params && this.$route.params.loadId) {
      this.isNew = false;
      this.GetLoad(this.$route.params.loadId);
    }
  },
  methods: {
    isUserAllowed,
    async GetLoad(loadId) {
      try {
        this.load = await api.get("/load/" + loadId);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async SaveLoad() {
      this.loading = true;
      try {
        if (this.isNew) {
          await api.post("/load", this.load);
        } else {
          await api.put("/load", this.load);
        }
        this.loading = false;
        this.goToSummary();
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to create load. Please try again.";
        console.log(err);
      }
    },
    async CancelLoad() {
      this.loading = true;
      try {
        await api.put("/load/cancel", this.load);
        this.loading = false;
        this.goToSummary();
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to create load. Please try again.";
        console.log(err);
      }
    },
    async GetBookingFields() {
      try {
        this.fieldsLoading = true;
        let fields = await api.get("/load/bookingfields");
        this.steamShipLine = fields.steamShipLine.map((value) => {
          return {
            text: value.name,
            value: value.shipperId,
            active: value.Active,
          };
        });
        this.containerType = fields.containerType.map((value) => {
          return { text: value.name, value: value.containerTypeId };
        });
        this.commodity = fields.commodity.map((value) => {
          return { text: value.name, value: value.commodityId };
        });
        this.destination = fields.destination.map((value) => {
          return { text: value.description, value: value.destinationId };
        });
        this.fumigation = fields.fumigation.map((value) => {
          return { text: value.description, value: value.fumigationTypeId };
        });
        this.partyAccount = fields.partyAccount.map((value) => {
          return { text: value.name, value: value.partyAccountId };
        });
        this.destinationComments = fields.sampleDestinationComments.map(
          (value) => {
            return { text: value.Name, value: value.CommentId };
          }
        );
        this.sampleComments = fields.sampleRequirementComments.map((value) => {
          return { text: value.Name, value: value.CommentId };
        });
        this.fieldsLoading = false;
      } catch (err) {
        this.fieldsLoading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    fetchDestinationDebounced(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.getDestinations(val);
      }, 500);
    },
    async setMaxWeight() {
      let container = this.containerType.filter(
        (container) => container.value === this.load.containerTypeId
      )[0];
      if (container.text && container.text.includes("20")) {
        this.load.MaxWeight = 48000;
      } else if (container.text && container.text.includes("40")) {
        this.load.MaxWeight = 51900;
      }
    },
    async getDestinations(val) {
      try {
        this.loading = true;
        let destinations = await api.get("/load/destinations?search=" + val);
        debugger;
        this.destination = destinations.map((value) => {
          return { text: value.Name, value: value.DestinationId };
        });
        console.log(this.destination);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          "Failed to retrieve destination data. Please try again.";
        console.log(err);
      }
    },
    goToSummary() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.form {
  padding: 10px;
  background: #ffffff;
}
.submit-button {
  width: 90%;
}
.v-input--switch .v-input--switch__track {
  color: #424242 !important;
}
</style>
