<template>
  <v-card
    class="d-flex justify-center mb-12 login-card"
    flat
    tile
    height="100%"
  >
    <v-card tile :class="`align-self-center elevation-0`">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="registration-form"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <h1>G4 Transload Login</h1>
              <h2>Pick a new password.</h2>
              <p>Enter a new password below to use in G4 Transload.</p>
            </v-col>
            <v-alert dismissible border="left" type="error" v-model="isError">
              {{ errorMessage }}
            </v-alert>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="userPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  rules.required,
                  rules.min,
                  rules.digit,
                  rules.capital,
                  rules.special,
                ]"
                validate-on-blur
                :type="show ? 'text' : 'password'"
                label="New Password"
                outlined
                class="mb-6"
                @click:append="show = !show"
              ></v-text-field>
              <div style="margin-top: -35px; margin-bottom: 25px">
                <p style="font-style: italic; font-size: 13px">
                  Password should contain at least one uppercase letter, one
                  lower case letter, a digit, and a symbol.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm New Password"
                outlined
                required
                clearable
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :error-messages="passwordMatch()"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <p class="consent">
                <v-icon large height="100%" class="consent"
                  >mdi-message-processing</v-icon
                >
                By creating a user account you consent to receiving SMS messages
                for multifactor authentication purposes.
              </p>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                :disabled="!valid"
                color="secondary"
                class="mr-6 text--primary"
                @click="validate"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "SelfRegistration",
  props: {
    sessionUser: {
      type: Object,
      required: true,
    },
    systemPassword: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    show: false,
    isError: false,
    userPassword: "",
    confirmPassword: "",
    errorMessage: "",
    rules: {
      required: (value) => !!value || "Enter a password",
      min: (v) => v.length >= 8 || "Use 8 characters or more for your password",
      digit: (v) => (v && /\d/.test(v)) || "Please at least one digit",
      capital: (v) =>
        (v && /[A-Z]{1}/.test(v)) || "Please use at least one capital letter",
      special: (v) =>
        (v && /[^A-Za-z0-9]/.test(v)) ||
        "Please use at least one special character",
    },
  }),
  mounted() {},
  computed: {},
  methods: {
    async validate() {
      this.$refs.form.validate();
      try {
        await Auth.completeNewPassword(this.sessionUser, this.userPassword, {
          name: this.sessionUser.username,
        }).then((cognitoUser) => {
          this.sessionUser = cognitoUser;
        });
      } catch (err) {
        console.log("ERROR RECEIVED");
        this.errorMessage = err.message;
        this.isError = true;
        this.loading = false;
        throw err;
      }

      await Auth.rememberDevice();
      await Auth.setPreferredMFA(this.sessionUser, "SMS");
      await Auth.signOut({ global: true });
      this.$emit("eventname");
    },
    passwordMatch() {
      return this.userPassword === this.confirmPassword
        ? ""
        : "Password must match";
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.consent {
  color: #e53935;
}
.registration-form {
  width: 100%;
}
label {
  font-size: 13px;
}
</style>
