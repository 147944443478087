var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex justify-center mb-12 login-card",
      attrs: { flat: "", tile: "", height: "100%" },
    },
    [
      _c(
        "v-card",
        { class: `align-self-center elevation-0`, attrs: { tile: "" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validate.apply(null, arguments)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "text-center" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("img", {
                          staticClass: "text-center mt-4",
                          attrs: {
                            src: require("../../assets/img_e_brand_up_color_png.png"),
                            contain: "",
                            height: "100",
                          },
                        }),
                        _c("div", [_vm._v("Powered By JCT")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      !_vm.emailSent
                        ? _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                            _c("h2", [_vm._v("Forgot Password?")]),
                            _c("p", [
                              _vm._v(
                                "Enter the email associated with your G4 Transload account."
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.emailSent
                        ? _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                            _c("h2", [_vm._v("Reset Password")]),
                            _c("p", [
                              _vm._v(
                                " Enter a new password below to use in G4 Transload. Please use the verification code sent to your E-mail. "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "v-alert",
                        {
                          staticClass: "message",
                          attrs: {
                            dismissible: "",
                            border: "left",
                            type: "error",
                          },
                          model: {
                            value: _vm.isError,
                            callback: function ($$v) {
                              _vm.isError = $$v
                            },
                            expression: "isError",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      ),
                      _c(
                        "v-alert",
                        {
                          staticClass: "message",
                          attrs: {
                            dismissible: "",
                            border: "left",
                            type: "success",
                          },
                          model: {
                            value: _vm.isSuccess,
                            callback: function ($$v) {
                              _vm.isSuccess = $$v
                            },
                            expression: "isSuccess",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.emailRules,
                              label: "E-mail",
                              required: "",
                              outlined: "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.emailSent
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-6",
                                attrs: {
                                  "append-icon": _vm.show
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  rules: [
                                    _vm.passwordRules.required,
                                    _vm.passwordRules.min,
                                    _vm.passwordRules.digit,
                                    _vm.passwordRules.capital,
                                    _vm.passwordRules.special,
                                  ],
                                  "validate-on-blur": "",
                                  type: _vm.show ? "text" : "password",
                                  label: "New Password",
                                  required: "",
                                  outlined: "",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.show = !_vm.show
                                  },
                                },
                                model: {
                                  value: _vm.userPassword,
                                  callback: function ($$v) {
                                    _vm.userPassword = $$v
                                  },
                                  expression: "userPassword",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-25px",
                                    "margin-bottom": "25px",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "font-style": "italic",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Password should contain at least one uppercase letter, one lower case letter, a digit, and a symbol. "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.emailSent
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Confirm New Password",
                                  rules: [_vm.passwordRules.required],
                                  required: "",
                                  outlined: "",
                                  clearable: "",
                                  "validate-on-blur": "",
                                  "append-icon": _vm.show
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.show ? "text" : "password",
                                  "error-messages": _vm.passwordMatch(),
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.show = !_vm.show
                                  },
                                },
                                model: {
                                  value: _vm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.confirmPassword = $$v
                                  },
                                  expression: "confirmPassword",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.emailSent
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    _vm.codeRules.required,
                                    _vm.codeRules.min,
                                    _vm.codeRules.digit,
                                  ],
                                  label: "Validation Code Found in E-mail",
                                  required: "",
                                  outlined: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.code,
                                  callback: function ($$v) {
                                    _vm.code = $$v
                                  },
                                  expression: "code",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.emailSent
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "submit white--text",
                                  attrs: { color: "info" },
                                  on: { click: _vm.haveVerificationCode },
                                },
                                [
                                  _vm._v(
                                    " I already have the verification code "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.emailSent
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "submit white--text",
                                  attrs: { color: "primary" },
                                  on: { click: _vm.cancel },
                                },
                                [_vm._v(" Back to Login ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          !_vm.emailSent
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "submit text--primary",
                                  attrs: {
                                    color: "secondary",
                                    type: "submit",
                                    disabled: !_vm.valid,
                                  },
                                },
                                [_vm._v(" Send Validation Email ")]
                              )
                            : _vm._e(),
                          _vm.emailSent
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "submit text--primary",
                                  attrs: {
                                    color: "secondary",
                                    type: "submit",
                                    disabled: !_vm.valid,
                                  },
                                },
                                [_vm._v(" Reset Password ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }