var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "mb-2",
      attrs: { elevation: "2", outlined: "", height: "100%" },
    },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.inboundDate))]),
      _c(
        "v-row",
        [
          _c(
            "v-card-text",
            [
              _vm._v(" Orders "),
              _c("v-icon", { attrs: { large: "", height: "100%" } }, [
                _vm._v("mdi-format-align-left"),
              ]),
              _c(
                "v-card",
                { attrs: { elevation: "2", outlined: "", tile: "" } },
                [
                  _vm._l(_vm.orders, function (order) {
                    return [
                      _c("v-card-text", { key: order.containerNo }, [
                        _vm._v(
                          " " +
                            _vm._s(order.orderNo) +
                            " - " +
                            _vm._s(order.orderTime) +
                            " "
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-card-text",
            [
              _vm._v(" Containers "),
              _c("v-icon", { attrs: { large: "", height: "100%" } }, [
                _vm._v("mdi-train"),
              ]),
              _c(
                "v-card",
                {
                  staticClass: "mb-2",
                  attrs: { elevation: "2", outlined: "", tile: "" },
                },
                [
                  _vm._l(_vm.containers, function (container) {
                    return [
                      _c("v-card-text", { key: container.containerNo }, [
                        _vm._v(
                          " " +
                            _vm._s(container.containerNo) +
                            " - " +
                            _vm._s(container.containerTime) +
                            " "
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }