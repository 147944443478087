var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex justify-center mb-12 login-card",
      attrs: { flat: "", tile: "", height: "100%" },
    },
    [
      _c(
        "v-card",
        { class: `align-self-center elevation-0`, attrs: { tile: "" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "registration-form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                        _c("h1", [_vm._v("G4 Transload Login")]),
                        _c("h2", [_vm._v("Pick a new password.")]),
                        _c("p", [
                          _vm._v(
                            "Enter a new password below to use in G4 Transload."
                          ),
                        ]),
                      ]),
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            border: "left",
                            type: "error",
                          },
                          model: {
                            value: _vm.isError,
                            callback: function ($$v) {
                              _vm.isError = $$v
                            },
                            expression: "isError",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mb-6",
                            attrs: {
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.min,
                                _vm.rules.digit,
                                _vm.rules.capital,
                                _vm.rules.special,
                              ],
                              "validate-on-blur": "",
                              type: _vm.show ? "text" : "password",
                              label: "New Password",
                              outlined: "",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show = !_vm.show
                              },
                            },
                            model: {
                              value: _vm.userPassword,
                              callback: function ($$v) {
                                _vm.userPassword = $$v
                              },
                              expression: "userPassword",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "-35px",
                                "margin-bottom": "25px",
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-style": "italic",
                                    "font-size": "13px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Password should contain at least one uppercase letter, one lower case letter, a digit, and a symbol. "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Confirm New Password",
                              outlined: "",
                              required: "",
                              clearable: "",
                              "append-icon": _vm.show
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.show ? "text" : "password",
                              "error-messages": _vm.passwordMatch(),
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.show = !_vm.show
                              },
                            },
                            model: {
                              value: _vm.confirmPassword,
                              callback: function ($$v) {
                                _vm.confirmPassword = $$v
                              },
                              expression: "confirmPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "12" } }, [
                        _c(
                          "p",
                          { staticClass: "consent" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "consent",
                                attrs: { large: "", height: "100%" },
                              },
                              [_vm._v("mdi-message-processing")]
                            ),
                            _vm._v(
                              " By creating a user account you consent to receiving SMS messages for multifactor authentication purposes. "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-6 text--primary",
                              attrs: {
                                disabled: !_vm.valid,
                                color: "secondary",
                              },
                              on: { click: _vm.validate },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }