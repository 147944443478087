<template>
  <v-app>
    <navbar />
    <v-main>
      <v-container>
        <h1>Website Terms of Use</h1>
        <p>Last Modified: March 1, 2022</p>

        <p class="header">Acceptance of the Terms of Use</p>

        <p>
          These terms of use are entered into by and between You and Joilet
          Container Terminal, LLC and its affiliates ("<b>Company</b>,"
          "<b>we</b>," or "<b>us</b>"). The following terms and conditions,
          together with any documents they expressly incorporate by reference
          (collectively, "<b>Terms of Use</b>"), govern your access to and use
          of www.g4transload.com including any content, functionality and
          services offered on or through www.g4transload.com (the
          "<b>Website</b>"), whether as a guest or a registered user.
        </p>

        <p>
          Please read the Terms of Use carefully before you start to use the
          Website.
          <b
            >By using the Website, you accept and agree to be bound and abide by
            these Terms of Use and our Privacy Policy, found at
            <a href="#privacy"><u>https://www.g4transload.com/tos</u></a
            >, incorporated herein by reference</b
          >. If you do not want to agree to these Terms of Use or the Privacy
          Policy, you must not access or use the Website.
        </p>

        <p class="header">Changes to the Terms of Use</p>

        <p>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them, and apply to all access to and use of the Website thereafter.
        </p>

        <p>
          Your continued use of the Website following the posting of revised
          Terms of Use means that you accept and agree to the changes. You are
          expected to check this page each time you access this Website so you
          are aware of any changes, as they are binding on you.
        </p>

        <p class="header">Accessing the Website and Account Security</p>

        <p>
          We reserve the right to withdraw or amend this Website, and any
          service or material we provide on the Website, in our sole discretion
          without notice. We will not be liable if for any reason all or any
          part of the Website is unavailable at any time or for any period. From
          time to time, we may restrict access to some parts of the Website, or
          the entire Website, to users, including registered users.
        </p>

        <p>You are responsible for both:</p>
        <ul>
          <li>
            Making all arrangements necessary for you to have access to the
            Website.
          </li>

          <li>
            Ensuring that all persons who access the Website through your
            internet connection are aware of these Terms of Use and comply with
            them.
          </li>
        </ul>
        <br />
        <p>
          To access the Website or some of the resources it offers, you may be
          asked to provide certain registration details or other information. It
          is a condition of your use of the Website that all the information you
          provide on the Website is correct, current and complete. You agree
          that all information you provide to register with this Website or
          otherwise, including, but not limited to, through the use of any
          interactive features on the Website, is governed by our Privacy
          Policy, and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy.
        </p>

        <p>
          If you choose, or are provided with, a user name, password or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to this Website or portions of it using your user name,
          password or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your user name or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </p>

        <p>
          We have the right to disable any user name, password or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>

        <p class="header">Intellectual Property Rights</p>

        <p>
          The Website and its entire contents, features and functionality
          (including but not limited to all trademarks, information, software,
          text, names, logos, service names, designs, slogans, displays, images,
          video and audio, and the design, selection and arrangement thereof),
          are owned by the Company, its licensors or other providers of such
          material and are protected by United States and international
          copyright, trademark, patent, trade secret and other intellectual
          property or proprietary rights laws.
        </p>

        <p>
          These Terms of Use permit you to use the Website for your personal,
          non-commercial use only, other than for the limited business purposes
          that may be agreed to by the Company and you. Except as may be
          otherwise mutually agreed to between you and the Company, you must not
          reproduce, distribute, modify, create derivative works of, publicly
          display, publicly perform, republish, download, store or transmit any
          of the material on our Website, except as follows:
        </p>
        <ul>
          <li>
            Your computer may temporarily store copies of such materials in its
            memory incidental to your accessing and viewing those materials.
          </li>

          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>

          <li>
            You may print or download one copy of a reasonable number of pages
            of the Website for your own personal, non-commercial use and not for
            further reproduction, publication or distribution.
          </li>
        </ul>
        <br />
        <p>You must not:</p>
        <ul>
          <li>Modify copies of any materials from this site.</li>

          <li>
            Use any illustrations, photographs, video or audio sequences or any
            graphics separately from the accompanying text.
          </li>

          <li>
            Delete or alter any copyright, trademark or other proprietary rights
            notices from copies of materials from this site.
          </li>
        </ul>
        <br />
        <p>
          If you print, copy, modify, download or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use or other written agreement with the Company, your right
          to use the Website will cease immediately and you must, at our option,
          return or destroy any copies of the materials you have made. No right,
          title or interest in or to the Website or any content on the Website
          is transferred to you, and all rights not expressly granted are
          reserved by the Company. Any use of the Website not expressly
          permitted by these Terms of Use is a breach of these Terms of Use and
          may violate copyright, trademark and other laws.
        </p>

        <p class="header">Prohibited Uses</p>

        <p>
          You may use the Website only for lawful purposes and in accordance
          with these Terms of Use. You agree not to use the Website:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the US or
            other countries).
          </li>

          <li>
            For the purpose of exploiting, harming or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information or otherwise.
          </li>

          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>

          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user or any other person or entity (including,
            without limitation, by using e-mail addresses associated with any of
            the foregoing).
          </li>

          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Website, or which, as determined by us, may
            harm the Company or users of the Website, or expose them to
            liability.
          </li>
        </ul>
        <br />
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party's use
            of the Website, including their ability to engage in real time
            activities through the Website.
          </li>

          <li>
            Use any robot, spider or other automatic device, process or means to
            access the Website for any purpose, including monitoring or copying
            any of the material on the Website.
          </li>

          <li>
            Use any manual process to monitor or copy any of the material on the
            Website, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>

          <li>
            Use any device, software or routine that interferes with the proper
            working of the Website.
          </li>

          <li>
            Introduce any viruses, trojan horses, worms, logic bombs or other
            material which is malicious or technologically harmful.
          </li>

          <li>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer or database connected to the
            Website.
          </li>

          <li>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>

          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <br />
        <p class="header">Reliance on Information Posted</p>

        <p>
          The information presented on or through the Website is made available
          solely for general information purposes. We do not warrant the
          accuracy, completeness or usefulness of this information. Any reliance
          you place on such information is strictly at your own risk. We
          disclaim all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Website,
          or by anyone who may be informed of any of its contents.
        </p>

        <p>
          This Website may include content provided by third parties, including
          materials provided by other users, bloggers and third-party licensors,
          syndicators, aggregators and/or reporting services. All statements
          and/or opinions expressed in these materials, and all articles and
          responses to questions and other content, other than the content
          provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>

        <p class="header">Changes to the Website</p>

        <p>
          We may update the content on this Website from time to time, but its
          content is not necessarily complete or up-to-date. Any of the material
          on the Website may be out of date at any given time, and we are under
          no obligation to update such material.
        </p>

        <p class="header">
          Information About You and Your Visits to the Website
        </p>

        <p>
          All information we collect on this Website is subject to our Privacy
          Policy. By using the Website, you consent to all actions taken by us
          with respect to your information in compliance with the Privacy
          Policy.
        </p>

        <p class="header">Links from the Website</p>

        <p>
          If the Website contains links to other sites and resources provided by
          third parties, these links are provided for your convenience only.
          This includes links contained in advertisements, including banner
          advertisements and sponsored links. We have no control over the
          contents of those sites or resources, and accept no responsibility for
          them or for any loss or damage that may arise from your use of them.
          If you decide to access any of the third party websites linked to this
          Website, you do so entirely at your own risk and subject to the terms
          and conditions of use for such websites.
        </p>

        <p class="header">Disclaimer of Warranties</p>

        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
        </p>

        <p>
          YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
          CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
          PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY
          NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
          REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT
          LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH
          THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE,
          RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
          THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
          VIRUSES OR OTHER HARMFUL
        </p>

        <p>
          COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
          THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>

        <p>
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE.
        </p>

        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <p class="header">Limitation on Liability</p>

        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER
          ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
          INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
          ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>

        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <p class="header">Indemnification</p>

        <p>
          You agree to defend, indemnify and hold harmless the Company, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys' fees) arising out of
          or relating to your violation of these Terms of Use or your use of the
          Website, including, but not limited to, any use of the Website's
          content, services and products other than as expressly authorized in
          these Terms of Use, or your use of any information obtained from the
          Website.
        </p>

        <p class="header">Governing Law and Jurisdiction</p>

        <p>
          All matters relating to the Website and these Terms of Use, and any
          dispute or claim arising therefrom or related thereto (in each case,
          including non-contractual disputes or claims), shall be governed by
          and construed in accordance with the internal laws of the State of
          Nebraska without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Nebraska or any other
          jurisdiction).
        </p>

        <p>
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Use or the Website shall be instituted exclusively in
          the state or federal courts in Douglas County, Nebraska although we
          retain the right to bring any suit, action or proceeding against you
          for breach of these Terms of Use in your country of residence or any
          other relevant country. You waive any and all objections to the
          exercise of jurisdiction over you by such courts and to venue in such
          courts.
        </p>

        <p class="header">Arbitration</p>

        <p>
          At Company's sole discretion, it may require You to submit any
          disputes arising from these Terms of Use or use of the Website,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance, or termination, to final and
          binding arbitration under the Rules of Arbitration of the American
          Arbitration Association applying Nebraska law.
        </p>

        <p class="header">Limitation on Time to File Claims</p>

        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>

        <p class="header">Waiver and Severability</p>

        <p>
          No waiver by the Company of any term or condition set forth in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>

        <p>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>

        <p class="header">Entire Agreement</p>

        <p>
          The Terms of Use and our Privacy Policy constitute the sole and entire
          agreement between you and the Company with respect to the Website and
          supersede all prior and contemporaneous understandings, agreements,
          representations and warranties, both written and oral, with respect to
          the Website.
        </p>

        <p class="header">Your Comments and Concerns</p>

        <p>This website is operated by Joilet Container Terminal, LLC.</p>

        <p>
          All other feedback, comments, requests for technical support and other
          communications relating to the Website should be directed to:
          <a href="mailto:privacy-us@viterra.com" rel="nofollow"
            ><u>privacy-us@viterra.com</u></a
          >
        </p>
      </v-container>
      <hr />
      <v-container id="privacy">
        <p>Effective: March 1, 2022</p>

        <h1>PRIVACY POLICY</h1>

        <p>
          Joilet Container Terminal, LLC (“<b>JCL</b>”, “<b>We</b>” or
          “<b>Our</b>”) respects your privacy and is committed to protecting it
          through our compliance with this privacy policy (“<b>Privacy Policy</b
          >”).
        </p>

        <p>
          This Privacy Policy applies to your interaction and communication with
          JCL, including in person, by phone, through mail or email or through
          the website www.g4transload.com and other JCL-owned and/or operated
          websites, mobile sites, mobile applications or social media account
          (collectively, our “<b>Sites</b>”) and our practices for collecting,
          using, maintaining, protecting and disclosing that information.
          <b
            >By accessing or using our Sites or otherwise disclosing information
            to JCL, you consent to our collection, use, and sharing of your
            information in accordance with this Privacy Policy</b
          >.
        </p>

        <p>
          If you have questions about our Privacy Policy, our use of your
          information, or if you would like to change how we communicate with
          you, email
          <a href="mailto:privacy-us@viterra.com" rel="nofollow"
            ><u>privacy-us@viterra.com</u></a
          >.
        </p>

        <p class="header">WHAT INFORMATION DO WE COLLECT?</p>

        <p>
          <b><i>Information You Provide Directly to Us</i></b>
        </p>

        <p>
          We collect personal information you provide directly to us, for
          example, when you provide or access scheduling or delivery
          information, fill out a form or registration on our site, or request
          to be contacted by us. The types of information we may collect
          include:
        </p>
        <ul>
          <li>
            <b>Account Information</b>. When you register for an account on the
            Sites, you will need to provide information. You will need to
            provide certain personal information to complete the registration,
            which may include your name, email address, mailing address,
            username, password, and phone number. Other information may be
            collected as part of the registration process and the administration
            and personalization of your user experience.
          </li>

          <li>
            <b>Transaction Information</b>. We may collect information relating
            to your use of the Sites, for example, schedule or delivery
            information reviewed or inputted into the Site.
          </li>

          <li>
            <b>Your Requests</b>. We may collect personal information from you
            when you fill out a form for a specific request or otherwise choose
            to provide information through a “contact us” feature, for example,
            your name, address, email and phone number.
          </li>
        </ul>
        <p>
          <b><i>Information We Collect Automatically</i></b>
        </p>

        <p>
          When you access or use the Sites, the types of information we may
          automatically collect about you include:
        </p>
        <ul>
          <li>
            <b>Usage Information</b>. We may collect information about your
            usage of, and interaction with the Sites. For example, we may
            collect and store statistics and other information about the online
            activities of our users on an aggregated (collective) basis,
            including tracking the number of people who view each page to learn
            which features are the most popular.
          </li>

          <li>
            <b>Log Information</b>. When you access or use the Sites, our
            servers may automatically record certain log file information, such
            as your Internet Protocol (“IP”) address, operating system, browser
            type and language, referring URLs, access times, pages viewed, links
            clicked and other information about your activities on the Site.
          </li>

          <li>
            <b>Information Collected by Cookies</b>. We and our service
            providers use various tracking technologies, including cookies, to
            collect information about you when you interact with our Sites,
            including information about your browsing and purchasing behavior.
            Cookies are small data files stored on your hard drive or in device
            memory that help us improve the Sites and your experience, see which
            areas and features of the Sites are popular and count visits. Most
            web browsers are set to accept cookies by default. If you prefer,
            you can usually choose to set your browser to remove or reject
            browser cookies. Please note that if you choose to remove or reject
            cookies, this could affect the availability and functionality of the
            Sites.
          </li>
        </ul>
        <p>
          <b><i>Information We Collect From Third Parties</i></b>
        </p>

        <p>
          We may receive information that third parties provide us, which may
          include information about you. This Privacy Policy does not apply to
          information collected through or by any third party. If you have any
          questions about such third parties’ privacy practices, you should
          review their privacy policies.
        </p>

        <p class="header">HOW DO WE USE COLLECTED INFORMATION?</p>

        <p>
          We may use your information for various purposes related to our
          operations and your use of the Sites. For example, we may use your
          information to:
        </p>
        <ul>
          <li>
            Provide, maintain and improve our Sites and provide you with
            relevant information and content.
          </li>

          <li>
            Send you technical notices, updates, security alerts and support and
            administrative messages.
          </li>

          <li>
            Respond to your comments, questions and requests and provide
            customer service.
          </li>

          <li>
            Monitor and analyze trends, usage and activities in connection with
            our Sites.
          </li>

          <li>
            Detect, investigate and prevent fraudulent transactions and other
            illegal activities and protect the rights and property of JCL and
            others.
          </li>

          <li>
            Maintain appropriate records for internal administrative purposes.
          </li>

          <li>
            To comply with legal and regulatory requirements, required
            disclosures and choices and controls that may be available.
          </li>

          <li>To personalize online content and user experiences.</li>

          <li>
            To perform operational functions in connection with our business,
            including data analysis, audits, developing and improving products
            and services, enhancing the Sites and identifying usage trends.
          </li>

          <li>
            To deliver marketing materials or other advertising content to you.
          </li>

          <li>
            Link or combine with information we get from others to help
            understand your needs and provide you with a better experience.
          </li>

          <li>To diagnose problems with our services.</li>

          <li>
            Carry out any other purpose described to you at the time the
            information was collected.
          </li>
        </ul>
        <p>
          Aggregated and de-identified information that cannot reasonably be
          used to identify an individual, does not constitute personal
          information. Such de-identified and/or aggregated information which
          does not identify individuals is not subject to this Privacy Policy.
        </p>

        <p>
          We may keep your information for as long as is necessary for our
          business purposes and to protect ourselves in the event of a legal
          claim.
        </p>
        <p class="header">WHO DO WE SHARE INFORMATION WITH?</p>

        <p>
          We may share information about you as follows or as otherwise
          described in this Privacy Policy:
        </p>
        <ul>
          <li>
            With affiliates, partners, vendors, consultants and other service
            providers who need access to such information to carry out work or
            perform services on our behalf.
          </li>

          <li>
            With third party businesses to provide you with information about
            products and services that may be useful to you.
          </li>

          <li>
            In response to a request for information if we believe disclosure is
            in accordance with, or required by, any applicable law, rule,
            regulation or legal process.
          </li>

          <li>
            If we believe your actions are inconsistent with our Terms of
            Services or other policies, or to protect the rights, property or
            safety of JCL or others.
          </li>

          <li>
            In connection with, or during negotiations of, any merger,
            acquisition, sale of assets or any business, other change of control
            transaction or financing.
          </li>

          <li>
            To comply with any court order, law or legal process, including to
            respond to any government or regulatory request.
          </li>

          <li>To enforce any agreement we may have with you.</li>

          <li>
            Between and among JCL and any current or future parent, subsidiary
            and/or affiliated company.
          </li>

          <li>With your consent or at your direction.</li>
        </ul>
        <p>
          We also may share aggregated or de-identified information, which
          cannot reasonably be used to identify you and is not subject to this
          Privacy Policy.
        </p>

        <p class="header">ACCESSING AND CORRECTING YOUR INFORMATION</p>

        <p>
          You can review and change your personal information by logging into
          our Site and visiting your account profile page. Site account users
          may delete their user profile information by deleting their account.
        </p>
        <p class="header">CHILDREN UNDER THE AGE OF 13</p>

        <p>
          Our Site is not intended for children under 13 years of age. No one
          under age 13 may provide any personal information to or on the Site.
          We do not knowingly collect personal information from children under
          13. If we learn we have collected or received personal information
          from a child under 13 without verification of parental consent, we
          will delete that information.
        </p>

        <p class="header">THIRD-PARTY LINKS</p>

        <p>
          In an attempt to provide you with increased value, we may include
          third-party links on our Site. If you click on a third-party link, you
          will be directed to that third-party’s website. We have no control
          over these linked sites. Each of third-party site has its own separate
          and independent privacy policies and practices, including in
          connection with their use or disclosure of information you disclose to
          them. JCL is not responsible or liable for the content of any
          third-party site, even if these sites reflect our branding. We
          encourage you to check the privacy policies of third-party sites
          before providing your information to them. The fact that we may link
          to a third-party site or present a banner ad or other type of
          advertisement from a third party is not an endorsement, authorization,
          or representation of any affiliation by us with that third-party, nor
          is it an endorsement of their privacy or information security policies
          or practices.
        </p>

        <p class="header">THIRD-PARTY ANALYTICS SERVICES</p>

        <p>
          We may work with certain third parties to collect, analyze, and use
          some of the information described above. For example, we may allow
          third parties to set cookies or use web beacons on the Sites or in
          email communications from us. The information collected by third
          parties using these technologies may be used to engage in analysis and
          reporting. These third parties may set and access cookies on your
          computer or other device and may collect information about your online
          activities across different websites or services over time, including
          on websites and mobile applications that are not owned or operated by
          JCL. In particular, the Sites may use Google Analytics to help collect
          and analyze certain information for the purposes discussed above. You
          may opt-out of the use of cookies in web browsers by Google Analytics
          by visiting
          <a
            href="https://support.google.com/analytics/answer/181881?hl=en"
            rel="nofollow"
            ><u>Google’s website</u></a
          >.
        </p>

        <p class="header">DATA SECURITY</p>

        <p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration and disclosure. Our security measures include industry
          standard technology and equipment to help protect your personal
          information.
        </p>

        <p>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Site, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </p>

        <p>
          Unfortunately, the transmission of information via the Internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Site. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Site.
        </p>

        <p class="header">NOTICE TO INTERNATIONAL USERS</p>

        <p>
          The Sites are hosted in the United States and are subject to U.S. law,
          and are intended for users located only in the U.S. If you are
          accessing the Sites from outside the United States, please be advised
          that U.S. law may not offer the same privacy protections as the laws
          of your jurisdiction. By accessing and using the Sites, you consent to
          the transfer to and processing of your personal information in the
          U.S.
        </p>

        <p class="header">CHANGES TO OUR PRIVACY POLICY</p>

        <p>
          This Privacy Policy may change from time to time. It is our policy to
          post any changes we make to our Privacy Policy on this page. If we
          make material changes to how we treat our users' personal information,
          we will notify you through a notice on our Site’s home page. Your
          continued use of our Site after we make changes is deemed to be
          acceptance of those changes. The date this Privacy Policy was last
          revised is identified at the top of the page. You are responsible for
          periodically visiting our Site and this Privacy Policy to check for
          any changes.
        </p>

        <p class="header">CONTACT US</p>

        <p>
          Contact us for any questions or concerns you may have regarding this
          Privacy Policy and our information and data collection and use
          practices at
          <a href="mailto:privacy-us@viterra.com" rel="nofollow"
            ><u>privacy-us@viterra.com</u></a
          >.
        </p>

        <p class="header">ACCESSIBILITY</p>

        <p>
          We are committed to facilitating the accessibility of our Sites for
          all people with disabilities. To access this Privacy Policy in an
          alternative format, please contact us at
          <a href="mailto:privacy-us@viterra.com" rel="nofollow"
            ><u>privacy-us@viterra.com</u></a
          >.
        </p>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<script>
import Footer from "../components/Layout/Footer.vue";
import Navbar from "../components/Layout/Navbar.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.header {
  text-decoration: underline;
  font-weight: bold;
}
</style>
