<template>
  <div class="login-body">
    <v-app>
      <v-card
        v-if="!registration && !mfaValidation && !forgot"
        class="d-flex justify-center mb-12 login-card"
        tile
        height="100%"
      >
        <v-card tile :class="`align-self-center elevation-0`">
          <v-row class="text-center">
            <v-col cols="12">
              <img
                src="../assets/img_e_brand_up_color_png.png"
                height="100"
                class="text-center mt-5 mb-2"
              />
              <div>Powered By JCT</div>
            </v-col>
          </v-row>
          <v-alert dismissible border="left" type="error" v-model="isError">
            {{ errorMessage }}
          </v-alert>
          <v-alert dismissible border="left" type="success" v-model="isSuccess">
            {{ successMessage }}
          </v-alert>
          <v-form
            @submit.prevent="validate"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <h2>G4 Transload Login</h2>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    outlined
                    required
                    clearable
                    :rules="passwordRules"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <v-btn
                    :disabled="!valid"
                    :loading="loading"
                    color="secondary"
                    class="login text--primary"
                    type="submit"
                  >
                    Login
                  </v-btn>
                </v-col>
                <v-col cols="6" md="6">
                  <v-btn
                    class="login white--text"
                    color="primary"
                    @click="forgotPassword"
                  >
                    Forgot Password?
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn class="login" color="info" to="/register">
                    Sign up for your ingredient deliveries
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-card>
      <forgot-password
        v-if="forgot"
        @click="forgotPassword"
        v-on:forgotreset="passwordReset"
        v-on:back="backToLogin"
      >
      </forgot-password>
      <self-registration
        :sessionUser="sessionUser"
        :systemPassword="password"
        v-if="registration"
        @eventname="ResetLogin"
      ></self-registration>
      <MFAValidation
        :sessionUser="sessionUser"
        :mfaType="mfaType"
        v-if="mfaValidation"
      ></MFAValidation>
      <v-btn v-if="registration || mfaValidation || forgot" @click="ResetLogin">
        <v-icon>mdi-arrow-left</v-icon>Go Back to Login
      </v-btn>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import SelfRegistration from "../components/Login/SelfRegistration.vue";
import MFAValidation from "../components/Login/MFAValidation.vue";
import ForgotPassword from "../components/Login/ForgotPassword.vue";
import Footer from "../components/Layout/Footer.vue";
export default {
  components: {
    SelfRegistration,
    MFAValidation,
    ForgotPassword,
    Footer,
  },
  props: {
    registered: {
      type: Boolean,
      required: false,
    },
    registeredMessage: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    valid: true,
    registration: false,
    mfaValidation: false,
    forgot: false,
    mfaType: "SMS_MFA",
    show: false,
    password: "",
    email: "",
    sessionUser: {},
    isError: false,
    errorMessage: "",
    isSuccess: false,
    successMessage: "",
    loading: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(v) ||
        "E-mail must be valid",
    ],
    passwordRules: [(v) => !!v || "Password is required"],
  }),
  mounted() {
    if (this.registered) {
      this.userRegistered();
    }
    if (this.$route.query && this.$route.query.resetPassword === "true") {
      this.forgot = true;
    }
  },
  methods: {
    async validate() {
      const isValid = this.$refs.form.validate();
      const admins = ["Admin", "Master"];
      if (isValid) {
        this.loading = true;

        try {
          await Auth.signIn(this.email, this.password).then((response) => {
            this.sessionUser = response;

            if (this.sessionUser.challengeName === "NEW_PASSWORD_REQUIRED") {
              this.registration = true;
              this.loading = false;
            } else if (
              this.sessionUser.challengeName === "SMS_MFA" ||
              this.sessionUser.challengeName === "SOFTWARE_TOKEN_MFA"
            ) {
              this.mfaValidation = true;
              this.mfaType === this.sessionUser.challengeName;
              this.loading = false;
            } else {
              this.$store.commit("logIn", {
                isLoggedIn: true,
                username: this.sessionUser.Username,
                token: this.sessionUser.signInUserSession.accessToken.jwtToken,
                isAdmin: admins.some((i) =>
                  this.sessionUser.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ].includes(i)
                ),
                userType:
                  this.sessionUser.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ][0],
              });

              this.$router.push({ name: "Home" });
            }
          });
        } catch (error) {
          if (error.code == "UserNotFoundException") {
            this.errorMessage = "Invalid username/password";
          } else {
            this.errorMessage = error.message;
            console.log(error); // We don't want to log the error if the user doesn't exists since haxxors can use that for info
          }
          this.isError = true;
          this.loading = false;
        }
      }
    },
    forgotPassword() {
      this.forgot = true;
    },
    backToLogin() {
      this.forgot = false;
    },
    passwordReset(message) {
      this.ResetLogin();
      this.forgot = false;
      this.isSuccess = true;
      this.successMessage = message;
    },
    userRegistered() {
      this.forgot = false;
      this.isSuccess = true;
      this.successMessage = this.registeredMessage;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ResetLogin() {
      this.registration = false;
      this.mfaValidation = false;
      this.forgot = false;
      this.email = "";
      this.password = "";
      this.sessionUser = {};
      this.isError = false;
      this.errorMessage = "";
      this.isSuccess = false;
      this.successMessage = "";
    },
  },
};
</script>
<style scoped>
.login {
  width: 100%;
  height: 40px !important;
  color: #fff;
}

.login-body,
.login-card,
.v-application--wrap {
  border-radius: 50px;
}
.v-application .mb-6 {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 600px) {
  .v-btn {
    font-size: 0.75em;
  }
}
</style>
