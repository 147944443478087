<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-row class="ml-3 mt-2" justify="center" max-width="400" width="400">
      <v-icon
        medium
        color="primary"
        :class="destination === 'from' ? 'active-step' : 'step'"
        >mdi-numeric-1-circle-outline</v-icon
      >
      <v-col cols="4" md="4" lg="3" xl="2">
        <v-divider class="ml-0"></v-divider>
      </v-col>
      <v-icon
        medium
        color="primary"
        :class="destination === 'to' ? 'active-step' : 'step'"
        >mdi-numeric-2-circle-outline</v-icon
      >
      <v-col cols="4" md="4" lg="3" xl="2">
        <v-divider class="ml-0"></v-divider>
      </v-col>
      <v-icon
        medium
        color="primary"
        :class="destination === '' ? 'active-step' : 'step'"
        >mdi-numeric-3-circle-outline</v-icon
      ></v-row
    >
    <v-row class="ml-3" justify="center" v-if="destination !== ''">
      <v-col cols="12" md="2" lg="2" xl="2" v-if="destination === 'from'">
        <v-autocomplete
          label="Parties"
          :items="parties"
          :loading="loadingParty"
          item-text="text"
          item-value="value"
          v-model="selectedPartyId"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2">
        <v-autocomplete
          label="Bookings"
          :items="
            activeBookings.filter(
              (booking) =>
                selectedPartyId === '0' || booking.partyId === selectedPartyId
            )
          "
          :loading="loadingBookings"
          item-text="text"
          item-value="value"
          v-model="selectedBookingId"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="ml-3" justify="center" v-if="destination !== ''">
      <v-col cols="12" md="3" lg="2" xl="2">
        <v-btn
          @click="GetBookingDetails(selectedBookingId, 'to')"
          :loading="loadingBooking"
          :disabled="selectedBookingId === 0"
          color="secondary"
          class="mt-2 mr-2 ml-2 submit-button text--primary"
          >Search Booking
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-3">
      <v-col cols="12" md="5" lg="5" xl="5" v-if="fromBooking.length">
        <v-data-table
          :headers="headers"
          :items="fromBooking"
          item-key="bookingNumber"
          :loading="loadingBooking"
          class="elevation-1 table mb-5"
          show-expand
          :expanded.sync="fromContainers"
          hide-default-footer
        >
          <template
            v-slot:expanded-item="{ headers, item }"
            style="shadow-box: none"
          >
            <td :colspan="headers.length">
              <v-data-table
                :headers="containerHeaders"
                :items="item.containers.filter((container) => !container.move)"
                item-key="name"
                :loading="loadingBooking"
                class="elevation-1 table"
              >
                <template v-slot:item.NetWeight="{ item }">
                  <span>{{ item.Weight - item.TareWeight }}</span>
                </template>
                <!-- eslint-disable-next-line -->
              <template v-slot:item.action="{ item, isSelected, select }">
                  <v-simple-checkbox
                    v-if="
                      approvedContainerStatus.includes(
                        item.BookingContainerEventStatusName
                      )
                    "
                    v-model="item.isSelected"
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        cols="12"
        md="1"
        lg="1"
        xl="1"
        v-if="toBooking.length && fromBooking.length"
      >
        <v-btn
          @click="MoveSelected()"
          :loading="loadingBooking"
          :disabled="
            fromBooking.length &&
            fromBooking[0].containers.filter(
              (container) => container.isSelected
            ).length === 0
          "
          color="secondary"
          class="mt-2 mr-2 ml-2 submit-button text--primary"
          ><v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="5" lg="5" xl="5" v-if="toBooking.length">
        <v-data-table
          :headers="headers"
          :items="toBooking"
          item-key="bookingNumber"
          :loading="loadingBooking"
          class="elevation-1 table mb-5"
          show-expand
          :expanded.sync="toContainers"
          hide-default-footer
        >
          <template
            v-slot:expanded-item="{ headers, item }"
            style="shadow-box: none"
          >
            <td :colspan="headers.length">
              <v-data-table
                :headers="containerHeaders"
                :items="
                  item.containers.concat(
                    fromBooking.length &&
                      fromBooking[0].containers.filter(
                        (container) => container.move
                      )
                  )
                "
                item-key="name"
                :loading="loadingBooking"
                class="elevation-1 table"
              >
                <template v-slot:item.NetWeight="{ item }">
                  <span>{{ item.Weight - item.TareWeight }}</span>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn icon color="red" v-if="item.move">
                    <v-icon
                      color="red"
                      @click="CancelMove(item.BookingContainerId)"
                      >mdi-close-circle</v-icon
                    >
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="center" class="ml-3 mb-2">
      <v-col cols="12" md="3" lg="2" xl="2">
        <v-btn
          v-if="
            destination === 'to' ||
            (destination === 'from' && selectedBookingId !== 0)
          "
          :href="
            this.$route.params && this.$route.params.fromBookingId
              ? '/outbound/containermove/' +
                this.$route.params.fromBookingId +
                '/' +
                selectedBookingId
              : '/outbound/containermove/' + selectedBookingId
          "
          :loading="loadingBooking"
          :disabled="selectedBookingId === 0"
          color="secondary"
          class="mt-2 mr-2 ml-2 mb-2 submit-button text--primary"
          >Continue
        </v-btn>
        <v-dialog
          v-model="confirmation"
          width="600"
          v-if="toBooking.length && fromBooking.length"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :disabled="
                fromBooking[0].containers.filter((container) => container.move)
                  .length === 0
              "
              color="secondary"
              class="mt-2 mr-2 ml-2 mb-2 submit-button text--primary"
              >Confirm
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Confirm Container Move
            </v-card-title>
            <v-card-text class="mt-2">
              Confirm you want to move these containers from
              <b>{{ fromBooking[0].bookingNumber }}</b> to
              <b>{{ toBooking[0].bookingNumber }}</b>
            </v-card-text>
            <v-data-table
              :headers="containerHeaders"
              :items="
                fromBooking.length
                  ? fromBooking[0].containers.filter(
                      (container) => container.move
                    )
                  : []
              "
              item-key="name"
              class="elevation-1 table"
            >
              <template v-slot:item.NetWeight="{ item }">
                <span>{{ item.Weight - item.TareWeight }}</span>
              </template>
            </v-data-table>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :loading="confirmationLoading"
                @click="confirmation = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                :loading="confirmationLoading"
                @click="ConfirmMove()"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "MoveBookingContainer",

  components: {},
  data() {
    return {
      parties: [],
      selectedPartyId: "0",
      activeBookings: [],
      selectedBookingId: 0,
      fromBooking: [],
      toBooking: [],
      fromContainers: [],
      toContainers: [],
      destination: "",
      approvedContainerStatus: [
        "Loaded",
        "PendingFumigation",
        "FumigationStarted",
        "FumigationEnded",
        "FumigationCert",
        "RailBilled",
      ],
      data: [],
      confirmation: false,
      loadingBookings: false,
      loadingParty: false,
      loadingBooking: false,
      confirmationLoading: false,
      isError: false,
      emailAddress: "",
      alertMessage: "",
      documents: [],
      selected: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Customer", value: "PartyAccountName" },
        { text: "Booking", align: "start", value: "bookingNumber" },
        { text: "Commodity", value: "CommodityName" },
        { text: "Container Type", value: "ContainerTypeName" },
        { text: "SSL", value: "ShipperName" },
        { text: "ERD", value: "earliestReleaseDate" },
        { text: "Cut", value: "cutDate" },
        { text: "", value: "data-table-expand" },
      ];
    },
    containerHeaders() {
      return [
        { text: "Container #", value: "ContainerNumber" },
        { text: "Status", value: "BookingContainerEventStatusName" },
        { text: "Seal #", value: "SealNumber" },
        { text: "Ticket #", value: "TicketNumber" },
        { text: "Weight", value: "Weight" },
        { text: "Tare Weight", value: "TareWeight" },
        { text: "Net Weight", value: "NetWeight" },
        { text: "Load Date", value: "LoadDate" },
        { text: "", value: "action" },
      ];
    },
  },
  mounted() {
    this.getParties();
    this.getActiveBookings(
      this.$route.params && this.$route.params.fromBookingId
    );
    if (this.$route.params && this.$route.params.fromBookingId) {
      if (this.$route.params.toBookingId) {
        this.GetBookingDetails(this.$route.params.fromBookingId, "from");
        this.GetBookingDetails(this.$route.params.toBookingId, "to");
      } else {
        this.destination = "to";
      }
    } else {
      this.destination = "from";
    }
  },
  methods: {
    isUserAllowed,
    openLink(url) {
      window.open(url);
    },
    async getParties() {
      try {
        this.loadingParty = true;
        let parties = await api.get("/users/parties");
        this.parties = parties.map((party) => {
          return { text: party.name, value: party.party_id.toString() };
        });
      } catch (error) {
        console.log(error);
      }
      this.loadingParty = false;
    },
    async getActiveBookings(sourceBookingId) {
      try {
        this.loadingBookings = true;
        let activeBookings = await api.get(
          "/load/activeBookings?bookingId=" +
            (sourceBookingId !== undefined ? sourceBookingId : 0)
        );
        this.activeBookings = activeBookings.map((booking) => {
          return {
            text: booking.BookingNumber,
            value: booking.BookingId.toString(),
            partyId: booking.PartyId.toString(),
          };
        });
      } catch (error) {
        console.log(error);
      }
      this.loadingBookings = false;
    },
    async GetBookingDetails(bookingId, destination) {
      try {
        this.loadingBooking = true;
        this[destination + "Booking"] = [];
        this[destination + "Booking"].push(
          await api.get("/load/bookingdetails/" + bookingId)
        );
        if (this[destination + "Booking"].length > 0) {
          this[destination + "Containers"].push(
            this[destination + "Booking"][0]
          );
        }
      } catch (err) {
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
      this.loadingBooking = false;
    },
    async MoveSelected() {
      this.fromBooking[0].containers = this.fromBooking[0].containers.map(
        (container) => {
          if (
            container.isSelected &&
            this.approvedContainerStatus.includes(
              container.BookingContainerEventStatusName
            )
          ) {
            container.move = true;
          }
          return container;
        }
      );
    },
    async CancelMove(bookingContainerId) {
      this.fromBooking[0].containers = this.fromBooking[0].containers.map(
        (container) => {
          if (container.BookingContainerId === bookingContainerId) {
            container.move = false;
          }
          return container;
        }
      );
    },
    async ConfirmMove() {
      try {
        this.confirmationLoading = true;
        let containersToMove = this.fromBooking[0].containers
          .filter((container) => container.move)
          .map((container) => {
            return { ...container, BookingId: this.$route.params.toBookingId };
          });
        await api.put("/load/movecontainers", containersToMove);

        await Promise.all([
          this.GetBookingDetails(this.$route.params.fromBookingId, "from"),
          this.GetBookingDetails(this.$route.params.toBookingId, "to"),
        ]);
      } catch (err) {
        this.isError = true;
        this.alertMessage = "Failed to Move container. Please try again.";
        console.log(err);
      }
      this.confirmation = false;
      this.confirmationLoading = false;
    },
  },
};
</script>

<style>
.search-bar {
  padding-top: 15px;
}
.table {
  padding-left: 15px;
  padding-right: 15px;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
}

.active-step {
  background-color: #ffcc00 !important;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.active-step {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
</style>
