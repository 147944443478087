<template>
  <div>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-alert
      dismissible
      border="left"
      type="success"
      v-model="isSuccess"
      class="message"
    >
      {{ successMessage }}
    </v-alert>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="form"
      v-if="isUserAllowed(['Party'])"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            label="Comment Type"
            :items="commentTypes"
            item-text="text"
            item-value="value"
            v-model="comment.CommentTypeId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-text-field
            v-model="comment.Name"
            label="Name"
            required
            v-on:keypress="isLetterOrNumber($event)"
            clearable
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-autocomplete
            label="Status"
            :items="statuses"
            item-text="text"
            item-value="value"
            v-model="comment.Active"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="6" lg="6" xl="6">
          <tiptap-vuetify
            v-model="comment.CommentText"
            :extensions="extensions"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mb-2">
        <v-col cols="12" md="2" lg="2" xl="2">
          <v-btn
            @click="SaveComment"
            :disabled="!valid"
            :loading="loading"
            color="secondary"
            class="mr-2 ml-2 submit-button text--primary"
            >{{ comment.CommentId ? "Update Comment" : "Create Comment" }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" lg="2" xl="2" v-if="comment.CommentId > 0">
          <v-btn
            @click="comment = { Active: true }"
            v-if="comment.CommentId > 0"
            :loading="loading"
            color="secondary"
            class="mr-2 ml-2 submit-button text--primary"
            >Cancel Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      v-if="isUserAllowed(['Admin', 'Master', 'Party'])"
      :headers="headers"
      :items="comments"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      item-key="FileName"
      :loading="loading"
      class="elevation-1 table mt-5"
    >
      <!-- eslint-disable-next-line -->
      <template v-if="isUserAllowed(['Admin'])" v-slot:header.PartyName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="partySearch"
                label="Enter a party"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn @click="partySearch = ''" small text class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.CommentTypeName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="commentTypeSearch"
                label="Enter a Comment Type"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn
                @click="commentTypeSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.CommentText="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="commentTextSearch"
                label="Enter a Comment Text"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn
                @click="commentTextSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:header.Active="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div class="pl-2 pr-2" style="background-color: white">
              <v-autocomplete
                label="Status"
                :items="statuses"
                item-text="text"
                item-value="value"
                v-model="commentStatusSearch"
              ></v-autocomplete>
              <v-btn
                @click="commentStatusSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.Name="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small> mdi-filter </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="commentNameSearch"
                label="Enter a Name"
                class="pa-4"
                type="text"
              ></v-text-field>
              <v-btn
                @click="commentNameSearch = ''"
                small
                text
                class="ml-2 mb-2"
                >Clear</v-btn
              >
            </div>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.CommentText="{ item }">
        <div class="mt-2 mb-2 table-cell" v-html="item.CommentText" />
      </template>
      <template v-slot:item.Active="{ item }">
        {{ item.Active ? "Active" : "In-Active" }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn v-if="item.Active" icon @click="comment = item">
          <v-icon color="grey">mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
const api = require("@/api/api.js");
import { isUserAllowed } from "@/helpers.js";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
} from "tiptap-vuetify";

export default {
  name: "Comments",
  components: {
    TiptapVuetify,
  },
  data: () => ({
    extensions: [
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      Bold,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    isError: false,
    loading: true,
    alertMessage: "",
    valid: true,
    partySearch: "",
    commentTypeSearch: "",
    commentNameSearch: "",
    commentStatusSearch: "",
    commentTextSearch: "",
    comment: { Active: true },
    comments: [],
    commentTypes: [],
    isSuccess: false,
    successMessage: "",
    statuses: [
      { text: "Active", value: true },
      { text: "In-Active", value: false },
    ],
    search: "",
  }),
  computed: {
    headers() {
      let headers = [];
      if (isUserAllowed(["Admin", "Master"])) {
        headers.push({
          text: "Party",
          value: "PartyName",
          sortable: false,
          filter: (value) => {
            if (!this.partySearch || this.partySearch === "") return true;
            return (
              value != null &&
              this.partySearch != null &&
              typeof value === "string" &&
              value
                .toString()
                .toLocaleUpperCase()
                .indexOf(this.partySearch.toString().toLocaleUpperCase()) !== -1
            );
          },
        });
      }
      headers.push({
        text: "Comment type",
        value: "CommentTypeName",
        sortable: false,
        filter: (value) => {
          if (!this.commentTypeSearch || this.commentTypeSearch === "")
            return true;
          return (
            value != null &&
            this.commentTypeSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(
                this.commentTypeSearch.toString().toLocaleUpperCase()
              ) !== -1
          );
        },
      });
      headers.push({
        text: "Name",
        value: "Name",
        sortable: false,
        filter: (value) => {
          if (!this.commentNameSearch || this.commentNameSearch === "")
            return true;
          return (
            value != null &&
            this.commentNameSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(
                this.commentNameSearch.toString().toLocaleUpperCase()
              ) !== -1
          );
        },
      });
      headers.push({
        text: "Text",
        value: "CommentText",
        sortable: false,
        filter: (value) => {
          if (!this.commentTextSearch || this.commentTextSearch === "")
            return true;
          return (
            value != null &&
            this.commentTextSearch != null &&
            typeof value === "string" &&
            value
              .toString()
              .toLocaleUpperCase()
              .indexOf(
                this.commentTextSearch.toString().toLocaleUpperCase()
              ) !== -1
          );
        },
      });
      headers.push({
        text: "Status",
        value: "Active",
        sortable: false,
        filter: (value) => {
          if (
            this.commentStatusSearch === undefined ||
            this.commentStatusSearch === ""
          )
            return true;
          return (
            value != null &&
            this.commentStatusSearch != null &&
            typeof value === "boolean" &&
            this.commentStatusSearch.toString() === value.toString()
          );
        },
      });
      if (isUserAllowed(["Party"])) {
        headers.push({ text: "", value: "action", sortable: false });
      }
      return headers;
    },
  },
  async mounted() {
    this.GetComments();
    this.GetCommentTypes();
  },
  methods: {
    isUserAllowed,
    async GetComments() {
      try {
        this.loading = true;
        this.comments = await api.get(`/load/comments`);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          err.response && err.response.data ? err.response.data : "";
        if (this.alertMessage === "") {
          this.alertMessage =
            "Failed to retrieve comment data. Please try again.";
        }
        console.log(err);
      }
    },
    async GetCommentTypes() {
      try {
        this.loading = true;
        let response = await api.get(`/load/commenttypes`);
        this.commentTypes = response.map((value) => {
          return { text: value.Name, value: value.CommentTypeId };
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          err.response && err.response.data ? err.response.data : "";
        if (this.alertMessage === "") {
          this.alertMessage =
            "Failed to retrieve comment type data. Please try again.";
        }
        console.log(err);
      }
    },
    async SaveComment() {
      try {
        this.loading = true;
        await api.post(`/load/comment`, this.comment);
        this.isSuccess = true;
        this.successMessage = "Comment was Successfully Saved!";
        this.comment = { Active: true };
        this.loading = false;
        this.GetComments();
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage =
          err.response && err.response.data ? err.response.data : "";
        if (this.alertMessage === "") {
          this.alertMessage = "Failed to save comment data. Please try again.";
        }
        console.log(err);
      }
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9- ]+$/.test(char)) return true;
      else e.preventDefault();
    },
  },
};
</script>
<style>
.tiptap-vuetify-editor__content {
  height: 200px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.table-cell {
  max-height: 100px;
  overflow: scroll;
}

.v-data-table__mobile-row__cell .table-cell {
  max-height: 175px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
