/* eslint-disable import/extensions */
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker.js";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import awsmobile from "@/aws-exports";

import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
import VuetifyMask from "vuetify-mask";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";

Vue.config.productionTip = false;

Amplify.configure(awsmobile);
Vue.use(Auth);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VuetifyMask);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
