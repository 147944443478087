var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { style: { background: _vm.$vuetify.theme.themes[_vm.theme].background } },
    [
      _c("navbar", { attrs: { height: "70px" } }),
      _c(
        "v-main",
        [
          _c("page-title"),
          !_vm.isHome ? _c("breadcrumbs") : _vm._e(),
          _c("Main"),
        ],
        1
      ),
      _c("bottom-bar"),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }