var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _c(
        "v-alert",
        {
          staticClass: "message",
          attrs: { dismissible: "", border: "left", type: "success" },
          model: {
            value: _vm.isSuccess,
            callback: function ($$v) {
              _vm.isSuccess = $$v
            },
            expression: "isSuccess",
          },
        },
        [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 table mt-5",
        attrs: {
          headers: _vm.computedHeaders,
          items: _vm.containers,
          "item-key": "ContainerNumber",
          loading: _vm.loading,
          "footer-props": {
            "items-per-page-options": [10, 20, 30, 40, 50, -1],
          },
          "items-per-page": 30,
          search: _vm.search,
          "custom-filter": _vm.FilterOnlyCapsText,
          "show-expand":
            _vm.$vuetify.breakpoint.xsOnly || _vm.$vuetify.breakpoint.smOnly,
          expanded: _vm.expanded,
          "mobile-breakpoint": 0,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "search-bar pt-2",
                    attrs: { align: "center" },
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex pt-0",
                        attrs: { cols: "12", sm: "6" },
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mx-4",
                          attrs: { label: "Filter" },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "mx-4",
                          attrs: { label: "Days back" },
                          on: { input: _vm.fetchContainersDebounced },
                          model: {
                            value: _vm.daysPast,
                            callback: function ($$v) {
                              _vm.daysPast = $$v
                            },
                            expression: "daysPast",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "header.ContainerNumber",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.BookingContainerEventStatusName",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.StartDate",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.EndDate",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.CommodityName",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.ContainerTypeDescription",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.SealNumber",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.CommodityTemperature",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.AirTemperature",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.FumeReading24Hr",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.FumeReading48Hr",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.FumeReading72Hr",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.FumeReading96Hr",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "header.FumeReading120Hr",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [_vm._v(" " + _vm._s(header.text) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.SealNumber",
            fn: function ({ item }) {
              return [
                item.SealNumber !== null
                  ? _c(
                      "v-edit-dialog",
                      {
                        attrs: {
                          "return-value": item.SealNumber,
                          large: "",
                          persistent: "",
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            return _vm.$set(item, "SealNumber", $event)
                          },
                          "update:return-value": function ($event) {
                            return _vm.$set(item, "SealNumber", $event)
                          },
                          save: function ($event) {
                            return _vm.UpdateFumigationContainer(item)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "mt-4 text-h6" }, [
                                    _vm._v("Update Seal Number"),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: { label: "Edit", autofocus: "" },
                                    model: {
                                      value: item.SealNumber,
                                      callback: function ($$v) {
                                        _vm.$set(item, "SealNumber", $$v)
                                      },
                                      expression: "item.SealNumber",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("div", [_vm._v(_vm._s(item.SealNumber))])]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.StartDate",
            fn: function ({ item }) {
              return [
                item.StartDate !== null
                  ? _c(
                      "v-edit-dialog",
                      {
                        attrs: { large: "", persistent: "" },
                        on: {
                          open: function ($event) {
                            _vm.editStartDate = item.StartDate
                          },
                          save: function ($event) {
                            return _vm.UpdateBookingContainerEventStart(item)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "mt-4 text-h6" }, [
                                    _vm._v("Update Start Time"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("v-date-picker", {
                                        model: {
                                          value: item.StartDateOnlyLocal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "StartDateOnlyLocal",
                                              $$v
                                            )
                                          },
                                          expression: "item.StartDateOnlyLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("v-time-picker", {
                                        model: {
                                          value: item.StartDateTimeOnlyLocal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "StartDateTimeOnlyLocal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.StartDateTimeOnlyLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("div", [_vm._v(_vm._s(item.StartDateLocal))])]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.EndDate",
            fn: function ({ item }) {
              return [
                item.EndDate !== null
                  ? _c(
                      "v-edit-dialog",
                      {
                        attrs: { large: "", persistent: "" },
                        on: {
                          open: function ($event) {
                            _vm.editEndDate = item.EndDate
                          },
                          save: function ($event) {
                            return _vm.UpdateBookingContainerEventEnd(item)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "mt-4 text-h6" }, [
                                    _vm._v("Update End Time"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("v-date-picker", {
                                        model: {
                                          value: item.EndDateOnlyLocal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "EndDateOnlyLocal",
                                              $$v
                                            )
                                          },
                                          expression: "item.EndDateOnlyLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("v-time-picker", {
                                        model: {
                                          value: item.EndDateTimeOnlyLocal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "EndDateTimeOnlyLocal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.EndDateTimeOnlyLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("div", [_vm._v(_vm._s(item.EndDateLocal))])]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.CommodityTemperature",
            fn: function ({ item }) {
              return [
                _c("v-text-field", {
                  staticClass: "mt-4 d-flex justify-center",
                  staticStyle: { width: "100px" },
                  attrs: {
                    disabled:
                      !_vm.isUserAllowed(["Fumigator"]) ||
                      !["FumigationStarted", "PendingFumigation"].includes(
                        item.BookingContainerEventStatusName
                      ),
                    type: "number",
                    rounded: "",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      item.Modified = true
                    },
                  },
                  model: {
                    value: item.CommodityTemperature,
                    callback: function ($$v) {
                      _vm.$set(item, "CommodityTemperature", $$v)
                    },
                    expression: "item.CommodityTemperature",
                  },
                }),
              ]
            },
          },
          {
            key: "item.AirTemperature",
            fn: function ({ item }) {
              return [
                _c("v-text-field", {
                  staticClass: "mt-4 d-flex justify-center",
                  staticStyle: { width: "100px" },
                  attrs: {
                    disabled:
                      !_vm.isUserAllowed(["Fumigator"]) ||
                      !["FumigationStarted", "PendingFumigation"].includes(
                        item.BookingContainerEventStatusName
                      ),
                    type: "number",
                    rounded: "",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      item.Modified = true
                    },
                  },
                  model: {
                    value: item.AirTemperature,
                    callback: function ($$v) {
                      _vm.$set(item, "AirTemperature", $$v)
                    },
                    expression: "item.AirTemperature",
                  },
                }),
              ]
            },
          },
          {
            key: "item.FumeReading24Hr",
            fn: function ({ item }) {
              return [
                _c("v-text-field", {
                  staticClass: "mt-4 d-flex justify-center",
                  staticStyle: { width: "100px" },
                  attrs: {
                    disabled:
                      item.BookingContainerEventStatusName ===
                        "PendingFumigation" ||
                      !_vm.isUserAllowed(["Fumigator"]),
                    type: "number",
                    rounded: "",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      item.Modified = true
                    },
                  },
                  model: {
                    value: item.FumeReading24Hr,
                    callback: function ($$v) {
                      _vm.$set(item, "FumeReading24Hr", $$v)
                    },
                    expression: "item.FumeReading24Hr",
                  },
                }),
              ]
            },
          },
          {
            key: "item.FumeReading48Hr",
            fn: function ({ item }) {
              return [
                _c("v-text-field", {
                  staticClass: "mt-4 d-flex justify-center",
                  staticStyle: { width: "100px" },
                  attrs: {
                    disabled:
                      item.BookingContainerEventStatusName ===
                        "PendingFumigation" ||
                      !_vm.isUserAllowed(["Fumigator"]),
                    type: "number",
                    rounded: "",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      item.Modified = true
                    },
                  },
                  model: {
                    value: item.FumeReading48Hr,
                    callback: function ($$v) {
                      _vm.$set(item, "FumeReading48Hr", $$v)
                    },
                    expression: "item.FumeReading48Hr",
                  },
                }),
              ]
            },
          },
          {
            key: "item.FumeReading72Hr",
            fn: function ({ item }) {
              return [
                _c("v-text-field", {
                  staticClass: "mt-4 d-flex justify-center",
                  staticStyle: { width: "100px !important" },
                  attrs: {
                    disabled:
                      item.BookingContainerEventStatusName ===
                        "PendingFumigation" ||
                      !_vm.isUserAllowed(["Fumigator"]),
                    type: "number",
                    rounded: "",
                    outlined: "",
                  },
                  on: {
                    input: function ($event) {
                      item.Modified = true
                    },
                  },
                  model: {
                    value: item.FumeReading72Hr,
                    callback: function ($$v) {
                      _vm.$set(item, "FumeReading72Hr", $$v)
                    },
                    expression: "item.FumeReading72Hr",
                  },
                }),
              ]
            },
          },
          {
            key: "item.FumeReading96Hr",
            fn: function ({ item }) {
              return [
                item.CommodityTemperature < 70
                  ? _c("v-text-field", {
                      staticClass: "mt-4 d-flex justify-center",
                      staticStyle: { width: "100px !important" },
                      attrs: {
                        disabled:
                          item.BookingContainerEventStatusName ===
                            "PendingFumigation" ||
                          !_vm.isUserAllowed(["Fumigator"]),
                        type: "number",
                        rounded: "",
                        outlined: "",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.FumeReading96Hr,
                        callback: function ($$v) {
                          _vm.$set(item, "FumeReading96Hr", $$v)
                        },
                        expression: "item.FumeReading96Hr",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.FumeReading120Hr",
            fn: function ({ item }) {
              return [
                item.CommodityTemperature < 60
                  ? _c("v-text-field", {
                      staticClass: "mt-4 d-flex justify-center",
                      staticStyle: { width: "100px !important" },
                      attrs: {
                        disabled:
                          item.BookingContainerEventStatusName ===
                            "PendingFumigation" ||
                          !_vm.isUserAllowed(["Fumigator"]),
                        type: "number",
                        rounded: "",
                        outlined: "",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.FumeReading120Hr,
                        callback: function ($$v) {
                          _vm.$set(item, "FumeReading120Hr", $$v)
                        },
                        expression: "item.FumeReading120Hr",
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                item.BookingContainerEventStatusName === "PendingFumigation" &&
                _vm.isUserAllowed(["Fumigator"])
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled:
                            isNaN(item.CommodityTemperature) ||
                            isNaN(item.AirTemperature),
                          icon: "",
                          color: "green",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { color: "green" },
                            on: {
                              click: function ($event) {
                                return _vm.StartFumigation(item)
                              },
                            },
                          },
                          [_vm._v("mdi-circle")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.BookingContainerEventStatusName === "FumigationStarted" &&
                _vm.isUserAllowed(["Fumigator"])
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled: _vm.CheckIfFumigationCanEnd(item),
                          icon: "",
                          color: "red",
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { color: "red" },
                            on: {
                              click: function ($event) {
                                return _vm.EndFumigation(item)
                              },
                            },
                          },
                          [_vm._v("mdi-octagon")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.FileName &&
                item.FileName != "" &&
                _vm.isUserAllowed(["Fumigator", "Admin"])
                  ? _c(
                      "v-btn",
                      {
                        attrs: { link: true, target: "_blank", icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openLink(item.PreSignedUrl)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-file")])],
                      1
                    )
                  : _vm._e(),
                item.FileName &&
                item.FileName != "" &&
                _vm.isUserAllowed(["Fumigator", "Admin"])
                  ? _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.GenerateNewFumigationCertificate(item)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-refresh")])],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function ({ headers, item }) {
              return [
                _c(
                  "td",
                  { attrs: { colspan: headers.length } },
                  [
                    _c("div", { staticClass: "mt-5" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Booking Number : "),
                      ]),
                      _vm._v(_vm._s(item.BookingNumber) + " "),
                    ]),
                    _c("div", { staticClass: "mt-1" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Commodity : "),
                      ]),
                      _vm._v(_vm._s(item.CommodityName) + " "),
                    ]),
                    _c("div", { staticClass: "mt-1" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Container Type : "),
                      ]),
                      _vm._v(_vm._s(item.ContainerTypeDescription) + " "),
                    ]),
                    _c(
                      "v-chip",
                      { staticClass: "ma-1", attrs: { label: "" } },
                      [
                        _c(
                          "v-edit-dialog",
                          {
                            attrs: {
                              "return-value": item.SealNumber,
                              large: "",
                              persistent: "",
                            },
                            on: {
                              "update:returnValue": function ($event) {
                                return _vm.$set(item, "SealNumber", $event)
                              },
                              "update:return-value": function ($event) {
                                return _vm.$set(item, "SealNumber", $event)
                              },
                              save: function ($event) {
                                return _vm.UpdateFumigationContainer(item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "mt-4 text-h6" },
                                        [_vm._v("Update Seal Number")]
                                      ),
                                      _c("v-text-field", {
                                        attrs: { label: "Edit", autofocus: "" },
                                        model: {
                                          value: item.SealNumber,
                                          callback: function ($$v) {
                                            _vm.$set(item, "SealNumber", $$v)
                                          },
                                          expression: "item.SealNumber",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Seal Number : "),
                            ]),
                            _vm._v(_vm._s(item.SealNumber) + " "),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-chip",
                      { staticClass: "ma-1", attrs: { label: "" } },
                      [
                        item.StartDate !== null
                          ? _c(
                              "v-edit-dialog",
                              {
                                attrs: { large: "", persistent: "" },
                                on: {
                                  open: function ($event) {
                                    _vm.editStartDate = item.StartDate
                                  },
                                  save: function ($event) {
                                    return _vm.UpdateBookingContainerEventStart(
                                      item
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-4 text-h6" },
                                            [_vm._v("Update Start Time")]
                                          ),
                                          _c("v-date-picker", {
                                            model: {
                                              value: item.StartDateOnlyLocal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "StartDateOnlyLocal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.StartDateOnlyLocal",
                                            },
                                          }),
                                          _c("v-time-picker", {
                                            model: {
                                              value:
                                                item.StartDateTimeOnlyLocal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "StartDateTimeOnlyLocal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.StartDateTimeOnlyLocal",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("Start Date : ")]
                                ),
                                _vm._v(_vm._s(item.StartDateLocal) + " "),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-chip",
                      { staticClass: "ma-1", attrs: { label: "" } },
                      [
                        item.EndDate !== null
                          ? _c(
                              "v-edit-dialog",
                              {
                                attrs: { large: "", persistent: "" },
                                on: {
                                  open: function ($event) {
                                    _vm.editEndDate = item.EndDate
                                  },
                                  save: function ($event) {
                                    return _vm.UpdateBookingContainerEventEnd(
                                      item
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "input",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-4 text-h6" },
                                            [_vm._v("Update End Time")]
                                          ),
                                          _c("v-date-picker", {
                                            model: {
                                              value: item.EndDateOnlyLocal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "EndDateOnlyLocal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.EndDateOnlyLocal",
                                            },
                                          }),
                                          _c("v-time-picker", {
                                            model: {
                                              value: item.EndDateTimeOnlyLocal,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "EndDateTimeOnlyLocal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.EndDateTimeOnlyLocal",
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("End Date : ")]
                                ),
                                _vm._v(_vm._s(item.EndDateLocal) + " "),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("v-text-field", {
                      staticClass: "mt-2",
                      attrs: {
                        disabled:
                          !_vm.isUserAllowed(["Fumigator"]) ||
                          !["FumigationStarted", "PendingFumigation"].includes(
                            item.BookingContainerEventStatusName
                          ),
                        type: "number",
                        label: "Commodity Temperature",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.CommodityTemperature,
                        callback: function ($$v) {
                          _vm.$set(item, "CommodityTemperature", $$v)
                        },
                        expression: "item.CommodityTemperature",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-2",
                      attrs: {
                        disabled:
                          !_vm.isUserAllowed(["Fumigator"]) ||
                          !["FumigationStarted", "PendingFumigation"].includes(
                            item.BookingContainerEventStatusName
                          ),
                        type: "number",
                        label: "Air Temperature",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.AirTemperature,
                        callback: function ($$v) {
                          _vm.$set(item, "AirTemperature", $$v)
                        },
                        expression: "item.AirTemperature",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-2",
                      attrs: {
                        disabled:
                          item.BookingContainerEventStatusName ===
                            "PendingFumigation" ||
                          !_vm.isUserAllowed(["Fumigator"]),
                        type: "number",
                        label: "24 Hour Reading",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.FumeReading24Hr,
                        callback: function ($$v) {
                          _vm.$set(item, "FumeReading24Hr", $$v)
                        },
                        expression: "item.FumeReading24Hr",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-2",
                      attrs: {
                        disabled:
                          item.BookingContainerEventStatusName ===
                            "PendingFumigation" ||
                          !_vm.isUserAllowed(["Fumigator"]),
                        type: "number",
                        label: "48 Hour Reading",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.FumeReading48Hr,
                        callback: function ($$v) {
                          _vm.$set(item, "FumeReading48Hr", $$v)
                        },
                        expression: "item.FumeReading48Hr",
                      },
                    }),
                    _c("v-text-field", {
                      staticClass: "mt-2",
                      attrs: {
                        disabled:
                          item.BookingContainerEventStatusName ===
                            "PendingFumigation" ||
                          !_vm.isUserAllowed(["Fumigator"]),
                        type: "number",
                        label: "72 Hour Reading",
                      },
                      on: {
                        input: function ($event) {
                          item.Modified = true
                        },
                      },
                      model: {
                        value: item.FumeReading72Hr,
                        callback: function ($$v) {
                          _vm.$set(item, "FumeReading72Hr", $$v)
                        },
                        expression: "item.FumeReading72Hr",
                      },
                    }),
                    item.CommodityTemperature < 70
                      ? _c("v-text-field", {
                          staticClass: "mt-2",
                          attrs: {
                            disabled:
                              item.BookingContainerEventStatusName ===
                                "PendingFumigation" ||
                              !_vm.isUserAllowed(["Fumigator"]),
                            type: "number",
                            label: "96 Hour Reading",
                          },
                          on: {
                            input: function ($event) {
                              item.Modified = true
                            },
                          },
                          model: {
                            value: item.FumeReading96Hr,
                            callback: function ($$v) {
                              _vm.$set(item, "FumeReading96Hr", $$v)
                            },
                            expression: "item.FumeReading96Hr",
                          },
                        })
                      : _vm._e(),
                    item.CommodityTemperature < 60
                      ? _c("v-text-field", {
                          staticClass: "mt-2",
                          attrs: {
                            disabled:
                              item.BookingContainerEventStatusName ===
                                "PendingFumigation" ||
                              !_vm.isUserAllowed(["Fumigator"]),
                            type: "number",
                            label: "120 Hour Reading",
                          },
                          on: {
                            input: function ($event) {
                              item.Modified = true
                            },
                          },
                          model: {
                            value: item.FumeReading120Hr,
                            callback: function ($$v) {
                              _vm.$set(item, "FumeReading120Hr", $$v)
                            },
                            expression: "item.FumeReading120Hr",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        item.FileName &&
                        item.FileName != "" &&
                        _vm.isUserAllowed(["Fumigator", "Admin"])
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-3 text--primary",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  link: true,
                                  target: "_blank",
                                  loading: _vm.loading,
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openLink(item.PreSignedUrl)
                                  },
                                },
                              },
                              [_vm._v(" Fumigation Certificate ")]
                            )
                          : _vm._e(),
                        item.FileName &&
                        item.FileName != "" &&
                        _vm.isUserAllowed(["Fumigator", "Admin"])
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-3 text--primary",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  loading: _vm.loading,
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.GenerateNewFumigationCertificate(
                                      item
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Generate New Certificate ")]
                            )
                          : _vm._e(),
                        item.BookingContainerEventStatusName ===
                          "PendingFumigation" &&
                        _vm.isUserAllowed(["Fumigator"])
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-3 text--primary",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled:
                                    isNaN(item.CommodityTemperature) ||
                                    isNaN(item.AirTemperature),
                                  loading: _vm.loading,
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.StartFumigation(item)
                                  },
                                },
                              },
                              [_vm._v(" Start Fumigation ")]
                            )
                          : _vm._e(),
                        item.BookingContainerEventStatusName ===
                          "FumigationStarted" &&
                        _vm.isUserAllowed(["Fumigator"])
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-3 text--primary",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.CheckIfFumigationCanEnd(item),
                                  loading: _vm.loading,
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.EndFumigation(item)
                                  },
                                },
                              },
                              [_vm._v(" End Fumigation ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.isUserAllowed(["Fumigator"])
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mt-3 mb-3 text--primary",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  loading: _vm.loading,
                                  color: "secondary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.UpdateFumigationContainers(item)
                                  },
                                },
                              },
                              [_vm._v(" Update Temperatures ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", md: "9", lg: "10", xl: "10" } }),
          _vm.isUserAllowed(["Fumigator"])
            ? _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center",
                  attrs: { cols: "12", md: "3", lg: "2", xl: "1" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 ml-2 mt-3 text--primary",
                      attrs: { loading: _vm.loading, color: "secondary" },
                      on: { click: _vm.UpdateFumigationContainers },
                    },
                    [_vm._v(" Update Temperatures ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }