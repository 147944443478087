import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        background: "#f6f6f6",
        primary: "#1A1423",
        secondary: "#FFCC00",
        warning: "#FB8C00",
        info: "#007DBA",
      },
      dark: {
        background: "#f6f6f6",
        primary: "#1A1423",
        secondary: "#FFCC00",
        warning: "#FB8C00",
        info: "#007DBA",
      },
    },
  },
});
