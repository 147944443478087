var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-body" },
    [
      _c(
        "v-app",
        [
          !_vm.registration && !_vm.mfaValidation && !_vm.forgot
            ? _c(
                "v-card",
                {
                  staticClass: "d-flex justify-center mb-12 login-card",
                  attrs: { tile: "", height: "100%" },
                },
                [
                  _c(
                    "v-card",
                    {
                      class: `align-self-center elevation-0`,
                      attrs: { tile: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "text-center" },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("img", {
                              staticClass: "text-center mt-5 mb-2",
                              attrs: {
                                src: require("../assets/img_e_brand_up_color_png.png"),
                                height: "100",
                              },
                            }),
                            _c("div", [_vm._v("Powered By JCT")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            border: "left",
                            type: "error",
                          },
                          model: {
                            value: _vm.isError,
                            callback: function ($$v) {
                              _vm.isError = $$v
                            },
                            expression: "isError",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                      ),
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dismissible: "",
                            border: "left",
                            type: "success",
                          },
                          model: {
                            value: _vm.isSuccess,
                            callback: function ($$v) {
                              _vm.isSuccess = $$v
                            },
                            expression: "isSuccess",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.successMessage) + " ")]
                      ),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.validate.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [_c("h2", [_vm._v("G4 Transload Login")])]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.emailRules,
                                          label: "E-mail",
                                          required: "",
                                          outlined: "",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function ($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Password",
                                          outlined: "",
                                          required: "",
                                          clearable: "",
                                          rules: _vm.passwordRules,
                                          "append-icon": _vm.show
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show ? "text" : "password",
                                        },
                                        on: {
                                          "click:append": function ($event) {
                                            _vm.show = !_vm.show
                                          },
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "login text--primary",
                                          attrs: {
                                            disabled: !_vm.valid,
                                            loading: _vm.loading,
                                            color: "secondary",
                                            type: "submit",
                                          },
                                        },
                                        [_vm._v(" Login ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6", md: "6" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "login white--text",
                                          attrs: { color: "primary" },
                                          on: { click: _vm.forgotPassword },
                                        },
                                        [_vm._v(" Forgot Password? ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "login",
                                          attrs: {
                                            color: "info",
                                            to: "/register",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Sign up for your ingredient deliveries "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.forgot
            ? _c("forgot-password", {
                on: {
                  click: _vm.forgotPassword,
                  forgotreset: _vm.passwordReset,
                  back: _vm.backToLogin,
                },
              })
            : _vm._e(),
          _vm.registration
            ? _c("self-registration", {
                attrs: {
                  sessionUser: _vm.sessionUser,
                  systemPassword: _vm.password,
                },
                on: { eventname: _vm.ResetLogin },
              })
            : _vm._e(),
          _vm.mfaValidation
            ? _c("MFAValidation", {
                attrs: { sessionUser: _vm.sessionUser, mfaType: _vm.mfaType },
              })
            : _vm._e(),
          _vm.registration || _vm.mfaValidation || _vm.forgot
            ? _c(
                "v-btn",
                { on: { click: _vm.ResetLogin } },
                [
                  _c("v-icon", [_vm._v("mdi-arrow-left")]),
                  _vm._v("Go Back to Login "),
                ],
                1
              )
            : _vm._e(),
          _c("Footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }