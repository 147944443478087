var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { style: { background: _vm.$vuetify.theme.themes[_vm.theme].background } },
    [
      _c("navbar"),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            [
              _c(
                "v-alert",
                {
                  attrs: { dismissible: "", border: "left", type: "error" },
                  model: {
                    value: _vm.isError,
                    callback: function ($$v) {
                      _vm.isError = $$v
                    },
                    expression: "isError",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
              _c(
                "v-alert",
                {
                  attrs: { dismissible: "", border: "left", type: "success" },
                  model: {
                    value: _vm.isSuccess,
                    callback: function ($$v) {
                      _vm.isSuccess = $$v
                    },
                    expression: "isSuccess",
                  },
                },
                [
                  _vm._v(
                    " You have successfully submitted your new user account. Please check your email for your temporary password and log in. Redirecting back to login page... "
                  ),
                ]
              ),
              _c("h1", { staticClass: "page-title" }, [
                _vm._v("Register User Account"),
              ]),
              _c("p", [
                _vm._v(
                  "Please enter the following information below to create your user."
                ),
              ]),
              _c("UserDetails", {
                attrs: { user: _vm.user, loading: _vm.loading },
                on: { eventname: _vm.CreateUser },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }