<template>
  <div class="app-main">
    <router-view :key="key" />
  </div>
</template>

<script>
export default {
  name: "Main",
  computed: {
    key() {
      return this.$route.path;
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "G4 Transload";
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .app-main {
    margin-bottom: 80px;
  }
}
</style>
