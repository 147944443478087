var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: { dismissible: "", type: "warning" },
          model: {
            value: _vm.isError,
            callback: function ($$v) {
              _vm.isError = $$v
            },
            expression: "isError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.alertMessage) + " ")]
      ),
      _vm.isUserAllowed(["Party"])
        ? _c(
            "v-form",
            {
              ref: "form",
              staticClass: "form mt-5",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Load Number",
                          required: "",
                          rules: [_vm.loadNumberRule, _vm.requiredRules],
                          clearable: "",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.isLetterOrNumber($event)
                          },
                        },
                        model: {
                          value: _vm.booking.LoadNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.booking, "LoadNumber", $$v)
                          },
                          expression: "booking.LoadNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "Commodity",
                          items: _vm.commodity,
                          "item-text": "text",
                          "item-value": "value",
                          rules: [_vm.requiredRules],
                          required: "",
                        },
                        model: {
                          value: _vm.booking.CommodityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.booking, "CommodityId", $$v)
                          },
                          expression: "booking.CommodityId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.partyAccount,
                          label: "Party Account",
                          required: "",
                          rules: [_vm.requiredRules],
                        },
                        model: {
                          value: _vm.booking.PartyAccountId,
                          callback: function ($$v) {
                            _vm.$set(_vm.booking, "PartyAccountId", $$v)
                          },
                          expression: "booking.PartyAccountId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-col", {
                    attrs: { cols: "0", md: "4", lg: "4", xl: "4" },
                  }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "mt-2 mr-2 ml-2 submit-button text--primary",
                          attrs: {
                            id: "createBooking",
                            disabled:
                              !_vm.booking.LoadNumber ||
                              !_vm.booking.CommodityId ||
                              !_vm.booking.PartyAccountId,
                            loading: _vm.loading,
                            color: "secondary",
                          },
                          on: { click: _vm.CreateBooking },
                        },
                        [_vm._v("Create Delivery ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _vm.isUserAllowed(["Trucker"])
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "2", lg: "2", xl: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "mt-3 mr-2 ml-2 mb-2 schedule-button text--primary",
                      attrs: {
                        id: "scheduleDelivery",
                        href: "/booking/driver",
                        loading: _vm.loading,
                        color: "secondary",
                      },
                    },
                    [_vm._v("Schedule a Delivery ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 table",
        attrs: {
          id: "dataTable",
          headers: _vm.headers,
          items: _vm.times,
          "item-key": "name",
          loading: _vm.loading,
          "footer-props": {
            "items-per-page-options": [10, 20, 30, 40, 50, -1],
          },
          "items-per-page": 30,
          search: _vm.search,
          "custom-filter": _vm.FilterOnlyCapsText,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-row",
                  { staticClass: "search-bar", attrs: { align: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex", attrs: { cols: "12", sm: "6" } },
                      [
                        _c("v-text-field", {
                          staticClass: "mx-4",
                          attrs: { id: "search", label: "Filter" },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                        _c("v-text-field", {
                          staticClass: "mx-4",
                          attrs: { id: "daysPast", label: "Days back" },
                          on: { input: _vm.fetchDeliveriesDebounced },
                          model: {
                            value: _vm.daysPast,
                            callback: function ($$v) {
                              _vm.daysPast = $$v
                            },
                            expression: "daysPast",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "header.DeliveryScheduleStatusDescription",
            fn: function ({ header }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _vm._v(" " + _vm._s(header.text) + " "),
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "offset-y": "",
                          "close-on-content-click": false,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-filter "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "background-color": "white",
                              width: "280px",
                            },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "pa-4",
                              attrs: {
                                label: "Enter a booking status",
                                type: "text",
                              },
                              model: {
                                value: _vm.statusSearch,
                                callback: function ($$v) {
                                  _vm.statusSearch = $$v
                                },
                                expression: "statusSearch",
                              },
                            }),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2 mb-2",
                                attrs: { small: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.statusSearch = ""
                                  },
                                },
                              },
                              [_vm._v("Clear")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.PartyName",
            fn: function ({ item }) {
              return [_vm._v(" " + _vm._s(item.PartyAccountName) + " ")]
            },
          },
          {
            key: "item.DriverPhone",
            fn: function ({ item }) {
              return [
                _c("a", { attrs: { href: `tel:${item.DriverPhone}` } }, [
                  _vm._v(_vm._s(item.DriverPhone)),
                ]),
              ]
            },
          },
          {
            key: "item.booked",
            fn: function ({ item }) {
              return [
                item.booked
                  ? _c("v-icon", { attrs: { color: "green" } }, [
                      _vm._v("mdi-circle"),
                    ])
                  : _c("v-icon", { attrs: { color: "red" } }, [
                      _vm._v("mdi-circle"),
                    ]),
              ]
            },
          },
          {
            key: "item.edit",
            fn: function ({ item }) {
              return [
                [53, 59].includes(item.StatusId)
                  ? _c(
                      "v-btn",
                      {
                        ref: "select-driver-time",
                        staticClass: "mr-2",
                        attrs: { size: "small", icon: "mdi-pencil" },
                        on: {
                          click: function ($event) {
                            return _vm.SelectDriverTime({
                              nativeEvent: $event,
                              event: item,
                            })
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                      1
                    )
                  : _vm._e(),
                item.DeliveryScheduleStatusDescription === "Not Scheduled"
                  ? _c(
                      "v-btn",
                      {
                        ref: "select-driver-time",
                        staticClass: "mr-2",
                        attrs: {
                          icon: "mdi-plus",
                          size: "small",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.DeleteDeliveries({
                              nativeEvent: $event,
                              event: item,
                            })
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(" mdi-delete ")])],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            activator: _vm.selectedElement,
            "offset-x": "",
          },
          model: {
            value: _vm.selectedOpen,
            callback: function ($$v) {
              _vm.selectedOpen = $$v
            },
            expression: "selectedOpen",
          },
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                color: "grey lighten-4",
                "min-width": "350px",
                flat: "",
              },
            },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                    1
                  ),
                  _c("v-toolbar-title", {
                    domProps: {
                      innerHTML: _vm._s(_vm.selectedEvent.loadNumber),
                    },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        staticStyle: { "font-size": "12px" },
                        attrs: {
                          label: "Load Number",
                          required: "",
                          outlined: "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.selectedEvent.LoadNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedEvent, "LoadNumber", $$v)
                          },
                          expression: "selectedEvent.LoadNumber",
                        },
                      }),
                      _c("v-select", {
                        staticStyle: { "font-size": "12px" },
                        attrs: {
                          items: _vm.GetAvailableDates(),
                          label: "Dates",
                          required: "",
                          rules: _vm.requiredRules,
                          outlined: "",
                        },
                        model: {
                          value: _vm.selectedEvent.ScheduleDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedEvent, "ScheduleDate", $$v)
                          },
                          expression: "selectedEvent.ScheduleDate",
                        },
                      }),
                      _c("v-select", {
                        staticStyle: { "font-size": "12px" },
                        attrs: {
                          items: _vm.GetAvailableTimesForDate(
                            _vm.selectedEvent.ScheduleDate
                          ),
                          label: "Times",
                          required: "",
                          rules: _vm.requiredRules,
                          outlined: "",
                        },
                        model: {
                          value: _vm.selectedEvent.ScheduleTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectedEvent, "ScheduleTime", $$v)
                          },
                          expression: "selectedEvent.ScheduleTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: { text: "", color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.selectedOpen = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        id: "deleteBooking",
                        text: "",
                        color: "secondary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.DeleteBooking(_vm.selectedEvent)
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text--primary",
                      attrs: {
                        id: "updateBooking",
                        text: "",
                        color: "secondary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.UpdateBooking(_vm.selectedEvent)
                        },
                      },
                    },
                    [_vm._v(" Update ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }