<template>
  <v-container fluid>
    <v-alert dismissible type="warning" v-model="isError">
      {{ alertMessage }}
    </v-alert>
    <v-alert
      dismissible
      border="left"
      type="success"
      v-model="isSuccess"
      class="message"
    >
      {{ successMessage }}
    </v-alert>

    <v-data-table
      :headers="computedHeaders"
      :items="containers"
      item-key="ContainerNumber"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, -1] }"
      :items-per-page="30"
      class="elevation-1 table mt-5"
      :search="search"
      :custom-filter="FilterOnlyCapsText"
      :show-expand="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
      :expanded="expanded"
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-row align="center" class="search-bar pt-2">
          <v-col class="d-flex pt-0" cols="12" sm="6">
            <v-text-field
              v-model="search"
              label="Filter"
              class="mx-4"
            ></v-text-field>
            <v-text-field
              v-model="daysPast"
              label="Days back"
              class="mx-4"
              @input="fetchContainersDebounced"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:header.ContainerNumber="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.BookingContainerEventStatusName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.StartDate="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.EndDate="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.CommodityName="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.ContainerTypeDescription="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.SealNumber="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.CommodityTemperature="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.AirTemperature="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.FumeReading24Hr="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.FumeReading48Hr="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.FumeReading72Hr="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.FumeReading96Hr="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.FumeReading120Hr="{ header }">
        <div class="d-flex" style="align-items: center">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:item.SealNumber="{ item }">
        <v-edit-dialog
          :return-value.sync="item.SealNumber"
          v-if="item.SealNumber !== null"
          large
          persistent
          @save="UpdateFumigationContainer(item)"
        >
          <div>{{ item.SealNumber }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Seal Number</div>
            <v-text-field
              v-model="item.SealNumber"
              label="Edit"
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.StartDate="{ item }">
        <v-edit-dialog
          v-if="item.StartDate !== null"
          large
          persistent
          @open="editStartDate = item.StartDate"
          @save="UpdateBookingContainerEventStart(item)"
        >
          <div>{{ item.StartDateLocal }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Start Time</div>
            <div style="width: 100%">
              <v-date-picker v-model="item.StartDateOnlyLocal"></v-date-picker>
            </div>
            <div style="width: 100%">
              <v-time-picker
                v-model="item.StartDateTimeOnlyLocal"
              ></v-time-picker>
            </div>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.EndDate="{ item }">
        <v-edit-dialog
          v-if="item.EndDate !== null"
          large
          persistent
          @open="editEndDate = item.EndDate"
          @save="UpdateBookingContainerEventEnd(item)"
        >
          <div>{{ item.EndDateLocal }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update End Time</div>
            <div style="width: 100%">
              <v-date-picker v-model="item.EndDateOnlyLocal"> </v-date-picker>
            </div>
            <div style="width: 100%">
              <v-time-picker v-model="item.EndDateTimeOnlyLocal">
              </v-time-picker>
            </div>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.CommodityTemperature="{ item }">
        <v-text-field
          :disabled="
            !isUserAllowed(['Fumigator']) ||
            !['FumigationStarted', 'PendingFumigation'].includes(
              item.BookingContainerEventStatusName
            )
          "
          v-model="item.CommodityTemperature"
          @input="item.Modified = true"
          class="mt-4 d-flex justify-center"
          style="width: 100px"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>
      <template v-slot:item.AirTemperature="{ item }">
        <v-text-field
          :disabled="
            !isUserAllowed(['Fumigator']) ||
            !['FumigationStarted', 'PendingFumigation'].includes(
              item.BookingContainerEventStatusName
            )
          "
          v-model="item.AirTemperature"
          @input="item.Modified = true"
          class="mt-4 d-flex justify-center"
          style="width: 100px"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>
      <template v-slot:item.FumeReading24Hr="{ item }">
        <v-text-field
          v-model="item.FumeReading24Hr"
          @input="item.Modified = true"
          :disabled="
            item.BookingContainerEventStatusName === 'PendingFumigation' ||
            !isUserAllowed(['Fumigator'])
          "
          class="mt-4 d-flex justify-center"
          style="width: 100px"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>
      <template v-slot:item.FumeReading48Hr="{ item }">
        <v-text-field
          v-model="item.FumeReading48Hr"
          @input="item.Modified = true"
          :disabled="
            item.BookingContainerEventStatusName === 'PendingFumigation' ||
            !isUserAllowed(['Fumigator'])
          "
          class="mt-4 d-flex justify-center"
          style="width: 100px"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>
      <template v-slot:item.FumeReading72Hr="{ item }">
        <v-text-field
          v-model="item.FumeReading72Hr"
          @input="item.Modified = true"
          :disabled="
            item.BookingContainerEventStatusName === 'PendingFumigation' ||
            !isUserAllowed(['Fumigator'])
          "
          class="mt-4 d-flex justify-center"
          style="width: 100px !important"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>

      <template v-slot:item.FumeReading96Hr="{ item }">
        <v-text-field
          v-model="item.FumeReading96Hr"
          @input="item.Modified = true"
          v-if="item.CommodityTemperature < 70"
          :disabled="
            item.BookingContainerEventStatusName === 'PendingFumigation' ||
            !isUserAllowed(['Fumigator'])
          "
          class="mt-4 d-flex justify-center"
          style="width: 100px !important"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>

      <template v-slot:item.FumeReading120Hr="{ item }">
        <v-text-field
          v-model="item.FumeReading120Hr"
          @input="item.Modified = true"
          v-if="item.CommodityTemperature < 60"
          :disabled="
            item.BookingContainerEventStatusName === 'PendingFumigation' ||
            !isUserAllowed(['Fumigator'])
          "
          class="mt-4 d-flex justify-center"
          style="width: 100px !important"
          type="number"
          rounded
          outlined
        ></v-text-field>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          :disabled="
            isNaN(item.CommodityTemperature) || isNaN(item.AirTemperature)
          "
          icon
          color="green"
          v-if="
            item.BookingContainerEventStatusName === 'PendingFumigation' &&
            isUserAllowed(['Fumigator'])
          "
        >
          <v-icon color="green" @click="StartFumigation(item)"
            >mdi-circle</v-icon
          >
        </v-btn>
        <v-btn
          :disabled="CheckIfFumigationCanEnd(item)"
          icon
          color="red"
          v-if="
            item.BookingContainerEventStatusName === 'FumigationStarted' &&
            isUserAllowed(['Fumigator'])
          "
        >
          <v-icon color="red" @click="EndFumigation(item)">mdi-octagon</v-icon>
        </v-btn>
        <v-btn
          :link="true"
          @click="openLink(item.PreSignedUrl)"
          target="_blank"
          icon
          v-if="
            item.FileName &&
            item.FileName != '' &&
            isUserAllowed(['Fumigator', 'Admin'])
          "
        >
          <v-icon>mdi-file</v-icon>
        </v-btn>
        <v-btn
          @click="GenerateNewFumigationCertificate(item)"
          icon
          v-if="
            item.FileName &&
            item.FileName != '' &&
            isUserAllowed(['Fumigator', 'Admin'])
          "
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <template
        v-slot:expanded-item="{ headers, item }"
        style="shadow-box: none"
      >
        <td :colspan="headers.length">
          <div class="mt-5">
            <span class="font-weight-bold">Booking Number : </span
            >{{ item.BookingNumber }}
          </div>
          <div class="mt-1">
            <span class="font-weight-bold">Commodity : </span
            >{{ item.CommodityName }}
          </div>
          <div class="mt-1">
            <span class="font-weight-bold">Container Type : </span
            >{{ item.ContainerTypeDescription }}
          </div>
          <v-chip class="ma-1" label>
            <v-edit-dialog
              :return-value.sync="item.SealNumber"
              large
              persistent
              @save="UpdateFumigationContainer(item)"
            >
              <span class="font-weight-bold">Seal Number : </span
              >{{ item.SealNumber }}
              <template v-slot:input>
                <div class="mt-4 text-h6">Update Seal Number</div>
                <v-text-field
                  v-model="item.SealNumber"
                  label="Edit"
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </v-chip>
          <v-chip class="ma-1" label>
            <v-edit-dialog
              v-if="item.StartDate !== null"
              large
              persistent
              @open="editStartDate = item.StartDate"
              @save="UpdateBookingContainerEventStart(item)"
            >
              <span class="font-weight-bold">Start Date : </span
              >{{ item.StartDateLocal }}
              <template v-slot:input>
                <div class="mt-4 text-h6">Update Start Time</div>
                <v-date-picker
                  v-model="item.StartDateOnlyLocal"
                ></v-date-picker>
                <v-time-picker
                  v-model="item.StartDateTimeOnlyLocal"
                ></v-time-picker>
              </template>
            </v-edit-dialog>
          </v-chip>
          <v-chip class="ma-1" label>
            <v-edit-dialog
              v-if="item.EndDate !== null"
              large
              persistent
              @open="editEndDate = item.EndDate"
              @save="UpdateBookingContainerEventEnd(item)"
            >
              <span class="font-weight-bold">End Date : </span
              >{{ item.EndDateLocal }}
              <template v-slot:input>
                <div class="mt-4 text-h6">Update End Time</div>
                <v-date-picker v-model="item.EndDateOnlyLocal"> </v-date-picker>
                <v-time-picker
                  v-model="item.EndDateTimeOnlyLocal"
                ></v-time-picker>
              </template>
            </v-edit-dialog>
          </v-chip>
          <v-text-field
            :disabled="
              !isUserAllowed(['Fumigator']) ||
              !['FumigationStarted', 'PendingFumigation'].includes(
                item.BookingContainerEventStatusName
              )
            "
            v-model="item.CommodityTemperature"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="Commodity Temperature"
          ></v-text-field>
          <v-text-field
            :disabled="
              !isUserAllowed(['Fumigator']) ||
              !['FumigationStarted', 'PendingFumigation'].includes(
                item.BookingContainerEventStatusName
              )
            "
            v-model="item.AirTemperature"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="Air Temperature"
          ></v-text-field>
          <v-text-field
            :disabled="
              item.BookingContainerEventStatusName === 'PendingFumigation' ||
              !isUserAllowed(['Fumigator'])
            "
            v-model="item.FumeReading24Hr"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="24 Hour Reading"
          ></v-text-field>
          <v-text-field
            :disabled="
              item.BookingContainerEventStatusName === 'PendingFumigation' ||
              !isUserAllowed(['Fumigator'])
            "
            v-model="item.FumeReading48Hr"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="48 Hour Reading"
          ></v-text-field>
          <v-text-field
            :disabled="
              item.BookingContainerEventStatusName === 'PendingFumigation' ||
              !isUserAllowed(['Fumigator'])
            "
            v-model="item.FumeReading72Hr"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="72 Hour Reading"
          ></v-text-field>
          <v-text-field
            v-if="item.CommodityTemperature < 70"
            :disabled="
              item.BookingContainerEventStatusName === 'PendingFumigation' ||
              !isUserAllowed(['Fumigator'])
            "
            v-model="item.FumeReading96Hr"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="96 Hour Reading"
          ></v-text-field>
          <v-text-field
            v-if="item.CommodityTemperature < 60"
            :disabled="
              item.BookingContainerEventStatusName === 'PendingFumigation' ||
              !isUserAllowed(['Fumigator'])
            "
            v-model="item.FumeReading120Hr"
            @input="item.Modified = true"
            type="number"
            class="mt-2"
            label="120 Hour Reading"
          ></v-text-field>
          <v-col cols="12">
            <v-btn
              v-if="
                item.FileName &&
                item.FileName != '' &&
                isUserAllowed(['Fumigator', 'Admin'])
              "
              :link="true"
              @click="openLink(item.PreSignedUrl)"
              target="_blank"
              :loading="loading"
              color="secondary"
              class="mt-3 text--primary"
              style="width: 100%"
            >
              Fumigation Certificate
            </v-btn>
            <v-btn
              v-if="
                item.FileName &&
                item.FileName != '' &&
                isUserAllowed(['Fumigator', 'Admin'])
              "
              @click="GenerateNewFumigationCertificate(item)"
              :loading="loading"
              color="secondary"
              class="mt-3 text--primary"
              style="width: 100%"
            >
              Generate New Certificate
            </v-btn>
            <v-btn
              v-if="
                item.BookingContainerEventStatusName === 'PendingFumigation' &&
                isUserAllowed(['Fumigator'])
              "
              :disabled="
                isNaN(item.CommodityTemperature) || isNaN(item.AirTemperature)
              "
              @click="StartFumigation(item)"
              :loading="loading"
              color="secondary"
              class="mt-3 text--primary"
              style="width: 100%"
            >
              Start Fumigation
            </v-btn>
            <v-btn
              v-if="
                item.BookingContainerEventStatusName === 'FumigationStarted' &&
                isUserAllowed(['Fumigator'])
              "
              :disabled="CheckIfFumigationCanEnd(item)"
              @click="EndFumigation(item)"
              :loading="loading"
              color="secondary"
              class="mt-3 text--primary"
              style="width: 100%"
            >
              End Fumigation
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="isUserAllowed(['Fumigator'])"
              @click="UpdateFumigationContainers(item)"
              :loading="loading"
              color="secondary"
              class="mt-3 mb-3 text--primary"
              style="width: 100%"
            >
              Update Temperatures
            </v-btn>
          </v-col>
        </td>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols="12" md="9" lg="10" xl="10"> </v-col>
      <v-col
        v-if="isUserAllowed(['Fumigator'])"
        cols="12"
        md="3"
        lg="2"
        xl="1"
        class="d-flex justify-center"
      >
        <v-btn
          @click="UpdateFumigationContainers"
          :loading="loading"
          color="secondary"
          class="mr-2 ml-2 mt-3 text--primary"
        >
          Update Temperatures
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const api = require("@/api/api.js");
const { DateTime } = require("luxon");
import { isUserAllowed } from "@/helpers.js";
export default {
  name: "Fumigation",
  components: {},
  data: () => ({
    isError: false,
    isSuccess: false,
    editEndDate: "",
    editStartDate: "",
    successMessage: "",
    loading: true,
    alertMessage: "",
    valid: true,
    requiredRules: [],
    statusSearch: "",
    selectedElement: null,
    selectedOpen: false,
    daysPast: 10,
    expanded: [],
    containers: [],
    search: "",
  }),
  computed: {
    computedHeaders() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return [
            {
              text: "Container #",
              align: "start",
              value: "ContainerNumber",
              sortable: false,
            },
            {
              text: "Status",
              value: "BookingContainerEventStatusName",
              sortable: false,
            },
            { text: "", value: "data-table-expand" },
          ];
        case "sm":
          return [
            {
              text: "Container #",
              align: "start",
              value: "ContainerNumber",
              sortable: false,
            },
            {
              text: "Status",
              value: "BookingContainerEventStatusName",
              sortable: false,
            },

            { text: "Start Time", value: "StartDate" },
            { text: "End Time", value: "EndDate" },
            { text: "", value: "data-table-expand" },
          ];
        default:
          return [
            {
              text: "Container #",
              align: "start",
              value: "ContainerNumber",
              sortable: false,
            },
            {
              text: "Status",
              value: "BookingContainerEventStatusName",
              sortable: false,
            },
            { text: "Commodity", value: "CommodityName", sortable: false },
            {
              text: "Container Type",
              value: "ContainerTypeDescription",
              sortable: false,
            },
            {
              text: "Seal Number",
              value: "SealNumber",
              sortable: false,
            },
            { text: "Start Time", value: "StartDate", sortable: false },
            { text: "End Time", value: "EndDate", sortable: false },
            {
              text: "Commodity Temperature",
              value: "CommodityTemperature",
              sortable: false,
            },
            {
              text: "Air Temperature",
              value: "AirTemperature",
              sortable: false,
            },
            { text: "24HR Reading", value: "FumeReading24Hr", sortable: false },
            { text: "48HR Reading", value: "FumeReading48Hr", sortable: false },
            { text: "72HR Reading", value: "FumeReading72Hr", sortable: false },
            { text: "96HR Reading", value: "FumeReading96Hr", sortable: false },
            {
              text: "120HR Reading",
              value: "FumeReading120Hr",
              sortable: false,
            },
            { text: "Action / File", value: "action" },
          ];
      }
    },
  },
  mounted() {
    this.GetFumigationContainers();
  },
  methods: {
    isUserAllowed,
    openLink(url) {
      window.open(url);
    },
    fetchContainersDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.GetFumigationContainers();
      }, 500);
    },
    FilterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
    async GetFumigationContainers() {
      try {
        this.loading = true;
        this.containers = await api.get(
          "/load/bookings/containers/fumigation?daysPast=" + this.daysPast
        );
        this.containers.forEach((container) => {
          if (container.StartDate !== null) {
            const startDate = new Date(container.StartDate);
            container.StartDateLocal = DateTime.fromJSDate(new Date(startDate))
              .setZone("America/Chicago")
              .toFormat("MM/dd/yyyy t");

            const startDateOnlyString = new Date(container.StartDate);
            container.StartDateOnlyLocal = DateTime.fromJSDate(
              new Date(startDateOnlyString)
            )
              .setZone("America/Chicago")
              .toFormat("yyyy-MM-dd");

            const sdt = DateTime.fromISO(container.StartDate);
            container.StartDateTimeOnlyLocal = sdt.toLocaleString(
              DateTime.TIME_24_SIMPLE
            );
          }
          if (container.EndDate !== null) {
            const endDate = new Date(container.EndDate);
            container.EndDateLocal = DateTime.fromJSDate(new Date(endDate))
              .setZone("America/Chicago")
              .toFormat("MM/dd/yyyy t");

            const endDateOnlyString = new Date(container.EndDate);
            container.EndDateOnlyLocal = DateTime.fromJSDate(
              new Date(endDateOnlyString)
            )
              .setZone("America/Chicago")
              .toFormat("yyyy-MM-dd");

            const edt = DateTime.fromISO(container.EndDate);
            container.EndDateTimeOnlyLocal = edt.toLocaleString(
              DateTime.TIME_24_SIMPLE
            );
          }
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async UpdateFumigationContainers() {
      try {
        this.loading = true;
        await api.put(
          "/load/fumigationcontainer",
          this.containers.filter((container) => container.Modified)
        );
        this.isSuccess = true;
        this.successMessage = "Updated Containers!";
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to retrieve load data. Please try again.";
        console.log(err);
      }
    },
    async UpdateFumigationContainer(container) {
      try {
        this.loading = true;
        await api.put("/load/fumigationcontainer", [container]);
        this.isSuccess = true;
        this.successMessage = "Updated Container!";
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to update container. Please try again.";
        console.log(err);
      }
    },
    async StartFumigation(item) {
      try {
        this.loading = true;
        await api.post("/load/fumigation/start", item);
        this.isSuccess = true;
        this.successMessage =
          "Fumigation Started for : " + item.ContainerNumber;
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to Start Fumigation. Please try again.";
        console.log(err);
      }
    },
    async UpdateBookingContainerEventStart(item) {
      try {
        this.loading = true;
        const updatedDate =
          item.StartDateOnlyLocal +
          "T" +
          item.StartDateTimeOnlyLocal +
          ":00.000";
        const updatedDateUTC = new Date(updatedDate);
        const updatedDateUTCString = updatedDateUTC.toISOString();
        let updateEvent = {
          bookingContainerEventId: item.BookingContainerEventIdStart,
          newTime: updatedDateUTCString,
        };
        await api.put("/load/updatebookingcontainerevent", updateEvent);
        this.isSuccess = true;
        this.successMessage =
          "Start Time updated for : " + item.ContainerNumber;
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to update Start Time. Please try again.";
        console.log(err);
      }
    },
    async UpdateBookingContainerEventEnd(item) {
      try {
        this.loading = true;
        const updatedDate =
          item.EndDateOnlyLocal + "T" + item.EndDateTimeOnlyLocal + ":00.000";
        const updatedDateUTC = new Date(updatedDate);
        const updatedDateUTCString = updatedDateUTC.toISOString();
        let updateEvent = {
          bookingContainerEventId: item.BookingContainerEventIdEnd,
          newTime: updatedDateUTCString,
        };
        await api.put("/load/updatebookingcontainerevent", updateEvent);
        this.isSuccess = true;
        this.successMessage = "End Time updated for : " + item.ContainerNumber;
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to update End Time. Please try again.";
        console.log(err);
      }
    },
    async EndFumigation(item) {
      try {
        this.loading = true;
        await api.post("/load/fumigation/end", item);
        this.isSuccess = true;
        this.successMessage = "Fumigation Ended for : " + item.ContainerNumber;
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed to End Fumigation. Please try again.";
        console.log(err);
      }
    },
    async GenerateNewFumigationCertificate(item) {
      try {
        this.loading = true;
        await api.post("/load/fumigation/generatenewcertificate", item);
        this.isSuccess = true;
        this.successMessage =
          "New Certificate Requested for: " + item.ContainerNumber;
        await this.GetFumigationContainers();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isError = true;
        this.alertMessage = "Failed New Certificate Request. Please try again.";
        console.log(err);
      }
    },
    CheckIfFumigationCanEnd(item) {
      return (
        !item.CommodityTemperature ||
        isNaN(item.CommodityTemperature) ||
        !item.AirTemperature ||
        isNaN(item.AirTemperature) ||
        !item.FumeReading24Hr ||
        isNaN(item.FumeReading24Hr) ||
        !item.FumeReading48Hr ||
        isNaN(item.FumeReading48Hr) ||
        !item.FumeReading72Hr ||
        isNaN(item.FumeReading72Hr) ||
        (item.CommodityTemperature < 70 &&
          (!item.FumeReading96Hr || isNaN(item.FumeReading96Hr))) ||
        (item.CommodityTemperature < 60 &&
          (!item.FumeReading120Hr || isNaN(item.FumeReading120Hr)))
      );
    },
  },
};
</script>

<style>
th .text-start {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
