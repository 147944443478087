var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-footer",
    { attrs: { app: "", color: "primary", dark: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "primary text-center white--text",
              attrs: { cols: "12" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "white--text footer-link",
                  attrs: { to: "/tos" },
                },
                [_vm._v("Terms of Service")]
              ),
              _vm._v(" | " + _vm._s(new Date().getFullYear()) + " "),
              _c("strong", [_vm._v("G4 Transload")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }