var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isUserAllowed(["Admin", "Master"])
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "d-lg-none d-md-none d-sm-none",
              attrs: { color: "primary", grow: "", fixed: "" },
            },
            [
              _c(
                "v-btn",
                { attrs: { to: "/users" } },
                [
                  _c("span", [_vm._v("User")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-account-circle"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/containers/admin" } },
                [
                  _c("span", [_vm._v("Container")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-truck-cargo-container"),
                  ]),
                ],
                1
              ),
              _vm.isUserAllowed(["Party", "Admin", "Master"])
                ? _c(
                    "v-btn",
                    { attrs: { to: "/outbound/summary" } },
                    [
                      _c("span", [_vm._v("Bookings")]),
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("mdi-border-color"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                { attrs: { to: "/deliveries/deliveries" } },
                [
                  _c("span", [_vm._v("Inbound")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-border-color"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/outbound/comments" } },
                [
                  _c("span", [_vm._v("Booking"), _c("br"), _vm._v("Comments")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-comment-outline"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/fumigation/containers" } },
                [
                  _c("span", [_vm._v("Fumigation")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-smoke"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isUserAllowed(["Party"])
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "d-lg-none d-md-none d-sm-none",
              attrs: { color: "primary", grow: "", fixed: "" },
            },
            [
              _c(
                "v-btn",
                { attrs: { to: "/outbound/summary" } },
                [
                  _c("span", [_vm._v("Bookings")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-border-color"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/deliveries/deliveries" } },
                [
                  _c("span", [_vm._v("Deliveries")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-truck-delivery"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/forecast" } },
                [
                  _c("span", [_vm._v("Forecast")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-smoke"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/tickets" } },
                [
                  _c("span", [_vm._v("Tickers")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-ticket"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/outbound/comments" } },
                [
                  _c("span", [_vm._v("Booking"), _c("br"), _vm._v("Comments")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-comment-outline"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isUserAllowed(["Trucker"])
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "d-lg-none d-md-none d-sm-none",
              attrs: { color: "primary", grow: "", fixed: "" },
            },
            [
              _c(
                "v-btn",
                { attrs: { to: "/booking/driver" } },
                [
                  _c("span", [_vm._v("Schedule")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-border-color"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/deliveries/deliveries" } },
                [
                  _c("span", [_vm._v("Deliveries")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-truck-delivery"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isUserAllowed(["Fumigator"])
        ? _c(
            "v-bottom-navigation",
            {
              staticClass: "d-lg-none d-md-none d-sm-none",
              attrs: { color: "primary", grow: "", fixed: "" },
            },
            [
              _c(
                "v-btn",
                { attrs: { to: "/deliveries/deliveries" } },
                [
                  _c("span", [_vm._v("Deliveries")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-truck-delivery"),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                { attrs: { to: "/fumigation/containers" } },
                [
                  _c("span", [_vm._v("Fumigation")]),
                  _c("v-icon", { attrs: { medium: "" } }, [
                    _vm._v("mdi-smoke"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }