import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";

import Route from "@/components/Layout/NestedRoute.vue";
import Layout from "@/components/Layout/Layout.vue";
import UserManagement from "@/views/Users/UserManagement.vue";
import UserEdit from "@/views/Users/Edit.vue";
import UserCreate from "@/views/Users/Create.vue";
import ContainerAdministration from "@/views/Containers/Administration.vue";
import LoadSummary from "@/views/Loads/LoadSummary.vue";
import Billing from "@/views/Loads/Billing.vue";
import Schedule from "@/views/Driver/Schedule.vue";
import BookingAdmin from "@/views/Booking/Admin.vue";
import Load from "@/views/Loads/Load.vue";
import InboundSchedule from "@/views/Inbound/InboundSummary.vue";
import DeliveryForecast from "@/views/Delivery/Forecast.vue";
import Tickets from "@/views/Inbound/Tickets.vue";
import Fumigation from "@/views/Fumigation/Container.vue";
import Register from "@/views/Users/Register.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import Comments from "@/views/Loads/Comments.vue";
import MoveBookingContainer from "@/views/Containers/MoveBookingContainer.vue";

import { Auth } from "aws-amplify";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Layout",
    component: Layout,
    beforeEach: routeGuard,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: routeGuard,
        meta: {
          title: "Home",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
      },
      {
        path: "/users",
        component: Route,
        beforeEnter: routeGuard,
        meta: {
          title: "Users",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
        children: [
          {
            path: "",
            name: "UserManagement",
            component: UserManagement,
            beforeEnter: routeGuard,
            meta: {
              title: "User Management",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/users/edit/:username",
            name: "UserDetails",
            component: UserEdit,
            beforeEnter: routeGuard,
            meta: {
              title: "Editing User",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/users/create",
            name: "UserCreate",
            component: UserCreate,
            beforeEnter: routeGuard,
            meta: {
              title: "Create User",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
        ],
      },
      {
        path: "/outbound/summary",
        component: Route,
        beforeEach: routeGuard,
        meta: {
          title: "Outbound",
          requiresAuth: true,
        },
        children: [
          {
            path: "/outbound/create",
            name: "CreateLoad",
            component: Load,
            beforeEnter: routeGuard,
            meta: {
              title: "Create a Booking",
              requiresAuth: true,
              allowedUsers: ["Party", "Admin", "Master"],
            },
          },
          {
            path: "/outbound/edit/:loadId",
            name: "EditLoad",
            component: Load,
            beforeEnter: routeGuard,
            meta: {
              title: "Edit a Booking",
              requiresAuth: true,
              allowedUsers: ["Party", "Admin", "Master"],
            },
          },
          {
            path: "",
            name: "LoadSummary",
            component: LoadSummary,
            beforeEnter: routeGuard,
            meta: {
              title: "Booking Summary",
              requiresAuth: true,
              allowedUsers: ["Party", "Admin", "Master"],  // eslint-disable-line
            },
          },
          {
            path: "/outbound/billing/:loadId",
            name: "Billing",
            component: Billing,
            beforeEnter: routeGuard,
            meta: {
              title: "Booking Details",
              requiresAuth: true,
              allowedUsers: ["Party", "Admin", "Master"],
            },
          },
          {
            path: "/outbound/comments",
            name: "Comments",
            component: Comments,
            beforeEnter: routeGuard,
            meta: {
              title: "Booking Comments",
              requiresAuth: true,
              allowedUsers: ["Party", "Admin", "Master"],
            },
          },
          {
            path: "/outbound/containermove",
            name: "Move Booking Container : From",
            component: MoveBookingContainer,
            beforeEnter: routeGuard,
            meta: {
              title: "Move Booking Container",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/outbound/containermove/:fromBookingId",
            name: "Move Booking Container : To",
            component: MoveBookingContainer,
            beforeEnter: routeGuard,
            meta: {
              title: "Move Booking Container",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/outbound/containermove/:fromBookingId/:toBookingId",
            name: "Move Booking Container",
            component: MoveBookingContainer,
            beforeEnter: routeGuard,
            meta: {
              title: "Move Booking Container",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/containers",
    component: Layout,
    beforeEach: routeGuard,
    meta: {
      title: "Containers",
      requiresAuth: true,
    },
    children: [
      {
        path: "/containers/admin",
        name: "Container Administration",
        component: ContainerAdministration,
        beforeEnter: routeGuard,
        meta: {
          title: "Container Administration",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
      },
    ],
  },
  {
    path: "/inbound",
    component: Layout,
    beforeEach: routeGuard,
    meta: {
      title: "Inbound",
      requiresAuth: true,
      allowedUsers: ["Admin", "Master", "Trucker"],
    },
    children: [
      {
        path: "",
        name: "InboundSchedule",
        component: InboundSchedule,
        beforeEnter: routeGuard,
        meta: {
          title: "Inbound Summary",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master", "Trucker"],
        },
      },
    ],
  },
  {
    path: "/deliveries",
    component: Layout,
    beforeEach: routeGuard,
    meta: {
      title: "Inbound",
      requiresAuth: true,
    },
    children: [
      {
        path: "/booking/driver",
        name: "Schedule",
        component: Schedule,
        beforeEnter: routeGuard,
        meta: {
          title: "Schedule a Delivery",
          requiresAuth: true,
          allowedUsers: ["Trucker", "Admin", "Master"],
        },
      },
      {
        path: "/deliveries/deliveries",
        name: "Deliveries",
        component: BookingAdmin,
        beforeEnter: routeGuard,
        meta: {
          title: "Deliveries",
          requiresAuth: true,
          allowedUsers: ["Party", "Admin", "Master", "Fumigator", "Trucker"],
        },
      },
      {
        path: "/forecast",
        name: "ForecastDeliveries",
        component: DeliveryForecast,
        beforeEnter: routeGuard,
        meta: {
          title: "Delivery Forecast",
          requiresAuth: true,
          allowedUsers: ["Party", "Admin", "Master"],
        },
      },
      {
        path: "/tickets",
        name: "Tickets",
        component: Tickets,
        beforeEnter: routeGuard,
        meta: {
          title: "Tickets",
          requiresAuth: true,
          allowedUsers: ["Party", "Admin", "Master"],
        },
      },
    ],
  },
  {
    path: "/fumigation",
    component: Layout,
    beforeEach: routeGuard,
    meta: {
      title: "Fumigation",
      requiresAuth: true,
      allowedUsers: ["Fumigator", "Admin", "Master"],
    },
    children: [
      {
        path: "/fumigation/containers",
        name: "Fumigation Containers",
        component: Fumigation,
        beforeEnter: routeGuard,
        meta: {
          title: "Fumigation Containers",
          requiresAuth: true,
          allowedUsers: ["Fumigator", "Admin", "Master"],
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
    props: true,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/tos",
    name: "Terms of Service",
    component: TermsOfService,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

async function routeGuard(to, from, next) {
  let isAuthenticated = false;
  try {
    isAuthenticated = await Auth.currentAuthenticatedUser();
    console.log(isAuthenticated);
  } catch (err) {
    console.log(err);
  }
  if (isAuthenticated) {
    //weird bug coming from login. so if user is going home just allow.
    if (to.path === "/home") {
      document.title = to.meta.title;
      if (store.getters.getUserType === "Trucker") {
        next("/deliveries/deliveries");
      } else if (store.getters.getUserType === "Fumigator") {
        next("/fumigation/containers");
      }
      {
        next();
      }
    } else {
      let allowedUsers = [];
      to.matched.some((record) => (allowedUsers = record.meta.allowedUsers));

      const authedUserType = store.getters.getUserType;

      if (allowedUsers.includes(authedUserType)) {
        document.title = to.meta.title;
        next();
      } else {
        document.title = "Home";
        next({ path: "/home" });
      }
    }
  } else {
    try {
      Auth.signOut({ global: true });
    } catch (err) {
      console.log(err);
    }
    store.commit("logOut");
    next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  }
}

export default router;
